import React, { useCallback, useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import quillEmoji from "react-quill-emoji";
import "react-quill-emoji/dist/quill-emoji.css";
import { CheckOutlined, CloseOutlined, SendOutlined } from "@ant-design/icons";

import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addComment,
  editComment,
  setIsEditComment,
} from "../../redux/slices/TaskSlice";

function QuillEditor({ comment }) {
  Quill.register("modules/imageResize", ImageResize);
  Quill.register(
    {
      "formats/emoji": quillEmoji.EmojiBlot,
      "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
      "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
      "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
    },
    true
  );
  const { activeProject } = useSelector((state) => state.project);
  const [assigneeDropdown, setAssigneeDropdown] = useState([]);
  const { isEditComment } = useSelector((state) => state.task);

  useEffect(() => {
    if (comment?.context && isEditComment) {
      setValue(comment.context);
    } else {
      setValue("");
    }
  }, [comment, isEditComment]);

  useEffect(() => {
    let teamArray = [];
    activeProject?.team?.forEach((user, i) => {
      // let element = (
      //   <div>
      //     <Avatar
      //       alt=""
      //       size={25}
      //       icon={<UserOutlined />}
      //       style={{
      //         backgroundColor: "#0037a2",
      //         color: "#fff",
      //         marginRight: "10px",
      //       }}
      //       src={user?.isUserProfileUploaded ?
      //         `${AppConstants.BUCKET_URL}` +
      //         user?.email?.replace("@", "%40") : ""
      //       }
      //     />
      //     {user?.name}
      //   </div>
      // );
      let teamObj = { value: user?.name, id: user?._id };
      teamArray.push(teamObj);
    });
    setAssigneeDropdown(teamArray);
  }, [activeProject?.team]);

  const dispatch = useDispatch();
  const { selectedTask } = useSelector((state) => state.task);

  const [editorState, setEditorState] = useState({});
  const [value, setValue] = useState("");

  const onChangeText = (value, delta, source, editor) => {
    let htmlValue = value.replace("<p><br></p>", "");
    setValue(htmlValue);
    setEditorState(editor);
  };

  const onCancelEdit = () => {
    setValue("");
    dispatch(setIsEditComment(false));
  };

  const sendMessage = () => {
    const htmlContent = editorState.getHTML().replace("<p><br></p>", "");
    let mentions = [];
    editorState.getContents().ops.forEach((element) => {
      if (element.insert.mention) {
        mentions.push(element.insert.mention.id);
      }
    });
    dispatch(
      addComment({
        context: htmlContent,
        mentions: mentions,
        taskId: selectedTask?._id,
        text: htmlContent.replace(/(<([^>]+)>)/g, ""),
      })
    );
    setValue("");
    setEditorState({});
  };

  const handleOnUpdate = () => {
    const htmlContent = editorState.getHTML();
    let mentions = [];
    editorState.getContents().ops.forEach((element) => {
      if (element.insert.mention) {
        mentions.push(element.insert.mention.id);
      }
    });
    if (comment._id) {
      let editedComment = { ...comment };
      editedComment.context = htmlContent;
      editedComment.mentions = mentions;
      editedComment.text = htmlContent.replace(/(<([^>]+)>)/g, "");

      dispatch(editComment(editedComment));
    }
  };
  const onhandleKeyDown = (e) => {
    if (e.keyCode === "Enter" && !e.shiftKey) {
      sendMessage();
    }
  };

  return (
    <div className="quillEditor-container">
      <ReactQuill
        onKeyDown={onhandleKeyDown}
        modules={{
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@", "#"],
            linkTarget: "https://www.google.com",
            source: useCallback(
              (searchTerm, renderList, mentionChar) => {
                let values;

                if (mentionChar === "@") {
                  values = assigneeDropdown;
                }
                if (searchTerm.length === 0) {
                  renderList(values, searchTerm);
                } else {
                  const matches = [];
                  for (let i = 0; i < values.length; i++)
                    if (
                      ~values[i].value
                        .toLowerCase()
                        .indexOf(searchTerm.toLowerCase())
                    )
                      matches.push(values[i]);
                  renderList(matches, searchTerm);
                }
              },
              [assigneeDropdown]
            ),
          },
          toolbar: [
            //   [{ header: "1" }, { header: "2" }],
            //   [{ size: [] }],
            ["bold", "italic", "underline", "blockquote"],
            //   [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            ["link", "image"],
            //   ["clean"],
            // ["emoji"],
            ["code-block"],
          ],
          "emoji-toolbar": true,
          "emoji-textarea": true,
          "emoji-shortname": true,
        }}
        theme="snow"
        value={value}
        onChange={onChangeText}
      />
      <div className="comment-footer">
        {isEditComment ? (
          <>
            <button
              className="comment-send-btn"
              onClick={() => handleOnUpdate()}
            >
              {
                <CheckOutlined
                  style={{
                    fontSize: "18px",
                    display: "flex",
                    color: "#0037a2",
                  }}
                />
              }
            </button>
            <button className="comment-edit-btn" onClick={() => onCancelEdit()}>
              {
                <CloseOutlined
                  style={{
                    fontSize: "18px",
                    display: "flex",
                    color: "#c92f54",
                  }}
                />
              }
            </button>
          </>
        ) : (
          <button
            className={
              value === "" ? "comment-disable-btn" : "comment-send-btn"
            }
            disabled={value === "" ? true : false}
            onClick={() => sendMessage()}
          >
            {
              <SendOutlined
                style={{
                  fontSize: "18px",
                  display: "flex",
                  color: "#0037a2",
                }}
              />
            }
          </button>
        )}
      </div>
    </div>
  );
}

export default QuillEditor;
