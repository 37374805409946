import { call, put, fork, takeEvery, takeLatest } from "redux-saga/effects";
import agent from "../../agent";
import {
  addComment,
  addNewComment,
  addNewTask,
  addReaction,
  closeTask,
  deleteComment,
  deleteTask,
  editComment,
  fetchTaskAudits,
  getComments,
  getReactions,
  getSelectedTask,
  removeComment,
  removeReaction,
  setComments,
  setIsEditComment,
  setReactions,
  setSelectedTask,
  setShowTaskDrawer,
  setTaskAudits,
  updateAccountableAssignee,
  updateComment,
  updateCommentsReaction,
  updateTaskAudits,
  updateTaskFields,
} from "../slices/TaskSlice";
import showMessage from "../../utils/messageUtil";
import { addTaskToProjectTasks } from "../slices/ProjectSlice";

function* getTask(data) {
  let response = yield call(agent.Task.getTaskById, data.payload);
  if (response?.statusCode === 200 || response?.statusCode === 304) {
    yield put(setSelectedTask(response.body.task));
    yield put(setComments(response.body.comments));
    yield put(setTaskAudits(response.body.audits));
    yield put(setShowTaskDrawer(true));
  } else {
    yield put(setShowTaskDrawer(false));
    showMessage(response, "", "");
  }
}

function* deleteTaskById(data) {
  try {
    const response = yield call(agent.Task.delete, data.payload);
    showMessage(
      response,
      "Task Deleted Successfully",
      "Task Deletion Failed: "
    );
  } catch (e) {
    console.log(e);
  }
}

function* updateTask(data) {
  try {
    const response = yield call(
      agent.Task.updateTaskField,
      data.payload.taskId,
      data.payload.filter
    );
    yield put(updateTaskAudits(response.body.taskAudit));
    showMessage(response, "Task Updated Successfully", "Update Task Failed: ");
  } catch (e) {
    console.log(e);
  }
}

function* updateAccountableAssigneeAsync(data) {
  try {
    const response = yield call(
      agent.Task.updateAccountableAssigneeByTaskId,
      data.payload.taskId,
      data.payload.filter
    );
    yield put(updateTaskAudits(response.body.taskAudit));
    showMessage(response, "Task Updated Successfully", "Update Task Failed: ");
  } catch (e) {
    console.log(e);
  }
}

function* closeTaskAsync(data) {
  try {
    const response = yield call(
      agent.Task.closeTaskById,
      data.payload.taskId,
      data.payload.closed
    );
    showMessage(response, "Task Closed Successfully", "Update Task Failed: ");
  } catch (e) {
    console.log(e);
  }
}

function* addTask(data) {
  try {
    const response = yield call(agent.Task.createTask, data.payload);
    showMessage(response, "Task Added Successfully", "Add Task Failed: ");
    yield put(addTaskToProjectTasks(response.body));
  } catch (e) {
    console.log(e);
  }
}

function* addCommentOnTask(data) {
  const response = yield call(agent.Task.addComment, data.payload);
  if (response?.statusCode === 200) yield put(addNewComment(response.body));
}

function* getCommentsOfTask(data) {
  let response = yield call(agent.Task.getComments, data.payload);
  yield put(setComments(response.body));
}

function* deleteCommentOfTask(data) {
  let response = yield call(agent.Task.deleteComment, data.payload);
  showMessage(
    response,
    "Comment Deleted Successfully",
    "Delete Comment Failed: "
  );

  yield put(removeComment(response.body));
}

function* editCommentById(data) {
  let response = yield call(
    agent.Task.editComment,
    data.payload._id,
    data.payload
  );
  showMessage(response, "Comment Edited Successfully", "Comment Edit Failed");

  yield put(setIsEditComment(false));
  yield put(updateComment(response.body));
}

function* getTaskAudits(data) {
  let response = yield call(agent.Task.getTaskAudits, data.payload);
  yield put(setTaskAudits(response.body));
}

function* addReactionToComment(data) {
  const response = yield call(agent.Task.addReaction, data.payload);
  yield put(updateCommentsReaction(response.body));
}

function* removeReactionFromComment(data) {
  const response = yield call(agent.Task.removeReaction, data.payload);
  yield put(updateCommentsReaction(response.body));
}

function* tasks() {
  yield takeEvery(getSelectedTask.type, getTask);
  yield takeEvery(deleteTask.type, deleteTaskById);
  yield takeLatest(updateTaskFields.type, updateTask);
  yield takeLatest(
    updateAccountableAssignee.type,
    updateAccountableAssigneeAsync
  );
  yield takeEvery(addNewTask.type, addTask);
  yield takeEvery(addComment.type, addCommentOnTask);
  yield takeEvery(getComments.type, getCommentsOfTask);
  yield takeEvery(deleteComment.type, deleteCommentOfTask);
  yield takeEvery(editComment.type, editCommentById);
  yield takeEvery(closeTask.type, closeTaskAsync);
  yield takeEvery(fetchTaskAudits.type, getTaskAudits);
  yield takeLatest(addReaction.type, addReactionToComment);
  yield takeLatest(removeReaction.type, removeReactionFromComment);
}

export const taskSaga = [fork(tasks)];
