import { useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  Dropdown,
  Button,
  List,
  Avatar,
  Modal,
  Input,
  Form,
  Popover,
  Badge,
  Tooltip,
  Popconfirm,
  message,
} from "antd";

import {
  LogoutOutlined,
  RedoOutlined,
  UserOutlined,
  SwapOutlined,
  CheckCircleOutlined,
  ProjectOutlined,
  SendOutlined,
  DownOutlined,
  UpOutlined,
  SearchOutlined,
  BellFilled,
  MoreOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { plusIcon } from "./SVG";
import { useDispatch, useSelector } from "react-redux";
import { logout, setLoginUser } from "../../redux/slices/LoginSlice";
import {
  setShowNewInviteModalOpen,
  setShowNewProjectModalOpen,
  setShowNewTaskModalOpen,
} from "../../redux/slices/CreateButtonSlice";
import {
  deleteNotification,
  getActiveCompany,
  markSeen,
  setActiveCompany,
  setActiveUserProfile,
  setNewPassword,
} from "../../redux/slices/UserSlice";
import { Link, useNavigate } from "react-router-dom";
import AppConstants from "../../utils/AppConstants";
import moment from "moment";
import {
  getActiveProject,
  getActiveProjetAndTask,
  setActiveProject,
} from "../../redux/slices/ProjectSlice";
import SearchModal from "../common/SearchModal";

function Header() {
  const { loginUser } = useSelector((state) => state.login);
  const { projects } = useSelector((state) => state.project);
  const { activeCompany, userRole, activeUserProfile, notifications } =
    useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCreateBox, setShowCreateBox] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [notiVisible, setNotiVisible] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [form] = Form.useForm();
  const [unseen, setUnseenCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    let count = notifications?.filter((noti) => noti?.seen === false).length;
    setUnseenCount(count);
  }, [notifications]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => window.scrollTo(0, 0));

  const handleLogout = () => {
    dispatch(setActiveCompany({}));
    dispatch(logout());
    dispatch(setActiveUserProfile(""));
    navigate("/sign-in", { replace: true });
  };

  const onFinish = (values) => {
    if (values.newPassword.trim().length > 7) {
      if (values.newPassword === values.confirmPassword) {
        if (values.newPassword !== values.oldPassword) {
          setIsModalOpen(false);
          form.resetFields();
          dispatch(setNewPassword(values));
        } else {
          form.setFields([
            {
              name: "newPassword",
              errors: ["Cannot be same as old Password"],
            },
          ]);
        }
      } else {
        form.setFields([
          {
            name: "confirmPassword",
            errors: ["Passwords should match"],
          },
        ]);
      }
    } else {
      form.setFields([
        {
          name: "newPassword",
          errors: ["Enter valid password"],
        },
      ]);
    }
  };

  const handleOnCreateClick = () => {
    setShowCreateBox(!showCreateBox);
  };

  const items = [
    {
      key: "1",
      label: (
        <Button
          id="global-create-button"
          disabled={projects?.length === 0}
          onClick={() => {
            dispatch(
              setShowNewTaskModalOpen(true),
              setShowCreateBox(!showCreateBox)
            );
          }}
        >
          <CheckCircleOutlined id="global-create-icon" />
          Task
        </Button>
      ),
    },
    userRole === AppConstants.OWNER || userRole === AppConstants.CREATOR
      ? {
          key: "2",
          label: (
            <Button
              id="global-create-button"
              onClick={() => {
                dispatch(
                  setShowNewProjectModalOpen(true),
                  setShowCreateBox(!showCreateBox)
                );
              }}
            >
              <ProjectOutlined id="global-create-icon" />
              Project
            </Button>
          ),
        }
      : null,
  ];

  const handleCompanyChange = (id) => {
    dispatch(getActiveCompany(id));
    setShowCompanies(false);
    navigate(`/home`);
  };
  const profileMenu = (
    <List
      min-width="100%"
      className="ant-dropdown-menu "
      itemLayout="horizontal"
    >
      <List.Item
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "11px",
        }}
      >
        <div style={{ marginBottom: "5px", cursor: "default" }}>
          <UserOutlined /> {`${loginUser.name} (${userRole})`}
        </div>
        {/* <p className="edit-profile" >
          Edit Profile
        </p> */}
      </List.Item>
      <List.Item style={{ padding: "11px" }}>
        <Link to="/profile" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <UserOutlined /> My Profile
        </Link>
      </List.Item>
      <List.Item style={{ padding: "11px" }}>
        <div onClick={handleLogout}>
          <LogoutOutlined /> Logout
        </div>
      </List.Item>
      <List.Item style={{ padding: "11px" }}>
        <div onClick={showModal}>
          <RedoOutlined /> Change Password
        </div>
      </List.Item>
      <List style={{ padding: 0, display: "flex", flexDirection: "column" }}>
        <Button
          style={{ width: "100%", textAlign: "left", border: "none" }}
          onClick={() => setShowCompanies(!showCompanies)}
        >
          <SwapOutlined /> Switch Company{" "}
          {showCompanies ? (
            <UpOutlined style={{ marginLeft: "30px" }} />
          ) : (
            <DownOutlined style={{ marginLeft: "30px" }} />
          )}
        </Button>
      </List>
      {showCompanies && loginUser?.companies?.length
        ? loginUser?.companies?.map((company) => (
            <List key={company._id} style={{ padding: 0 }}>
              <Button
                style={{
                  width: "100%",
                  textAlign: "left",
                  border: "none",
                  paddingLeft: "40px",
                  color: `${
                    activeCompany._id === company._id ? "#40a9ff" : ""
                  }`,
                }}
                onClick={() => handleCompanyChange(company._id)}
              >
                {company.name}
              </Button>
            </List>
          ))
        : null}
    </List>
  );

  const handleOnClickNotification = (notification) => {
    if (!notification.seen) {
      dispatch(
        markSeen({
          companyId: activeCompany?._id,
          notificationId: {
            notificationId: notification._id,
          },
        })
      );
    }
    if (notification.action === AppConstants.DAILY_REMINDER) {
      navigate(`/dashboard`);
      dispatch(setActiveProject({}));
      setNotiVisible(false);
      return;
    } else if (
      notification.action === AppConstants.ASSIGNED_ASSET ||
      notification.action === AppConstants.RECOVERED_ASSET
    ) {
      navigate(`/profile`);
      dispatch(setActiveProject({}));
      setNotiVisible(false);
      return;
    }

    if (!notification.project) {
      return;
    }
    switch (notification.action) {
      case AppConstants.ADD_MEMBER:
      case AppConstants.PROJECT_UPDATED:
        setNotiVisible(false);
        navigate(`/project/${notification.project?._id}/overview`, {
          replace: true,
        });
        dispatch(getActiveProject(notification.project?._id));
        break;

      case AppConstants.ASSIGNED_TASK:
      case AppConstants.TASK_UPDATED:
      case AppConstants.MENTIONED_IN_COMMENT:
      case AppConstants.ACCOUNTABLE_FOR_TASK:
      case AppConstants.ADDED_COMMENT:
      case AppConstants.UPDATED_COMMENT:
      case AppConstants.REACTED_ON_COMMENT:
        setNotiVisible(false);
        navigate(`/project/${notification.project?._id}/board`, {
          replace: true,
        });
        dispatch(
          getActiveProjetAndTask({
            projectId: notification.project?._id,
            taskId: notification.task?._id,
          })
        );
        break;

      case AppConstants.TASK_CLOSED:
        setNotiVisible(false);
        navigate(`/project/${notification.project?._id}/closed`, {
          replace: true,
        });
        dispatch(
          getActiveProjetAndTask({
            projectId: notification.project?._id,
            taskId: notification.task?._id,
          })
        );
        break;

      default:
        break;
    }
  };

  const markSeenNoti = (id) => {
    dispatch(
      markSeen({
        companyId: activeCompany?._id,
        notificationId: {
          notificationId: id,
        },
      })
    );
  };

  // const deleteNoti = () => {
  //   dispatch(deleteNotification({ notificationId: deleteId }));
  // };

  const notificationActions = (noti) => {
    return (
      <div className="noti-actions">
        {!noti?.seen ? (
          <p onClick={() => markSeenNoti(noti._id)}>
            <EyeOutlined />
            Mark as read
          </p>
        ) : null}
        <Popconfirm
          placement="topRight"
          title={`Are you sure to delete this notification ?`}
          okText="Yes"
          cancelText="No"
          overlayClassName="delete-popover"
          onConfirm={() =>
            dispatch(deleteNotification({ notificationId: noti._id }))
          }
        >
          <p>
            <DeleteOutlined /> Delete
          </p>
        </Popconfirm>
      </div>
    );
  };

  const handleNotificationContent = () => {
    return (
      <div
        style={{
          width: "450px",
          padding: "15px 15px 40px",
          maxHeight: "500px",
          // minHeight: "400px",
          overflowY: "auto",
        }}
      >
        {notifications?.length ? (
          notifications?.map((noti) => (
            <div className="notification-container">
              <div className="d-flex align-center">
                <div className="notification-name d-flex">
                  {noti.action === AppConstants.DAILY_REMINDER ? (
                    <Avatar size={45} className="avatar-text-img">
                      <BellFilled />
                    </Avatar>
                  ) : (
                    <Avatar
                      size={45}
                      className="avatar-text-img"
                      src={
                        noti?.actionBy?.isUserProfileUploaded
                          ? `${AppConstants.BUCKET_URL}` +
                            noti?.actionBy?.email?.replace("@", "%40")
                          : ""
                      }
                    >
                      {noti?.actionBy?.name.slice(0, 1)}
                    </Avatar>
                  )}
                  <p
                    className={noti.seen ? "" : "unseen-notification"}
                    onClick={() => {
                      handleOnClickNotification(noti);
                    }}
                  >
                    {noti.action === AppConstants.DAILY_REMINDER ? (
                      noti.action
                    ) : (
                      <>
                        <b>{noti?.actionBy?.name}</b>{" "}
                        {noti?.action?.toLowerCase()}
                        {" : "}
                        <b>
                          {noti?.targetValue?.length <= 50
                            ? noti?.targetValue
                            : `${noti?.targetValue?.slice(0, 50)}...`}
                        </b>
                      </>
                    )}
                  </p>
                  <Popover
                    overlayClassName="notification-actions-popup"
                    content={() => notificationActions(noti)}
                    placement="bottomRight"
                  >
                    <div style={{ height: "fit-content" }}>
                      <MoreOutlined className="more-options" />
                    </div>
                  </Popover>
                </div>
              </div>
              <div className="notification-time d-flex justify-flex-end w-full">
                <Tooltip
                  title={moment(noti?.createdAt)?.format(
                    "h:mm a, Do MMMM, YYYY"
                  )}
                >
                  <p
                    style={{
                      fontSize: "10px !important",
                      fontWeight: 400,
                      marginRight: "10px",
                      color: "#000",
                      width: "fit-content",
                    }}
                  >
                    {moment(noti?.createdAt)?.fromNow()}
                  </p>
                </Tooltip>
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center", paddingTop: "20px" }}>
            <p style={{ color: "#000", fontSize: "14px" }}>
              No notification available
            </p>
          </div>
        )}
        {notifications.length ? (
          <>
            <div className="view-all-notifications d-flex align-center justify-space-between">
              <p onClick={() => setNotiVisible(false)}>
                <Link to="/notifications">View all notifications</Link>
              </p>
              <p
                onClick={() => {
                  if (unseen !== 0) {
                    dispatch(
                      markSeen({
                        companyId: activeCompany?._id,
                        notificationId: {
                          notificationId: "all",
                        },
                      })
                    );
                  } else {
                    message.info("No Unread Notifications");
                  }
                }}
              >
                <Link>Mark all as Read</Link>
              </p>
            </div>
          </>
        ) : null}
      </div>
    );
  };
  const handleOpenChange = (open) => {
    setNotiVisible(open);
  };
  const handleOpenDropdownChange = (open) => {
    setProfileDropdown(open);
    setShowCompanies(false);
  };
  return (
    <>
      <SearchModal
        isOpen={isSearch}
        close={() => setIsSearch(false)}
        values={[searchVal, setSearchVal]}
      />
      <Row gutter={[24, 0]}>
        <Col span={14} md={14} className="header-control">
          <Input
            className="header-search"
            placeholder="Search"
            onClick={() => setIsSearch(true)}
            style={{ width: 400 }}
            // value={searchVal}
            prefix={<SearchOutlined />}
            readOnly
          />
        </Col>
        <Col span={10} md={10} className="header-control">
          <Dropdown
            overlay={profileMenu}
            trigger={["click"]}
            open={profileDropdown}
            onOpenChange={handleOpenDropdownChange}
            arrow
            placement="bottomRight"
          >
            <a
              href="#pablo"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Avatar
                alt="Profile Image"
                size={35}
                className="avatar-text-img "
                src={activeUserProfile}
              >
                {loginUser?.name?.slice(0, 1)}
              </Avatar>
            </a>
          </Dropdown>
          <div
            className="notification-icon"
            style={{ marginRight: "20px", width: "fit-content" }}
          >
            <Popover
              trigger="click"
              overlayClassName="notification-popup"
              open={notiVisible}
              placement="bottomRight"
              onOpenChange={handleOpenChange}
              content={handleNotificationContent}
            >
              <Badge /* size="small" */ color="red" count={unseen}>
                <BellFilled
                  style={{ cursor: "pointer" }}
                  onClick={() => setNotiVisible(!notiVisible)}
                />
              </Badge>
            </Popover>
          </div>
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <Button
              className="theme-bg-gradient"
              onClick={handleOnCreateClick}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#fff",
                margin: "0 20px",
              }}
            >
              {plusIcon}
              Create
            </Button>
          </Dropdown>
          {userRole === AppConstants.OWNER ? (
            <Button
              onClick={() => dispatch(setShowNewInviteModalOpen(true))}
              className="theme-bg-gradient"
              style={{
                display: "flex",
                alignItems: "center",
                color: "#fff",
              }}
            >
              <SendOutlined />
              Invite
            </Button>
          ) : null}
        </Col>
      </Row>
      <Modal
        title="Change Password"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          onFinish={onFinish}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 500,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <Form.Item
            style={{ margin: 4 }}
            label="Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
          <Form.Item
            style={{ margin: 4 }}
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters",
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
          <Form.Item
            style={{ margin: 4 }}
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 20,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="theme-bg-gradient"
              style={{ marginTop: "10px", width: "100%" }}
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Header;
