import { Avatar, Button, Popover, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import AppConstants from "../../utils/AppConstants";
import {
  getAssetHistory,
  getAssignedAssets,
  recoverAsset,
} from "../../redux/slices/AssetSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  HistoryOutlined,
  InfoCircleOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import ConfirmModal from "../common/ConfirmModal";
import AssetsHistoryModal from "./AssetsHistoryModal";

const AssignedAssets = () => {
  const dispatch = useDispatch();
  const { assignedAssets } = useSelector((state) => state.asset);
  const [tableData, setTableData] = useState([]);
  const [showRecoverPopup, setShowRecoverPopup] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [assetToRecover, setAssetToRecover] = useState({});

  const propertyContent = (properties) => {
    if (properties?.length) {
      return (
        <div style={{ padding: "10px" }}>
          {" "}
          {properties.map((property) => (
            <div className="d-flex align-center ">
              <p style={{ minWidth: "80px" }}> {property.key}</p>
              <span>:</span>
              <p>{property.value} </p>
            </div>
          ))}
        </div>
      );
    }
  };
  const columns = [
    {
      title: "Asset",
      dataIndex: "assetName",
      key: "assetName",
      width: "150px",
      render: (name, item) => (
        <div className="asset-info d-flex align-center justify-space-between">
          <span> {name}</span>
          {item?.assetProperties?.length ? (
            <Popover
              content={() => propertyContent(item.assetProperties)}
              title="Properties"
              overlayClassName="properties-popover"
            >
              <InfoCircleOutlined
                style={{ color: "#0000aa", cursor: "pointer" }}
              />
            </Popover>
          ) : null}
        </div>
      ),
    },
    {
      title: "Serial ID",
      dataIndex: "serialId",
      key: "serialId",
      width: "150px",
      render: (text) => (
        <>
          {" "}
          {text?.length < 17 ? (
            <p style={{ margin: 0, color: "#000" }}>{text}</p>
          ) : (
            <Tooltip title={text}>
              <p style={{ margin: 0, color: "#000" }}>
                {`${text?.slice(0, 16)}...`}
              </p>
            </Tooltip>
          )}{" "}
        </>
      ),
    },
    {
      title: "Assignee",
      dataIndex: "assignedTo",
      key: "assignedTo",
      width: "200px",
      render: (assignedTo) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Avatar
            alt=""
            // icon={<UserOutlined />}
            className="avatar-text-img"
            src={
              assignedTo?.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` +
                  assignedTo?.email?.replace("@", "%40")
                : ""
            }
          >
            {assignedTo?.name?.slice(0, 1)}
          </Avatar>
          {assignedTo?.name}
        </div>
      ),
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      key: "assignedBy",
      width: "200px",
      render: (assignedBy) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Avatar
            alt=""
            // icon={<UserOutlined />}
            className="avatar-text-img"
            src={
              assignedBy?.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` +
                  assignedBy?.email?.replace("@", "%40")
                : ""
            }
          >
            {assignedBy?.name?.slice(0, 1)}
          </Avatar>
          {assignedBy?.name}
        </div>
      ),
    },
    {
      title: "Assigned At",
      dataIndex: "assignedAt",
      key: "assignedAt",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text) => (
    //     <p
    //       className={
    //         text == "Assigned" ? "primary-highlight" : "secondary-highlight"
    //       }
    //     >
    //       {text}
    //     </p>
    //   ),
    // },
    {
      title: "Type",
      dataIndex: "recoverable",
      key: "recoverable",
      render: (text) => (
        <p
          className={text ? "primary-highlight" : "todo-highlight"}
          style={{ minWidth: "110px" }}
        >
          {text ? "Recoverable" : "Non Recoverable"}
        </p>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          {record?.recoverable ? (
            <Tooltip title="Recover">
              <UndoOutlined
                style={{ color: "#00a100" }}
                onClick={() => {
                  setAssetToRecover(record);
                  setShowRecoverPopup(true);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Recover">
              <UndoOutlined
                style={{ color: "#a9a9a9", cursor: "not-allowed" }}
              />
            </Tooltip>
          )}
          <Tooltip title="History">
            <HistoryOutlined
              onClick={() => {
                dispatch(getAssetHistory(record.assetId));
                setShowHistory(true);
              }}
              style={{ marginLeft: "10px", color: "blue", cursor: "pointer" }}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  //Export Headers
  const headers = [
    { label: "Asset", key: "assetName" },
    { label: "Serial Id", key: "serialId" },
    { label: "Recoverable", key: "recoverable" },
    { label: "Assigned To", key: "assignedTo.name" },
    { label: "Assigned By", key: "assignedBy.name" },
    { label: "Assigned At", key: "assignedAt" },
  ];

  useEffect(() => {
    dispatch(getAssignedAssets());
  }, []);

  useEffect(() => {
    if (assignedAssets?.length) {
      let data = assignedAssets.map((item) => ({
        id: item._id,
        serialId: item?.asset?.serialId ? item?.asset?.serialId : "NA",
        assetName: item.asset?.name,
        assetId: item.asset?._id,
        assetProperties: item.asset?.properties
          ? JSON.parse(item.asset?.properties)
          : [],
        assignedTo: item.assignedTo,
        assignedBy: item.assignedBy,
        status: item.status,
        assignedAt: item?.assignedAt
          ? moment(item.assignedAt).format("MMM DD, YYYY")
          : "",
        recoverable: item.asset?.recoverable,
      }));
      setTableData([...data]);
    }
  }, [assignedAssets]);

  const handleRecoverAsset = () => {
    dispatch(recoverAsset(assetToRecover?.id));
    handleCloseRecoverPopup();
  };

  const handleCloseRecoverPopup = () => {
    setAssetToRecover({});
    setShowRecoverPopup(false);
  };
  return (
    <div className="assigned-assets-table" style={{ margin: "20px 0" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0px 10px",
          marginBottom: "10px",
        }}
      >
        <Button
          className="theme-bg-gradient"
          style={{
            color: "#fff",
          }}
        >
          <CSVLink
            filename={"Assigned Assets.csv"}
            data={tableData}
            headers={headers}
            onClick={() => {}}
          >
            Export to CSV
          </CSVLink>
        </Button>
      </div>
      <ConfirmModal
        title={`Are you sure you want to recover ${assetToRecover?.assetName} from ${assetToRecover?.assignedTo?.name}?`}
        isOpen={showRecoverPopup}
        handleClose={handleCloseRecoverPopup}
        handleConfirm={handleRecoverAsset}
      />
      <AssetsHistoryModal
        isOpen={showHistory}
        close={() => {
          setShowHistory(false);
        }}
      />
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        scroll={{
          x: 1200,
          y: 550,
        }}
        bordered
      />
    </div>
  );
};

export default AssignedAssets;
