import { Avatar, Button, Form, Input, Modal, Radio, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowNewProjectModalOpen } from "../../redux/slices/CreateButtonSlice";
import { takeProject } from "../../redux/slices/ProjectSlice";
import AppConstants from "../../utils/AppConstants";
import { getDashboardDatasOfUser } from "../../redux/slices/UserSlice";

function NewProjectModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectTeamDropdown, setProjectTeamDropdown] = useState([]);

  const dispatch = useDispatch();

  const { isNewProjectModalOpen } = useSelector((state) => state.createButton);
  const { activeCompany } = useSelector((state) => state.user);
  const { loginUser } = useSelector((state) => state.login);
  const { projectNamesList } = useSelector((state) => state.project);

  useEffect(() => {
    let teamArray = [];
    if (Object.keys(activeCompany).length !== 0) {
      activeCompany.users?.forEach((user) => {
        if (user?._id !== loginUser?._id) {
          let element = (
            <div>
              <Avatar
                alt=""
                size={25}
                className="avatar-text-img"
                style={{
                  marginRight: "10px",
                }}
                src={
                  user?.isUserProfileUploaded
                    ? `${AppConstants.BUCKET_URL}` +
                      user?.email.replace("@", "%40")
                    : ""
                }
              >
                {user.name?.slice(0, 1)}
              </Avatar>
              {user.name}
            </div>
          );
          let teamObj = { label: element, value: user?._id, name: user?.name };
          teamArray.push(teamObj);
        }
      });
      setProjectTeamDropdown(teamArray);
    }
  }, [activeCompany]);

  useEffect(() => {
    if (isNewProjectModalOpen) {
      setIsModalOpen(isNewProjectModalOpen);
    }
  }, [isNewProjectModalOpen]);

  const onFinish = (values) => {
    if (!values.team) {
      values.team = [];
    }
    values.team.push(loginUser._id);
    const projectPayload = {
      name: values?.name?.trim(),
      description: values?.description?.trim(),
      companyId: activeCompany?._id,
      createdBy: loginUser?._id,
      team: values?.team,
      type: values?.type,
    };
    dispatch(takeProject(projectPayload));
    dispatch(getDashboardDatasOfUser(loginUser?._id));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    dispatch(setShowNewProjectModalOpen(false));
  };

  return (
    <>
      <Modal
        title="Create New Project"
        centered
        open={isModalOpen}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form
          name="complex-form"
          layout="vertical"
          preserve={false}
          onFinish={onFinish}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={
            {
              // maxWidth: 600,
            }
          }
        >
          <Form.Item label="* Name">
            <Space>
              <Form.Item
                name="name"
                noStyle
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.trim().length > 0) {
                        if (
                          !projectNamesList.includes(value.toLowerCase().trim())
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Duplicate project name");
                        }
                      } else {
                        return Promise.reject("Project name is required");
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Enter Project Name" />
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item
            label="Description"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="description"
              style={{
                display: "inline-block",
                // width: '100%',
              }}
            >
              <Input.TextArea
                placeholder="Enter Project Description"
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Team">
            <Input.Group>
              <Form.Item name="team">
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  mode="multiple"
                  // style={{ width: '100%' }}
                  options={projectTeamDropdown}
                  placeholder="Select Team"
                  filterOption={(inputValue, option) => {
                    if (
                      option.name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    ) {
                      return true;
                    } else return false;
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Radio.Group defaultValue={AppConstants.REGULAR}>
              <Radio value={AppConstants.REGULAR}>{AppConstants.REGULAR}</Radio>
              <Radio value={AppConstants.ADMIN}>{AppConstants.ADMIN}</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label=" " colon={false}>
            <div className="d-flex align-center justify-space-between">
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                className="theme-bg-gradient"
                style={{ marginLeft: "10px" }}
              >
                Create
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default NewProjectModal;
