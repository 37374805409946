import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assets: [],
  assetCategories: [],
  categoryNames: [],
  selectedCategory: null,
  selectedAsset: null,
  myAssets: [],
  assignedAssets: [],
  recoveredAssets: [],
  history: [],
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    getAssetCategories: () => {},
    setAssetCategories: (state, action) => {
      state.assetCategories = action.payload;
      state.categoryNames = action.payload.map((category) =>
        category.name.toLowerCase()
      );
    },
    addAssetCategory: () => {},
    addCategoryToList: (state, action) => {
      let categories = [...state.assetCategories];
      categories.push(action.payload);
      state.assetCategories = categories;

      let names = [...state.categoryNames];
      names.push(action.payload.name.toLowerCase());
      state.categoryNames = names;
    },
    updateAssetCategory: () => {},
    updateAssetCategoryList: (state, action) => {
      let categories = [...state.assetCategories];
      let index = categories.findIndex((c) => c._id === action.payload._id);
      categories[index] = action.payload;
      state.assetCategories = categories;

      state.categoryNames = categories.map((category) =>
        category.name.toLowerCase()
      );

      state.selectedCategory = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
    // getAssets: () => {},
    // setAssets: (state, action) => {
    //   state.assets = action.payload;
    // },
    getCompanyAssets: () => {},
    setCompanyAssets: (state, action) => {
      state.assets = action.payload;
    },
    addAsset: () => {},
    addNewAssetToList: (state, action) => {
      let assets = [...state.assets];
      assets.unshift(action.payload);
      state.assets = assets;
    },
    addMoreAssets: () => {},
    updateAsset: () => {},
    updateAssetInAssetList: (state, action) => {
      let assets = [...state.assets];
      let index = assets.findIndex(
        (asset) => asset._id === action.payload?._id
      );

      assets[index] = action.payload;
      state.selectedAsset = action.payload;
      state.assets = assets;
    },

    getAssignedAssets: () => {},
    setAssignedAssets: (state, action) => {
      state.assignedAssets = action.payload;
    },

    getRecoveredAssets: () => {},
    setRecoveredAssets: (state, action) => {
      state.recoveredAssets = action.payload;
    },

    getMyAssets: () => {},
    setMyAssets: (state, action) => {
      state.myAssets = action.payload;
    },

    assignAsset: () => {},
    updateAssetsAfterAssign: (state, action) => {
      let assets = [...state.assets];
      let index = assets.findIndex(
        (asset) => asset._id === action.payload?._id
      );
      assets[index] = action.payload;
      state.assets = assets;
    },

    recoverAsset: () => {},
    updateAssetsAfterRecover: (state, action) => {
      let assignedAssets = [...state.assignedAssets];
      assignedAssets = assignedAssets.filter(
        (asset) =>
          action.payload &&
          asset._id.toString() !== action.payload?._id?.toString()
      );
      state.assignedAssets = assignedAssets;
    },

    resetAllAssets: (state, action) => {
      state.assets = [];
      state.assetCategories = [];
      state.categoryNames = [];
      state.selectedCategory = null;
      state.selectedAsset = null;
      state.myAssets = [];
      state.assignedAssets = [];
      state.recoveredAssets = [];
      state.history = [];
    },

    deleteAsset: () => {},
    updateAssetsAfterDelete: (state, action) => {
      let assets = [...state.assets];
      assets = assets.filter(
        (asset) => asset._id?.toString() !== action.payload?.toString()
      );
      state.assets = assets;
      state.selectedAsset = null;
    },

    deleteCategory: () => {},
    updateCategoryListAfterDelete: (state, action) => {
      let categories = [...state.assetCategories];
      categories = categories.filter(
        (c) => c._id?.toString() !== action.payload?.toString()
      );
      state.assetCategories = categories;

      state.categoryNames = categories.map((category) =>
        category.name.toLowerCase()
      );
      if (state.selectedCategory?._id.toString() === action.payload?.toString())
        state.selectedCategory = null;
    },
    updateAssetStatus: () => {},
    getAssetHistory: () => {},
    setAssetHistory: (state, action) => {
      state.history = action.payload;
    },
  },
});

export const {
  getAssetCategories,
  setAssetCategories,
  addAssetCategory,
  addCategoryToList,
  updateAssetCategory,
  updateAssetCategoryList,
  setSelectedCategory,
  setSelectedAsset,
  addAsset,
  addNewAssetToList,
  updateAsset,
  addMoreAssets,
  updateAssetInAssetList,
  getAssets,
  setAssets,
  getCompanyAssets,
  setCompanyAssets,
  getAssignedAssets,
  setAssignedAssets,
  getRecoveredAssets,
  setRecoveredAssets,
  getMyAssets,
  setMyAssets,
  assignAsset,
  updateAssetsAfterAssign,
  recoverAsset,
  updateAssetsAfterRecover,
  resetAllAssets,
  deleteAsset,
  deleteCategory,
  updateAssetsAfterDelete,
  updateCategoryListAfterDelete,
  updateAssetStatus,
  getAssetHistory,
  setAssetHistory,
} = assetSlice.actions;
export default assetSlice.reducer;
