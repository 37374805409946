import React, { useEffect, useState } from "react";
import { Layout, Menu, Button, Typography, Card, Form, Input } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/images/ontrack.png";
import {
  getCompanyNamesList,
  getUserInvite,
  takeUsers,
} from "../redux/slices/UserSlice";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

function SignUp() {
  const [step1, setStep1] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { userInvite, companyNameList } = useSelector((state) => state.user);
  const { userInviteId } = useParams();
  const navigate = useNavigate();

  const onFinishStep1 = (values) => {
    setCompanyName(values?.company.trim());
    setStep1(false);
  };

  useEffect(() => {
    dispatch(getCompanyNamesList());
  }, []);

  useEffect(() => {
    if (!userInvite.userExists && userInvite.userInvite?.email) {
      form.setFieldsValue({
        email: userInvite.userInvite.email,
      });
    } else if (userInvite.userExists === true) {
      navigate("/sign-in");
    }
  }, [form, userInvite]);

  useEffect(() => {
    if (userInviteId) {
      dispatch(getUserInvite({ id: userInviteId }));
      setStep1(false);
    }
  }, [dispatch, userInviteId]);

  const onFinish = (values) => {
    const userPayload = {
      name: values.name.trim(),
      email: values.email.trim(),
      password: values.password,
      company: companyName,
      userInvite: userInvite.userInvite,
    };
    dispatch(takeUsers(userPayload));
    navigate("/sign-in");
  };

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div style={{ width: "130px" }}>
            <img src={logo} alt="Grappler" style={{ margin: "10px 20px" }} />
          </div>
        </Header>
        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content" style={{ paddingTop: "10px" }}>
              <Title>Sign Up</Title>
              <p className="text-lg">
                Create a new free account and enjoy using Grappler
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Register New User</h5>}
            bordered="false"
            style={{ marginTop: "-240px" }}
          >
            {step1 && (
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinishStep1}
                className="row-col"
              >
                <Form.Item
                  name="company"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length > 0) {
                          if (
                            !companyNameList.includes(
                              value.toLowerCase().trim()
                            )
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Duplicate company name");
                          }
                        } else {
                          return Promise.reject("Company name is required");
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter your company name" />
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                    className="theme-bg-color-primary"
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            )}
            {!step1 && (
              <>
                {/* <div className="sign-up-gateways">
                  <Button type="false">
                    <img src={logo3} alt="logo 3" />
                  </Button>
                </div>

                <p className="text-center my-25 font-semibold text-muted">Or</p> */}
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  className="row-col"
                  form={form}
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (value && value.trim().length > 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Name can not be blank");
                          }
                        },
                        message: "Name is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your name" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Email is required!",
                      },
                      {
                        type: "email",
                        message: "Please provide a valid email address",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your email"
                      disabled={userInvite.userInvite?.email ? true : false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required!",
                      },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter your password" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      className="theme-bg-color-primary"
                    >
                      SIGN UP
                    </Button>

                    <Button
                      style={{ width: "100%", marginTop: "1rem" }}
                      type="default"
                      onClick={() => setStep1(true)}
                    >
                      Back
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
            <p className="font-semibold text-muted text-center">
              Already have an account?{" "}
              <Link to="/sign-in" className="font-bold text-dark">
                Sign In
              </Link>
            </p>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            Copyright © 2024 Grappler by Innogent Technologies
          </p>
        </Footer>
      </div>
    </>
  );
}

export default SignUp;
