import { Avatar, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppConstants from "../../utils/AppConstants";

const TeamMembersCard = () => {
  const { activeCompany } = useSelector((state) => state.user);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    let data = [];
    activeCompany?.users?.forEach((user, index) => {
      let userRole = user?.role?.filter(
        (roleUser) => roleUser?.company === activeCompany?._id
      );

      data.push({
        key: index,
        name: {
          name: user?.name,
          email: user?.email,
          src: user?.isUserProfileUploaded
            ? `${AppConstants.BUCKET_URL}` + user?.email?.replace("@", "%40")
            : "",
        },
        role: userRole[0]?.name,
      });
    });
    setUserList(data);
  }, [activeCompany]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "300px",
      key: "name",
      render: (info) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            // size={40}
            style={{ backgroundColor: "#f56a00", marginRight: "5px" }}
            src={info.src}
          >
            {info?.name?.slice(0, 1)}
          </Avatar>

          <div style={{ marginLeft: "10px" }}>
            <h5>{info?.name}</h5>
            <p>{info?.email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "80px",
      render: (role) => (
        <p
          className={
            role == "Creator"
              ? "primary-highlight"
              : role == "User"
              ? "secondary-highlight"
              : "tertiary-highlight"
          }
          style={{ maxWidth: "70px" }}
        >
          {role}
        </p>
      ),
    },
  ];

  return (
    <div>
      <h1 className="theme-color-primary page-heading">Colleagues</h1>
      <Table
        scroll={{
          x: 200,
          y: 300,
        }}
        dataSource={userList}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default TeamMembersCard;
