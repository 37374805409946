import { Button, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowNewInviteModalOpen } from "../../redux/slices/CreateButtonSlice";
import { sendInvites } from "../../redux/slices/UserSlice";
import CreatableSelect from "react-select/creatable";
import AppConstants from "../../utils/AppConstants";

function NewInviteModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [roleDropdown, setRoleDropdown] = useState([]);
  const [departmentDropdown, setDepartmentDropdown] = useState([]);

  const { projects } = useSelector((state) => state.project);
  const { isNewInviteModalOpen } = useSelector((state) => state.createButton);
  const { allUsers, activeCompany, departments } = useSelector(
    (state) => state.user
  );
  const [inviteData, setInviteData] = useState({
    users: [],
    projects: [],
    role: "",
    department: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let projectArray = [];
    projects.forEach((project) => {
      let projectDropdownVariable = { label: project.name, value: project._id };
      projectArray.push(projectDropdownVariable);
    });
    setProjectDropdown(projectArray);

    let rolesArray = [];
    activeCompany?.roles?.forEach((role) => {
      if (role.name !== AppConstants.OWNER) {
        const roleObj = { label: role.name, value: role._id };
        rolesArray.push(roleObj);
      }
    });
    setRoleDropdown(rolesArray);
  }, [projects]);

  useEffect(() => {
    let departmentDropdown = [];
    departments.forEach((department) =>
      departmentDropdown.push({ label: department.name, value: department._id })
    );
    setDepartmentDropdown(departmentDropdown);
  }, [departments]);

  useEffect(() => {
    let users = [];
    if (allUsers) {
      allUsers.forEach((user) => {
        const found = activeCompany.users.some((el) => el.name === user.name);
        if (!found) users.push({ label: user.email, value: user.email });
      });
      setUsersDropdown([...users]);
    }
  }, [activeCompany.users, allUsers]);

  useEffect(() => {
    if (isNewInviteModalOpen) {
      setIsModalOpen(isNewInviteModalOpen);
    }
  }, [isNewInviteModalOpen]);

  const sendInvite = () => {
    dispatch(
      sendInvites({
        companyId: activeCompany._id,
        userInvites: inviteData,
      })
    );
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    dispatch(setShowNewInviteModalOpen(false));
  };

  const handleProjects = (projects) => {
    let projectIds = projects.map((project) => project);
    setInviteData((prevState) => ({
      ...prevState,
      projects: projectIds,
    }));
  };

  const handleUsers = (users) => {
    const invitees = users.map((user) => user.value);
    setInviteData((prevState) => ({
      ...prevState,
      users: invitees,
    }));
  };

  const handleRole = (roleId) => {
    setInviteData((prevState) => ({
      ...prevState,
      role: roleId,
    }));
  };

  const handleDepartment = (departmentId) => {
    setInviteData((prevState) => ({
      ...prevState,
      department: departmentId,
    }));
  };

  return (
    <>
      <Modal
        style={{ overflow: "hidden", height: "450px" }}
        title={`Invite people to ${activeCompany.name}`}
        centered
        open={isModalOpen}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical" onFinish={sendInvite}>
          <div>
            {/* <Form.Item label="Company">
              <Form.Item
                name="Company"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Select getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={companyDropdown}
                  placeholder="Select Company"
                  isValidNewOption={() => false}
                  defaultValue={{
                    label: activeCompany?.name,
                    value: activeCompany?._id,
                  }}
                  // onChange={handleChangeCompany}
                  disabled={true}
                />
              </Form.Item>
            </Form.Item> */}

            <Form.Item label="Project">
              <Form.Item
                name="Project"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={projectDropdown}
                  placeholder="Select Project"
                  isValidNewOption={() => false}
                  onChange={handleProjects}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="Role">
              <Form.Item
                name="role"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={roleDropdown}
                  placeholder="Select Role"
                  isValidNewOption={() => false}
                  onChange={handleRole}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="Department">
              <Form.Item
                name="department"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={departmentDropdown}
                  placeholder="Select department"
                  isValidNewOption={() => false}
                  onChange={handleDepartment}
                />
              </Form.Item>
            </Form.Item>
          </div>
          <div>
            <p
              style={{
                margin: 0,
                fontSize: "12px",
                fontWeight: 500,
                color: "#6d6e6f",
              }}
            >
              Your teammates will get an email that gives them access to your
              team.
            </p>
            <Form.Item label="Users">
              <Form.Item
                name="Users"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <CreatableSelect
                  maxMenuHeight={200}
                  isClearable
                  isMulti
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  placeholder="Please select"
                  onChange={handleUsers}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Menu: () => null,
                  }}
                  options={usersDropdown}
                />
              </Form.Item>
            </Form.Item>
          </div>
          <Form.Item style={{ padding: "20px 0" }}>
            <div className="d-flex align-center justify-space-between">
              <Button htmlType="button" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="theme-bg-gradient"
                style={{ marginLeft: "10px" }}
              >
                Send Invite
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default NewInviteModal;
