import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import showMessage from "../utils/messageUtil";

export function LoginFail() {
  const { message } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    let errorMessage = message.replaceAll("_", " ");
    const response = {
      error: "Login failed",
      message: errorMessage,
    };
    showMessage(response, "Login Successful", "Login Failed : ");
    navigate("/");
  }, []);

  return <div>Thanks for loggin in!</div>;
}
