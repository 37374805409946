import axios from "axios";
import AppConstants from "./utils/AppConstants";

const API_ROOT = process.env.API_ROOT
  ? process.env.API_ROOT
  : AppConstants.SERVER_URL;

const responseBody = (res) => res.data;
const errorBody = (err) => err.response?.data;

const tokenPlugin = (req) => {
  if (localStorage.getItem("jwt")) {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    return headers;
  }
};

const requests = {
  delete: async (url) => {
    return await axios
      .delete(`${API_ROOT}${url}`, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
  get: async (url) => {
    return await axios
      .get(`${API_ROOT}${url}`, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
  put: async (url, body) => {
    return await axios
      .put(`${API_ROOT}${url}`, body, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
  patch: async (url, body) => {
    return await axios
      .patch(`${API_ROOT}${url}`, body, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
  post: async (url, body) => {
    return await axios
      .post(`${API_ROOT}${url}`, body, tokenPlugin())
      .then(responseBody)
      .catch(errorBody);
  },
};

const Auth = {
  login: (loginUserPayload) => {
    return requests.post("/api/v1/users/login", loginUserPayload);
  },
  getCurrentUser: () => {
    return requests.get("/api/v1/users/currentUser");
  },
};

const Project = {
  getProjectsByCompanyId: (companyId) => {
    return requests.get(
      "/api/v1/projects/companyProjects?companyId=" + companyId
    );
  },
  getTasksByProject: (projectId, view) => {
    return requests.get(`/api/v1/projects/tasks?id=${projectId}&view=${view}`);
  },
  createProject: (projectPayload) => {
    return requests.post("/api/v1/projects", projectPayload);
  },
  getAllProjectNamesOfActiveCompany: () => {
    return requests.get("/api/v1/projects/names");
  },
  updateProject: (projectId, updateField) => {
    return requests.patch("/api/v1/projects/" + projectId, updateField);
  },
  addTeamMemberByProjectId: (projectId, addedUsers) => {
    return requests.patch(
      "/api/v1/projects/addMembers/" + projectId,
      addedUsers
    );
  },
  removeTeamMemberByProjectId: (projectId, removedUser) => {
    return requests.patch(
      "/api/v1/projects/removeMember/" + projectId,
      removedUser
    );
  },
  delete: (projectId) => {
    return requests.delete("/api/v1/projects/" + projectId);
  },
  getClosedTasksByProjectId: (projectId) => {
    return requests.get("/api/v1/projects/closedTasks/" + projectId);
  },
  getProjectAudits: (projectId) => {
    return requests.get("/api/v1/projects/audits/" + projectId);
  },
  getProjectById: (projectId) => {
    return requests.get("/api/v1/projects/" + projectId);
  },
  getTaskCount: (project, user) => {
    return requests.get("/api/v1/projects/taskCount/" + project + "/" + user);
  },
};

const View = {
  getViewsByProjectId: (projectId) => {
    return requests.get("/api/v1/views/" + projectId);
  },
  addView: (payload) => {
    return requests.post("/api/v1/views", payload);
  },
  deleteView: (viewId) => {
    return requests.delete("/api/v1/views/" + viewId);
  },
  updateView: (viewId, payload) => {
    return requests.patch("/api/v1/views/" + viewId, payload);
  },
};

const Task = {
  updateTaskField: (taskId, filter) => {
    return requests.patch("/api/v1/tasks/" + taskId, filter);
  },
  updateAccountableAssigneeByTaskId: (taskId, filter) => {
    return requests.patch(
      "/api/v1/tasks/updateAccountableAssignee/" + taskId,
      filter
    );
  },
  delete: (taskId) => {
    return requests.delete("/api/v1/tasks/" + taskId);
  },
  createTask: (taskPayload) => {
    return requests.post("/api/v1/tasks", taskPayload);
  },
  addComment: (commentPayload) => {
    return requests.post("/api/v1/tasks/addComment", commentPayload);
  },
  getComments: (taskId) => {
    return requests.get("/api/v1/tasks/comments/" + taskId);
  },
  deleteComment: (commentId) => {
    return requests.delete("/api/v1/tasks/comments/" + commentId);
  },
  editComment: (commentId, comment) => {
    return requests.patch("/api/v1/tasks/comments/" + commentId, comment);
  },
  closeTaskById: (taskId, closed) => {
    return requests.patch("/api/v1/tasks/closeTask/" + taskId, closed);
  },
  getTaskAudits: (taskId) => {
    return requests.get("/api/v1/tasks/audits/" + taskId);
  },
  getTaskById: (taskId) => {
    return requests.get("/api/v1/tasks/" + taskId);
  },
  getAllReaction: () => {
    return requests.get("/api/v1/tasks/comments/reactions");
  },
  addReaction: (payload) => {
    return requests.post("/api/v1/tasks/commentReaction", payload);
  },
  removeReaction: (id) => {
    return requests.delete("/api/v1/tasks/commentReaction/" + id);
  },
};

const User = {
  getUsers: () => {
    return requests.get("/api/v1/users");
  },
  createUser: (userPayload) => {
    return requests.post("/api/v1/users", userPayload);
  },
  addUser: (companyId, userPayload) => {
    return requests.post("/api/v1/users/addUser/" + companyId, userPayload);
  },
  resetPassword: (passwords) => {
    return requests.post("/api/v1/users/resetPassword", passwords);
  },
  forgotPassword: (email) => {
    return requests.post("/api/v1/users/forgotPassword", email);
  },
  updateRole: (userId, updatedRole) => {
    return requests.patch(
      "/api/v1/users/updateUserRole/" + userId,
      updatedRole
    );
  },
  userProfileUpload: (userId, profile) => {
    return requests.patch(
      "/api/v1/users/userProfileUploaded/" + userId,
      profile
    );
  },
  getNotificationsByCompanyId: (companyId) => {
    return requests.get("/api/v1/users/notifications/" + companyId);
  },
  getAllNotificationsByCompanyId: (companyId) => {
    return requests.get("/api/v1/users/notifications/all/" + companyId);
  },

  markNotificationsSeenByCompanyId: (companyId, notificationId) => {
    return requests.patch(
      "/api/v1/users/marknotificationsSeen/" + companyId,
      notificationId
    );
  },

  deleteNotificationById: (notificationId) => {
    return requests.delete(
      "/api/v1/users/deleteNotification/" + notificationId
    );
  },
  getAllDepartments: (companyId) => {
    return requests.get("/api/v1/users/departments/" + companyId);
  },
  updateUserDepartment: (userPayload, companyId) => {
    return requests.patch(
      "/api/v1/users/userDepartments/" + companyId,
      userPayload
    );
  },
};

const Company = {
  getSelectedCompany: (companyId) => {
    return requests.get("/api/v1/company/current/" + companyId);
  },
  sendInvite: (companyId, data) => {
    return requests.post("/api/v1/company/inviteUser/" + companyId, data);
  },
  getUserInvite: (id) => {
    return requests.get("/api/v1/company/inviteUser/" + id);
  },
  getAllCompaniesName: () => {
    return requests.get("/api/v1/company/companyName");
  },
  removeUser: (userId) => {
    return requests.delete("/api/v1/company/removeUser/" + userId);
  },
  getDashboardDatas: (userId) => {
    return requests.get("/api/v1/company/dashboardData/" + userId);
  },
  searchResults: (search, filterArray) => {
    return requests.get(
      "/api/v1/company/searchResult?search=" +
        search +
        "&filter=" +
        JSON.stringify(filterArray)
    );
  },
  getTasksByUserId: (userId) => {
    return requests.get("/api/v1/company/userAssignedTasks/" + userId);
  },
};

const Asset = {
  addAssetCategory: (assetCategoryPayload) => {
    return requests.post("/api/v1/assets/categories", assetCategoryPayload);
  },
  getAssetCategories: () => {
    return requests.get("/api/v1/assets/categories");
  },
  updateAssetCategory: (id, updatePayload) => {
    return requests.patch("/api/v1/assets/categories/" + id, updatePayload);
  },
  deleteCategory: (id) => {
    return requests.delete("/api/v1/assets/categories/" + id);
  },
  deleteAsset: (id) => {
    return requests.delete("/api/v1/assets/" + id);
  },
  addAsset: (assetPayload) => {
    return requests.post("/api/v1/assets", assetPayload);
  },
  getAssets: (categoryId) => {
    return requests.get("/api/v1/assets/" + categoryId);
  },
  getAssetsByCompany: (assetPayload) => {
    return requests.get("/api/v1/assets");
  },
  updateAsset: (id, updatePayload) => {
    return requests.put("/api/v1/assets/" + id, updatePayload);
  },
  assignAsset: (assetId, userIds) => {
    return requests.post("/api/v1/assets/assignAsset/" + assetId, userIds);
  },
  recoverAsset: (assetId) => {
    return requests.post("/api/v1/assets/recoverAsset/" + assetId);
  },
  getAssignedAssets: () => {
    return requests.get("/api/v1/assets/assignedAssets");
  },
  getRecoveredAssets: () => {
    return requests.get("/api/v1/assets/recoveredAssets");
  },
  getMyAssets: () => {
    return requests.get("/api/v1/assets/myAssets");
  },
  addMoreRecoverableAssets: (groupId, assetList) => {
    return requests.post("/api/v1/assets/addMoreAssets/" + groupId, assetList);
  },
  updateAssetStatus: (id, payload) => {
    return requests.post("/api/v1/assets/updateAssetStatus/" + id, payload);
  },
  getAssetHistory: (id) => {
    return requests.get("/api/v1/assets/history/" + id);
  },
};

const Tag = {
  getTagsByProject: (projectId) => {
    return requests.get("/api/v1/tags/" + projectId);
  },
  createTag: (payload) => {
    return requests.post("/api/v1/tags", payload);
  },
  editTag: (id, payload) => {
    return requests.put("/api/v1/tags/" + id, payload);
  },
  deleteTag: (id) => {
    return requests.delete("/api/v1/tags/" + id);
  },
};

export default {
  Auth,
  Project,
  Task,
  User,
  Company,
  View,
  Asset,
  Tag,
};
