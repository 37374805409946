const AppConstants = {
  OWNER: "Owner",
  CREATOR: "Creator",
  USER: "User",

  REGULAR: "Regular",
  ADMIN: "Admin",

  // development
  // PRIYANKA: "64ae8502fbb958529990cccd",
  // PUNIT: "64ae8917e4e67a7b889bc95b",

  // prod
  PRIYANKA: "64acfcadac39ce2516267890",
  PUNIT: "64acfccbac39ce25162678b1",

  // development
  // SERVER_URL: "http://localhost:8080",
  // CLIENT_URL: "http://localhost:3000",
  // WEBSOCKET_URL: "ws://localhost:8081",

  // production
  SERVER_URL: "https://grappler.innogent.in",
  CLIENT_URL: "https://grappler.innogent.in",
  WEBSOCKET_URL: "wss://grappler.innogent.in:8082",

  // BUCKET_NAME: "ontrack-development-bucket", // development
  BUCKET_NAME: "ontrack-prod-bucket", // production

  // BUCKET_URL:
    // "https://ontrack-development-bucket.s3.amazonaws.com/user_profile/", //development
  BUCKET_URL: "https://ontrack-prod-bucket.s3.amazonaws.com/user_profile/", //production

  TO_DO: "To Do",
  IN_PROGRESS: "In Progress",
  DONE: "Done",
  ON_HOLD: "On Hold",

  ADD_USER: "Added You to Company",
  INVITE_USER: "Invited You To Company",
  INVITE_NEW_USER: "Invited You To Grappler",
  ADD_MEMBER: "Added You to Project",
  REMOVE_MEMBER: "Removed You from Project",
  ASSIGNED_TASK: "Assigned You a Task",
  REMOVED_FROM_TASK: "Removed You from Assignees of Task",
  TASK_UPDATED: "Updated a Task",
  PROJECT_UPDATED: "Updated a Project",
  MENTIONED_IN_COMMENT: "Mentioned You in a Comment",
  ADDED_COMMENT: "Added a Comment",
  UPDATED_COMMENT: "Updated a Comment",
  UPDATE_ROLE: "Updated Your Role",
  UPDATE_DEPARTMENT: "Updated Your Department",
  REMOVE_USER: "Removed You from Company",
  TASK_CLOSED: "Closed a Task",
  TASK_DELETED: "Deleted a Task",
  PROJECT_DELETED: "Deleted a Project",
  ACCOUNTABLE_FOR_TASK: "Added You as the Accountable Assignee for Task",
  REMOVED_ACCOUNTABLE_FROM_TASK:
    "Removed you from the Accountable Assignee for Task",
  DAILY_REMINDER: "It looks like you have some pending tasks for today.",
  ASSIGNED_ASSET: "Assigned you an asset",
  RECOVERED_ASSET: "Recovered an asset from you",
  REACTED_ON_COMMENT: "Reacted on your comment",

  ENGINEERING: "Engineering",
  SALES: "Sales",
  HR: "HR",
  MARKETING: "Marketing",

  NEW: "New",
  ASSIGNED: "Assigned",
  RECOVERED: "Recovered",
  DAMAGED: "Damaged",
};

module.exports = AppConstants;
