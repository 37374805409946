import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../redux/slices/LoginSlice";

export function LoginSuccess() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCurrentUser(token));
    navigate("/");
  }, []);

  return <div>Thanks for loggin in!</div>;
}
