import { combineReducers } from "redux";
import login from "./slices/LoginSlice";
import createButton from "./slices/CreateButtonSlice";
import project from "./slices/ProjectSlice";
import task from "./slices/TaskSlice";
import user from "./slices/UserSlice";
import view from "./slices/ViewSlice";
import search from "./slices/SearchSlice";
import asset from "./slices/AssetSlice";
import tag from "./slices/TagSlice";

const rootReducer = combineReducers({
  login,
  project,
  createButton,
  task,
  user,
  view,
  search,
  asset,
  tag,
});

export default rootReducer;
