import {
  Avatar,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGlobalAddTask,
  setShowNewTaskModalOpen,
} from "../../redux/slices/CreateButtonSlice";
import { addNewTask, resetAddTaskPayload } from "../../redux/slices/TaskSlice";
import { getDashboardDatasOfUser } from "../../redux/slices/UserSlice";
import moment from "moment";
import AppConstants from "../../utils/AppConstants";
import {
  DoubleLeftOutlined,
  PauseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getDropdownTags, setDropdownTags } from "../../redux/slices/TagSlice";

function NewTaskModal() {
  const { isNewTaskModalOpen, globalAddTask } = useSelector(
    (state) => state.createButton
  );
  const { addTaskPayload } = useSelector((state) => state.task);
  const { projects, activeProject } = useSelector((state) => state.project);
  const { loginUser } = useSelector((state) => state.login);
  const { dropdownTags } = useSelector((state) => state.tag);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(!globalAddTask);
  const [newTask, setNewTask] = useState(addTaskPayload);
  const [projectDropdown, updateProjectDropdown] = useState([]);
  const [taskAssigneeDropdown, setAssigneeDropdown] = useState([]);
  const [accountableAssigneeDropdown, setAccountableAssigneeDropdown] =
    useState([]);
  const [accountableAssignee, setAccountableAssignee] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTimeState, setEndTime] = useState("");
  const [selectEndTime, setSelectEndTime] = useState(false);
  const [isAdminProject, setIsAdminProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tagDropdown, setTagDropdown] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const priority = [
    {
      label: (
        <div className="high-highlight">
          <DoubleLeftOutlined className="priority-high" /> High
        </div>
      ),
      value: "High",
    },
    {
      label: (
        <div className="medium-highlight">
          <PauseOutlined className="priority-medium" />
          Medium
        </div>
      ),
      value: "Medium",
    },
    {
      label: (
        <div className="low-highlight">
          <DoubleLeftOutlined className="priority-low" /> Low
        </div>
      ),
      value: "Low",
    },
  ];

  const [priorityDropdown, setPriorityDropown] = useState(priority);

  useEffect(() => {
    if (Object.keys(activeProject).length > 0) {
      setSelectedProject(activeProject);
      form.setFieldsValue({ project: activeProject?._id });
      dispatch(getDropdownTags(activeProject?._id));
    }
    return () => {
      dispatch(setDropdownTags([]));
    };
  }, []);

  useEffect(() => {
    let projectDropdownVariable = [];
    if (projects?.length > 0) {
      projects.forEach((project) => {
        let projectObj = { label: project?.name, value: project?._id };
        projectDropdownVariable.push(projectObj);
      });
      updateProjectDropdown(projectDropdownVariable);
    }
  }, [projects]);

  useEffect(() => {
    setDisabled(!globalAddTask);

    if (isNewTaskModalOpen) {
      setIsModalOpen(isNewTaskModalOpen);
    }
    setNewTask(addTaskPayload);
  }, [isNewTaskModalOpen, globalAddTask]);

  useEffect(() => {
    if (activeProject?.type === AppConstants.ADMIN) {
      setIsAdminProject(true);
    } else {
      setIsAdminProject(false);
      if (activeProject?.type !== AppConstants.ADMIN) {
        setIsAdminProject(false);
        let assigneeDropdownVariable = [];
        let statusDropdownVariable = [];
        activeProject?.team?.forEach((user) => {
          let element = (
            <div>
              <Avatar
                alt=""
                size={25}
                className="avatar-text-img"
                style={{
                  marginRight: "10px",
                }}
                src={
                  user?.isUserProfileUploaded
                    ? `${AppConstants.BUCKET_URL}` +
                      user?.email.replace("@", "%40")
                    : ""
                }
              >
                {user.name?.slice(0, 1)}
              </Avatar>
              {user.name}
            </div>
          );
          let assigneeObj = {
            label: element,
            value: user?._id,
            name: user?.name,
          };
          assigneeDropdownVariable.push(assigneeObj);
        });
        setAssigneeDropdown(assigneeDropdownVariable);
        setAccountableAssigneeDropdown([]);
        setAccountableAssignee(null);

        activeProject?.statusList?.forEach((status) => {
          let statusObj = { label: status, value: status };
          statusDropdownVariable.push(statusObj);
        });
        setStatusDropdown(statusDropdownVariable);
      }
    }
  }, [activeProject]);

  useEffect(() => {
    createTagDropdown();
  }, [dropdownTags]);

  const createTagDropdown = () => {
    let tagList = [];
    if (dropdownTags.length > 0) {
      tagList = dropdownTags.map((tag) => {
        let tagObj = {
          label: (
            <Tooltip title={tag.name}>
              <p
                className="primary-highlight"
                style={{ minWidth: "110px", backgroundColor: `${tag.color}` }}
              >
                {tag.name?.length < 15
                  ? tag.name
                  : `${tag.name.slice(0, 15)}...`}
              </p>
            </Tooltip>
          ),
          value: tag._id,
        };
        return tagObj;
      });
    }
    setTagDropdown(tagList);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAssigneeDropdown([]);
    setAccountableAssigneeDropdown([]);
    setAccountableAssignee(null);
    setTagDropdown([]);
    updateProjectDropdown([]);
    setStartDate("");
    setIsAdminProject(false);
    setEndTime("");
    setStatusDropdown([]);
    setSelectEndTime(false);
    setDisabled(false);
    dispatch(resetAddTaskPayload());
    dispatch(setShowNewTaskModalOpen(false));
    dispatch(setGlobalAddTask(true));
  };

  const handleAddTask = (values) => {
    let newTaskPayload = { ...newTask };
    newTaskPayload.name = values?.name?.trim();
    newTaskPayload.description = values?.description?.trim();
    newTaskPayload.project = globalAddTask
      ? values?.project
      : activeProject?._id;
    if (!isAdminProject) {
      newTaskPayload.assignee = values?.assignee;
      if (values?.assignee?.length > 1) {
        newTaskPayload.accountableAssignee = accountableAssignee;
      }
    } else {
      newTaskPayload.assignee = [AppConstants.PRIYANKA, AppConstants.PUNIT];
    }
    newTaskPayload.startDate = startDate;
    newTaskPayload.endDate = values?.endDate?._d;
    newTaskPayload.endTime = endTimeState;
    newTaskPayload.priority =
      values?.priority !== undefined ? values.priority : "Medium";
    newTaskPayload.tags = values.tags;
    if (globalAddTask) {
      newTaskPayload.status = isAdminProject
        ? AppConstants.TO_DO
        : values?.status || AppConstants.TO_DO;
    }
    dispatch(addNewTask(newTaskPayload));
    dispatch(getDashboardDatasOfUser(loginUser?._id));
    handleCloseModal();
  };

  const handleOnChangeProject = (e) => {
    let assigneeDropdownVariable = [];
    let statusDropdownVariable = [];
    form.setFieldsValue({ assignee: [] });
    form.setFieldsValue({ accountableAssignee: null });
    if (e) {
      let project = projects?.filter((project) => project._id === e)[0];
      setSelectedProject(project);
      dispatch(getDropdownTags(e));
      project?.team?.forEach((user) => {
        let element = (
          <div>
            <Avatar
              alt=""
              size={25}
              className="avatar-text-img"
              style={{ marginRight: "10px" }}
              src={
                user?.isUserProfileUploaded
                  ? `${AppConstants.BUCKET_URL}` +
                    user?.email.replace("@", "%40")
                  : ""
              }
            >
              {user.name?.slice(0, 1)}
            </Avatar>
            {user.name}
          </div>
        );

        let assigneeObj = {
          label: element,
          value: user?._id,
          name: user.name,
        };
        assigneeDropdownVariable.push(assigneeObj);
      });
      setAssigneeDropdown(assigneeDropdownVariable);
      setAccountableAssigneeDropdown([]);
      setAccountableAssignee(null);

      if (project?.type === AppConstants.ADMIN) {
        setIsAdminProject(true);
      } else {
        setIsAdminProject(false);

        project?.statusList?.forEach((status) => {
          let statusObj = { label: status, value: status };
          statusDropdownVariable.push(statusObj);
        });
        setStatusDropdown(statusDropdownVariable);
      }
    } else {
      dispatch(setDropdownTags([]));
      setSelectedProject(null);
      setAssigneeDropdown([]);
      setAccountableAssigneeDropdown([]);
      setAccountableAssignee(null);
      setStatusDropdown([]);
    }
  };

  const manageAccountableAssignee = (e) => {
    if (e.length > 1) {
      let team = [...taskAssigneeDropdown];
      let assigneeDropdownVariable = team?.filter((user) =>
        e?.includes(user?.value)
      );
      setAccountableAssigneeDropdown(assigneeDropdownVariable);
      if (accountableAssignee && !e.includes(accountableAssignee.toString())) {
        form.setFieldsValue({ accountableAssignee: null });
        setAccountableAssignee(null);
      }
    } else {
      setAccountableAssigneeDropdown([]);
      setAccountableAssignee(null);
    }
  };

  const handleOnChangeAccountableAssignee = (e) => {
    setAccountableAssignee(e);
  };

  // const handleOnChangeStartDate = (e) => {
  //   if (e?._d) {
  //     setStartDate(e._d);
  //   }

  //   if (!e && e?._d === undefined) {
  //     setStartDate("");
  //   }
  // };

  const handleOnChangeEndDate = (e) => {
    if (e?._d) {
      setEndDate(e._d);
      setSelectEndTime(true);
    }

    if (!e && e?._d === undefined) {
      setEndDate("");
      setSelectEndTime(false);
    }
  };

  const handleOnChangeEndTime = (time, timeString) => {
    setEndTime(timeString);
  };

  return (
    <>
      <Modal
        title="Create New Task"
        centered
        open={isModalOpen}
        footer={null}
        onCancel={handleCloseModal}
        className="create-task-modal"
      >
        <div className="new-task-form">
          <Form
            form={form}
            name="complex-form"
            layout="vertical"
            preserve={false}
            onFinish={handleAddTask}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={
              {
                // maxWidth: 600,
              }
            }
          >
            <Form.Item label="*Name">
              {/* <Space> */}
              <Form.Item
                name="name"
                noStyle
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (value && value.trim().length > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Task Name can not be blank");
                      }
                    },
                    message: "Task Name is required",
                  },
                ]}
              >
                <Input
                  style={
                    {
                      // width: 250,
                    }
                  }
                  placeholder="Enter Task Name"
                />
              </Form.Item>
              {/* </Space> */}
            </Form.Item>

            <Form.Item label="* Project">
              <Input.Group>
                <Form.Item
                  name="project"
                  rules={
                    globalAddTask
                      ? [
                          {
                            required: true,
                            message: "Project must be selected",
                          },
                        ]
                      : null
                  }
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                    // style={{ width: 250 }}
                    options={projectDropdown}
                    isValidNewOption={() => false}
                    disabled={disabled}
                    onChange={(e) => handleOnChangeProject(e)}
                    placeholder="Select a project"
                    defaultValue={disabled ? activeProject?.name : null}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <div className="d-flex align-center justify-space-between task-datepicker">
              <Form.Item
                label="End Date"
                style={{
                  marginBottom: 0,
                }}
              >
                <Form.Item
                  name="endDate"
                  style={{
                    display: "inline-block",
                    // width: 250,
                  }}
                >
                  <DatePicker
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabledDate={(d) => {
                      return moment().add(-1, "days") >= d;
                    }}
                    onChange={(e) => handleOnChangeEndDate(e)}
                    showToday={false}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item
                label="End Time"
                style={{
                  marginBottom: 0,
                }}
              >
                <Form.Item
                  name="endTime"
                  style={{
                    display: "inline-block",
                    // width: 250,
                  }}
                >
                  <TimePicker
                    getPopupContainer={(trigger) => trigger.parentNode}
                    use12Hours
                    onChange={handleOnChangeEndTime}
                    showNow={false}
                    format="h:mm A"
                    disabled={!selectEndTime}
                  />
                </Form.Item>
              </Form.Item>
            </div>

            <Form.Item
              label="Description"
              style={{
                marginBottom: 0,
              }}
            >
              <Form.Item
                name="description"
                style={{
                  display: "inline-block",
                  // width: 250,
                }}
              >
                <Input.TextArea
                  placeholder="Enter Task Description"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            </Form.Item>

            {selectedProject ? (
              <Form.Item label="Tags">
                <Input.Group>
                  <Form.Item name="tags">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      mode="multiple"
                      options={tagDropdown}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          {globalAddTask ? null : (
                            <>
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              />
                              <Space
                                style={{
                                  padding: "0 8px",
                                }}
                              >
                                <p
                                  style={{ textAlign: "center" }}
                                  onClick={handleCloseModal}
                                >
                                  <Link
                                    to={`/project/${activeProject?._id}/tags`}
                                  >
                                    Add more tags
                                  </Link>
                                </p>
                              </Space>
                            </>
                          )}
                        </>
                      )}
                      placeholder="Select Tags"
                      filterOption={(inputValue, option) => {
                        if (
                          option.name
                            ?.toLowerCase()
                            .includes(inputValue.toLowerCase())
                        ) {
                          return true;
                        } else return false;
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            ) : null}
            {taskAssigneeDropdown.length > 0 && !isAdminProject ? (
              <Form.Item label="Assignee">
                <Input.Group>
                  <Form.Item name="assignee">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      mode="multiple"
                      options={taskAssigneeDropdown}
                      placeholder="Select a assignee"
                      onChange={(e) => manageAccountableAssignee(e)}
                      filterOption={(inputValue, option) => {
                        if (
                          option.name
                            ?.toLowerCase()
                            .includes(inputValue.toLowerCase())
                        ) {
                          return true;
                        } else return false;
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            ) : null}

            {accountableAssigneeDropdown.length > 0 && !isAdminProject ? (
              <Form.Item label="Accountable Assignee">
                <Input.Group>
                  <Form.Item
                    name="accountableAssignee"
                    rules={[
                      {
                        required: true,
                        message: "Accountable Assignee is required",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      options={accountableAssigneeDropdown}
                      placeholder="Select an accountable assignee"
                      onChange={(e) => handleOnChangeAccountableAssignee(e)}
                      filterOption={(inputValue, option) => {
                        if (
                          option.name
                            ?.toLowerCase()
                            .includes(inputValue.toLowerCase())
                        ) {
                          return true;
                        } else return false;
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            ) : null}

            {!isAdminProject && statusDropdown.length > 0 ? (
              <Form.Item label="Status">
                <Input.Group>
                  <Form.Item name="status">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder="Select a status"
                      // style={{ width: 250 }}
                      disabled={disabled}
                      options={statusDropdown}
                      defaultValue={
                        addTaskPayload?.status || AppConstants.TO_DO
                      }
                    ></Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            ) : null}

            <Form.Item label="Priority">
              <Input.Group>
                <Form.Item name="priority">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placeholder="Select a priority"
                    // style={{ width: 250 }}
                    options={priorityDropdown}
                    defaultValue={priority[1]}
                  ></Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>

            {isAdminProject ? (
              <Form.Item label="Assignee">
                <Input.Group>
                  <Form.Item name="assignee">
                    <div className="d-flex align-center justify-space-between member-container">
                      <div className="d-flex align-center">
                        <Avatar
                          size="large"
                          className="avatar-text-img"
                          src={
                            `https://${AppConstants.BUCKET_URL}.s3.amazonaws.com/user_profile/` +
                            "priyanka.sharma@innogent.in".replace("@", "%40")
                          }
                        >
                          P
                        </Avatar>
                        <div className="overview-member">
                          <h5 style={{ margin: "0 0 0 4px " }}>
                            Priyanka Sharma
                          </h5>
                        </div>

                        <Avatar
                          style={{ marginLeft: "1rem" }}
                          size="large"
                          className="avatar-text-img"
                          src={
                            `https://${AppConstants.BUCKET_URL}.s3.amazonaws.com/user_profile/` +
                            "punit.bhidodiya@innogent.in".replace("@", "%40")
                          }
                        >
                          P
                        </Avatar>
                        <div className="overview-member">
                          <h5 style={{ margin: "0 0 0 4px " }}>
                            Punit Bhidodiya
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            ) : null}

            <Form.Item label=" " colon={false}>
              <div className="d-flex align-center justify-space-between">
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="theme-bg-gradient"
                  style={{ marginLeft: "10px" }}
                >
                  Create
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
export default NewTaskModal;
