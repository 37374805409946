import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssetCategory,
  deleteAsset,
  deleteCategory,
  getAssetCategories,
  getAssetHistory,
  getCompanyAssets,
  setSelectedAsset,
  setSelectedCategory,
} from "../../redux/slices/AssetSlice";
import {
  AppstoreOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Popover,
  Row,
  Tooltip,
} from "antd";
import moment from "moment";
import configIcon from "../../assets/images/config.png";
import detailIcon from "../../assets/images/details.png";
import AddCategoryModal from "../common/AddCategoryModal";
import AddAssetModal from "../common/AddAssetModal";
import AssignAssetModal from "../common/AssignAssetModal";
import AssetsInfoModal from "./AssetsInfoModal";
import AddAssetItem from "./AddAssetItem";
import AssetsHistoryModal from "./AssetsHistoryModal";
import { CSVLink } from "react-csv";

const AllAssets = () => {
  const dispatch = useDispatch();

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [isAddAsset, setIsAddAsset] = useState(false);
  const [isAssignAsset, setIsAssignAsset] = useState(false);
  const [isAssetsInfo, setIsAssetsInfo] = useState(false);
  const [isAddAssetItem, setIsAddAssetItem] = useState(false);
  const [isAssetOptions, setIsAssetOptions] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [assetsData, setAssetsData] = useState([]);
  const [assetItemsData, setAssetItemsData] = useState([]);
  const [editAsset, setEditAsset] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [assetToAddMore, setAssetToAddMore] = useState(null);
  const [exportedData, setExportedData] = useState([]);

  const { assetCategories, selectedCategory, assets } = useSelector(
    (state) => state.asset
  );
  const { activeCompany } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getAssetCategories());
    dispatch(getCompanyAssets());
  }, [activeCompany]);

  useEffect(() => {
    filterAssetsByCategory(selectedCategory, searchText);
  }, [assets, selectedCategory]);

  //Export Data Headers
  const headers = [
    { label: "Asset", key: "assetName" },
    { label: "Serial Id", key: "serialId" },
    { label: "Category", key: "category" },
    { label: "Type", key: "type" },
    { label: "Properties", key: "properties" },
    { label: "Vendor", key: "vendor" },
    { label: "Count", key: "count" },
    { label: "Created At", key: "createdAt" },
    { label: "Updated At", key: "updatedAt" },
    { label: "Status", key: "status" },
  ];

  //Export Data
  const createExportData = (event, done) => {
    let exportData = [];
    for (var asset of assetsData) {
      var propertiesArray = JSON.parse(asset?.properties);
      let properties = "";
      if (propertiesArray?.length > 0) {
        propertiesArray.forEach((prop) => {
          properties = properties + prop.key + " : " + prop.value + " | ";
        });
        properties = properties?.substring(0, properties.length - 2);
      }
      if (asset.recoverable) {
        for (const item of asset.assetItems) {
          let data = {
            assetName: asset?.name,
            serialId: item?.serialId ? item?.serialId : "NA",
            category: asset?.category?.name,
            type: asset?.recoverable ? "Recoverable" : "Non Recoverable",
            vendor: asset?.vendor,
            count: 1,
            createdAt: item?.createdAt
              ? moment(item.createdAt).format("MMM DD, YYYY")
              : "",
            updatedAt: item?.updatedAt
              ? moment(item.updatedAt).format("MMM DD, YYYY")
              : "",
            status: item.status,
            properties: properties,
          };
          exportData.push(data);
        }
      } else {
        let data = {
          assetName: asset?.name,
          serialId: asset?.serialId ? asset?.serialId : "NA",
          category: asset?.category?.name,
          type: asset?.recoverable ? "Recoverable" : "Non Recoverable",
          vendor: asset?.vendor,
          count: asset?.count,
          createdAt: asset?.createdAt
            ? moment(asset.createdAt).format("MMM DD, YYYY")
            : "",
          updatedAt: asset?.updatedAt
            ? moment(asset.updatedAt).format("MMM DD, YYYY")
            : "",
          status: asset.status,
          properties: properties,
        };
        exportData.push(data);
      }
    }
    setExportedData([...exportData]);
    done(true);
  };

  const selectCategory = (category) => {
    dispatch(setSelectedCategory(category));
    setCategoryVisible(false);
  };

  const filterAssetsByCategory = async (category, text) => {
    let searchResults = [];
    if (category !== null) {
      assets.forEach((asset) => {
        if (asset.category?._id === category?._id) {
          searchResults.push({ ...asset, isOpen: false });
        }
      });
    } else {
      assets.forEach((asset) => {
        searchResults.push({ ...asset, isOpen: false });
      });
    }
    if (text.trim().length > 0) {
      searchResults = searchResults.filter((asset) =>
        asset.name.toLowerCase().includes(text.trim().toLowerCase())
      );
    }
    setAssetsData([...searchResults]);
  };

  const searchAssets = (text) => {
    setSearchText(text);
    filterAssetsByCategory(selectedCategory, text);
  };

  const handleOpenChange = (open) => {
    setCategoryVisible(open);
  };

  const categoryContent = () => {
    return (
      <div className="assets-container ">
        <div className="category-list-container" style={{ width: "200px" }}>
          <div style={{ maxHeight: "168px", overflowY: "auto" }}>
            <div className="d-flex align-center">
              <p
                className="d-flex align-center justify-space-between"
                onClick={() => selectCategory(null)}
              >
                All Categories{" "}
              </p>
              {selectedCategory === null ? <CheckCircleFilled /> : null}
            </div>
            {assetCategories?.length
              ? assetCategories?.map((category) => (
                  <div className="d-flex align-center">
                    <p
                      className="d-flex align-center justify-space-between"
                      onClick={() => selectCategory(category)}
                    >
                      {category.name}{" "}
                      {category._id === selectedCategory?._id ? (
                        <CheckCircleFilled />
                      ) : null}
                    </p>

                    <Popconfirm
                      placement="topRight"
                      title={`Are you sure to delete category : ${category.name}?`}
                      okText="Yes"
                      cancelText="No"
                      overlayClassName="delete-popover"
                      onConfirm={() => {
                        dispatch(deleteCategory(category._id));
                      }}
                    >
                      <DeleteOutlined className="delete-icon" />
                    </Popconfirm>
                  </div>
                ))
              : null}
          </div>
          <div className="add-category-btn">
            <p
              onClick={() => {
                setIsAddCategory(true);
                setCategoryVisible(false);
              }}
            >
              Add Category
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleAddCategory = (categoryName) => {
    dispatch(addAssetCategory({ name: categoryName }));
  };

  const handleRowDetails = (asset) => {
    setAssetsData((prevData) => {
      return prevData.map((item) => {
        if (item._id === asset._id) {
          return { ...item, isOpen: !item.isOpen };
        } else {
          return { ...item, isOpen: false };
        }
      });
    });
  };

  const moreOptionContent = (asset) => {
    return (
      <div className="noti-actions">
        {asset?.recoverable ? (
          <div>
            <p
              onClick={() => {
                setAssetToAddMore(asset);
                setIsAssetOptions(false);
                setIsAddAssetItem(true);
              }}
            >
              <PlusCircleOutlined style={{ marginRight: "10px" }} />
              Add Asset
            </p>
            <p
              onClick={() => {
                setIsAssetOptions(false);
                dispatch(setSelectedAsset(asset));
                setAssetItemsData(asset?.assetItems);
                setIsAssetsInfo(true);
              }}
            >
              <AppstoreOutlined style={{ marginRight: "10px" }} />
              View Assets
            </p>
          </div>
        ) : (
          <div>
            <p
              onClick={() => {
                dispatch(getAssetHistory(asset._id));
                setShowHistory(true);
              }}
            >
              <HistoryOutlined style={{ marginRight: "10px" }} />
              History
            </p>

            <Popconfirm
              title={`Are you sure to delete ${asset.name} ?`}
              okText="Yes"
              cancelText="No"
              overlayClassName="delete-popover"
              onConfirm={() => dispatch(deleteAsset(asset._id))}
            >
              <p
                onClick={() => {
                  setIsAssetOptions(false);
                }}
              >
                <DeleteOutlined style={{ marginRight: "10px" }} />
                Delete
              </p>
            </Popconfirm>
          </div>
        )}
      </div>
    );
  };

  const assetOptionOpenChange = (open) => {
    setIsAssetOptions(open);
  };

  return (
    <div>
      <AddCategoryModal
        isOpen={isAddCategory}
        handleClose={() => setIsAddCategory(false)}
        handleConfirm={handleAddCategory}
      />
      <AddAssetModal
        isOpen={isAddAsset}
        editAsset={editAsset}
        handleClose={() => {
          setEditAsset(null);
          setIsAddAsset(false);
        }}
      />
      <AssignAssetModal
        isOpen={isAssignAsset}
        handleClose={() => {
          setIsAssignAsset(false);
          dispatch(setSelectedAsset(null));
        }}
      />
      <AssetsInfoModal
        isOpen={isAssetsInfo}
        assetItemsData={assetItemsData}
        setShowHistory={setShowHistory}
        close={() => {
          setIsAssetsInfo(false);
          dispatch(setSelectedAsset(null));
        }}
      />
      <AddAssetItem
        asset={assetToAddMore}
        isOpen={isAddAssetItem}
        close={() => {
          setAssetToAddMore(null);
          setIsAddAssetItem(false);
        }}
      />
      <AssetsHistoryModal
        isOpen={showHistory}
        close={() => {
          setShowHistory(false);
        }}
      />
      <div
        className="d-flex align-center justify-space-between"
        style={{ margin: "20px 0" }}
      >
        <Input
          className="search-field"
          value={searchText}
          onChange={(e) => searchAssets(e.target.value)}
          placeholder="Search"
          prefix={<SearchOutlined />}
          suffix={
            searchText ? (
              <CloseCircleOutlined
                style={{
                  color: "rgba(0,0,0,.45)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  filterAssetsByCategory(selectedCategory, "");
                  setSearchText("");
                }}
              />
            ) : null
          }
          // autoFocus
        />
        <div style={{ display: "flex" }}>
          <Popover
            trigger="click"
            open={categoryVisible}
            placement="bottomRight"
            onOpenChange={handleOpenChange}
            content={categoryContent}
          >
            <Button
              style={{
                marginRight: "20px",
                minWidth: "140px",
              }}
            >
              {selectedCategory ? selectedCategory?.name : "All Categories"}{" "}
              <CaretDownOutlined
                style={{ marginRight: "0", marginLeft: "10px" }}
              />
            </Button>
          </Popover>
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              className="theme-bg-gradient"
              disabled={assetCategories?.length > 0 ? false : true}
              onClick={() => setIsAddAsset(true)}
              style={{
                color: "#fff",
              }}
            >
              Add Assets
            </Button>
            <Button
              className="theme-bg-gradient"
              style={{
                color: "#fff",
              }}
            >
              <CSVLink
                filename={"Assets.csv"}
                data={exportedData}
                headers={headers}
                asyncOnClick={true}
                onClick={createExportData}
              >
                Download Assets
              </CSVLink>
            </Button>
          </div>
        </div>
      </div>
      <div>
        {assetsData?.length ? (
          assetsData?.map((asset, i) => (
            // <div className="asset-border">
            <div className="asset-item-container">
              <Row>
                <Col className="assets-column" span={1}>
                  {i + 1}.{" "}
                </Col>
                <Col className="assets-column" span={5}>
                  <p style={{ fontWeight: 600 }}>{asset.name} </p>
                </Col>
                <Col className="assets-column" span={5}>
                  <p>{asset?.category?.name} </p>
                </Col>
                <Col className="assets-column" span={4}>
                  <p
                    style={{ minWidth: "120px", textAlign: "center" }}
                    className={
                      asset?.recoverable
                        ? "primary-highlight"
                        : "todo-highlight"
                    }
                  >
                    {asset?.recoverable ? " Recoverable" : "Non Recoverable"}{" "}
                  </p>
                </Col>
                <Col className="assets-column" span={1}>
                  <p>{asset.count} </p>
                </Col>
                <Col span={5} offset={2}>
                  <Button
                    className="assign-highlight-btn"
                    disabled={asset.count > 0 ? false : true}
                    onClick={() => {
                      dispatch(setSelectedAsset(asset));
                      setIsAssignAsset(true);
                    }}
                  >
                    Assign
                  </Button>
                  {/* <Button
                    className="add-highlight-btn"
                    style={{ margin: "0 20px" }}
                    onClick={() => {
                      setIsAddAssetItem(true);
                    }}
                  >
                    Add
                  </Button> */}
                  <Button
                    className="add-highlight-btn"
                    style={{ margin: "0 20px" }}
                    onClick={() => {
                      setEditAsset(asset);
                      setIsAddAsset(true);
                    }}
                  >
                    Edit
                  </Button>
                </Col>
                <Col
                  className="assets-column"
                  span={1}
                  onClick={() => handleRowDetails(asset)}
                  style={{ cursor: "pointer" }}
                >
                  {asset.isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </Col>
              </Row>
              {asset.isOpen ? (
                <Row>
                  <Col span={9} offset={1}>
                    <Card className="asset-detail-card">
                      <div className="d-flex align-center justify-space-between">
                        <div className="d-flex">
                          <img
                            src={detailIcon}
                            alt=""
                            style={{ width: "35px" }}
                          />
                          <h4
                            style={{
                              color: "#0f1f77",
                              fontSize: "16px",
                              marginLeft: "14px",
                            }}
                          >
                            Details
                          </h4>
                        </div>
                        {/* {asset.recoverable ? ( */}
                        <Popover
                          overlayClassName="asset-options-popup"
                          content={() => moreOptionContent(asset)}
                          open={isAssetOptions}
                          onOpenChange={assetOptionOpenChange}
                          placement="bottom"
                        >
                          <MoreOutlined className="assets-more-icon" />
                        </Popover>
                        {/* ) : null} */}
                      </div>
                      <Row>
                        <Col span={8} offset={3}>
                          <p style={{ fontWeight: 600 }}>Vendor </p>
                        </Col>
                        <Col span={1}>
                          <span>:</span>
                        </Col>
                        <Col span={12}>
                          <p style={{ color: "#1079ff" }}>{asset.vendor}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} offset={3}>
                          <p style={{ fontWeight: 600 }}>Created By </p>
                        </Col>
                        <Col span={1}>
                          <span>:</span>
                        </Col>
                        <Col span={6}>
                          <p style={{ color: "#1079ff" }}>
                            {asset.addedBy?.name}
                          </p>
                        </Col>
                      </Row>
                      {!asset.recoverable ? (
                        <Row>
                          <Col span={8} offset={3}>
                            <p style={{ fontWeight: 600 }}>Updated At </p>
                          </Col>
                          <Col span={1}>
                            <span>:</span>
                          </Col>
                          <Col span={10}>
                            <p style={{ color: "#1079ff" }}>
                              {asset.updatedAt
                                ? moment(asset.updatedAt).format("MMMM D, YYYY")
                                : "-"}
                            </p>
                          </Col>
                        </Row>
                      ) : null}
                      <Row>
                        <Col span={8} offset={3}>
                          <p style={{ fontWeight: 600 }}>Created At </p>
                        </Col>
                        <Col span={1}>
                          <span>:</span>
                        </Col>
                        <Col span={10}>
                          <p style={{ color: "#1079ff" }}>
                            {asset.createdAt
                              ? moment(asset.createdAt).format("MMMM D, YYYY")
                              : "-"}
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12} offset={1}>
                    <Card className="asset-detail-card">
                      <div className="d-flex">
                        <img
                          src={configIcon}
                          alt=""
                          style={{ width: "35px" }}
                        />
                        <h4
                          style={{
                            color: "#0f1f77",
                            fontSize: "16px",
                            marginLeft: "10px",
                          }}
                        >
                          Properties
                        </h4>
                      </div>
                      <div>
                        {asset?.properties &&
                        JSON.parse(asset.properties).length ? (
                          JSON.parse(asset.properties).map((property) => (
                            <Row>
                              <Col span={8} offset={2}>
                                <p style={{ fontWeight: 600 }}>
                                  {property.key}
                                </p>
                              </Col>
                              <Col span={1}>
                                <span>:</span>
                              </Col>
                              <Col span={6}>
                                <p style={{ color: "#1079ff" }}>
                                  {property.value}
                                </p>
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            No properties are available.
                          </p>
                        )}
                      </div>
                    </Card>
                  </Col>
                  {/* <Col span={24}>
                    <div
                      className="view-details d-flex justify-flex-end"
                      style={{ paddingRight: "35px" }}
                    >
                      <p onClick={() => setIsAssetsInfo(true)}>
                        View assets information
                      </p>
                    </div>
                  </Col> */}
                </Row>
              ) : null}
            </div>
            // </div>
          ))
        ) : (
          <div style={{ textAlign: "center" }}>
            <p>No assets are available.</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default AllAssets;
