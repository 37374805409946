import { Button, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import AddTagModal from "../components/tags components/AddTagModal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteTag, getTags, setTags } from "../redux/slices/TagSlice";
import ConfirmModal from "../components/common/ConfirmModal";
import { getActiveProject } from "../redux/slices/ProjectSlice";

const ProjectTags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [tableData, setTableData] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { tags } = useSelector((state) => state.tag);
  const { activeProject } = useSelector((state) => state.project);

  useEffect(() => {
    if (Object.keys(activeProject).length <= 0) {
      dispatch(getActiveProject(projectId));
    }
    return () => {
      dispatch(setTags([]));
    };
  }, []);

  useEffect(() => {
    dispatch(getTags(projectId));
  }, [projectId]);

  useEffect(() => {
    let tagList = [];
    if (tags.length > 0) {
      tagList = tags.map((tag) => {
        let tagObj = {
          _id: tag._id,
          name: tag.name,
          color: tag.color,
          project: tag.project,
        };
        return tagObj;
      });
    }
    setTableData(tagList);
  }, [tags]);

  const handleDelete = (tagId) => {
    dispatch(deleteTag(tagId));
    setSelectedTag(null);
    setShowDeletePopup(false);
  };

  const columns = [
    {
      title: "Tags",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Tooltip title={record.name}>
          <p
            className="primary-highlight"
            style={{ minWidth: "110px", backgroundColor: `${record.color}` }}
          >
            {record.name?.length < 15
              ? record.name
              : `${record.name.slice(0, 15)}...`}
          </p>
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "170px",
      render: (_, record) => (
        <div className="d-flex align-center justify-space-between">
          <Button
            onClick={() => {
              setSelectedTag(record);
              setShowAdd(true);
            }}
          >
            Edit
          </Button>
          <Button
            className="delete-btn"
            onClick={() => {
              setSelectedTag(record);
              setShowDeletePopup(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <AddTagModal
        isOpen={showAdd}
        close={() => {
          setSelectedTag(null);
          setShowAdd(false);
        }}
        selectedTag={selectedTag}
        projectId={projectId}
      />
      <ConfirmModal
        title={`Are you sure you want to delete tag : ${selectedTag?.name}?`}
        isOpen={showDeletePopup}
        handleClose={() => {
          setSelectedTag(null);
          setShowDeletePopup(false);
        }}
        handleConfirm={() => handleDelete(selectedTag._id)}
      />
      <div
        className="d-flex align-center justify-space-between"
        style={{ marginTop: "10px" }}
      >
        <h1 className="theme-color-primary page-heading">
          {activeProject?.name}{" "}
        </h1>
        <div>
          <Button
            className="theme-bg-gradient"
            style={{ color: "#fff", marginRight: "20px" }}
            onClick={() => setShowAdd(true)}
          >
            Add Tag
          </Button>
          <Button
            onClick={() => {
              navigate(`/project/${projectId}/board`);
            }}
          >
            Back to Project
          </Button>
        </div>
      </div>
      <div style={{ margin: "20px 0" }}>
        <Table
          scroll={{
            y: 500,
          }}
          dataSource={tableData}
          columns={columns}
          pagination={false}
          bordered
        />
      </div>
    </div>
  );
};

export default ProjectTags;
