import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";

import PendingTask from "./pages/PendingTask";
import Main from "./components/layout/Main";
import { LoginSuccess } from "./pages/LoginSuccess";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import List from "./pages/List";
import { useDispatch, useSelector } from "react-redux";
import { LoginFail } from "./pages/LoginFail";
import Admin from "./pages/Admin";
import ForgotPassword from "./pages/ForgotPassword";
import { useEffect } from "react";
import { setSelectedCompanyToLoginUser } from "./redux/slices/LoginSlice";
import ProjectDetails from "./pages/ProjectDetails";
import TaskStatus from "./pages/TaskStatus";
import ProjectOverview from "./pages/ProjectOverview";
import ComingSoon from "./components/common/ComingSoon";
import UserProfile from "./pages/UserProfile";
import MyTasks from "./components/dashboard/MyTasks";
import ClosedTasks from "./pages/ClosedTasks";
import MyNotifications from "./pages/MyNotifications";
import SearchDetails from "./pages/SearchDetails";
import Assets from "./pages/Assets";
import UserTasks from "./components/common/UserTasks";
import ProjectTags from "./pages/ProjectTags";

function App() {
  const location = useLocation();
  const { isLogin } = useSelector((state) => state.login);

  const dispatch = useDispatch();

  useEffect(() => {
    let path = location.pathname.split("/");
    //please revert any changes you do in the below line, single quotes are necessary.
    if (path[1] === "sign-in" && path[2] && path[2].length !== 0) {
      dispatch(setSelectedCompanyToLoginUser(path[2]));
    }
  }, [location]);

  return (
    <div className="App">
      {isLogin ? (
        <>
          <Main>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="/my-tasks" element={<MyTasks />} />
              <Route path="/user-tasks" element={<UserTasks />} />
              <Route path="/insights" element={<ComingSoon />} />
              <Route path="/notifications" element={<MyNotifications />} />
              <Route path="/assets" element={<Assets />} />
              <Route path="/more" element={<ComingSoon />} />
              <Route path="/reports" element={<ComingSoon />} />
              <Route path="/profile" element={<UserProfile />} />
              {/* <Route path="/projects" element={<Projects />} /> */}
              <Route path="/project/:id" element={<ProjectDetails />}>
                <Route
                  path="/project/:id/overview"
                  exact="true"
                  element={<ProjectOverview />}
                />
                <Route
                  path="/project/:id/board"
                  exact="true"
                  element={<TaskStatus />}
                />
                <Route
                  path="/project/:id/list"
                  exact="true"
                  element={<List />}
                />
                <Route
                  path="/project/:id/views"
                  exact="true"
                  element={<List />}
                />
                <Route
                  path="/project/:id/closed"
                  exact="true"
                  element={<ClosedTasks />}
                />
              </Route>
              <Route
                path="/project/:projectId/tags"
                element={<ProjectTags />}
              />
              <Route path="/pendingTask" element={<PendingTask />} />
              <Route
                path="*"
                element={<Navigate to="/" state={{ from: location }} replace />}
              />
              <Route path="/admin" element={<Admin />} />
            </Routes>
          </Main>
        </>
      ) : (
        <Routes>
          <>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />}>
              <Route path="/sign-up/:userInviteId" element={<SignUp />} />
            </Route>
            <Route path="/forgotPassword" element={<ForgotPassword />} />

            <Route
              path="/auth/login/success/:token"
              element={<LoginSuccess />}
            />
            <Route path="/login/error/:message" element={<LoginFail />} />
            <Route
              path="*"
              element={
                <Navigate to="/sign-in" state={{ from: location }} replace />
              }
            />
          </>
        </Routes>
      )}
    </div>
  );
}

export default App;
