import {
  Button,
  Input,
  Form,
  Modal,
  Table,
  Select,
  Space,
  Avatar,
  Col,
  Card,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  removeUser,
  updateUserDepartment,
  updateUserRole,
} from "../redux/slices/UserSlice";
import { convertFromUtcToLocaleCustom } from "../utils/dateUtil";
import moment from "moment";
import AppConstants from "../utils/AppConstants";
import { deletebtn } from "../components/layout/SVG";
import ConfirmModal from "../components/common/ConfirmModal";
import { useNavigate } from "react-router-dom";

function Admin() {
  const { activeCompany, userRole, departments, userDepartments } = useSelector(
    (state) => state.user
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [data, setData] = useState([]);
  const [delUser, setDelUser] = useState({});
  const [delUserName, setDelUserName] = useState("");
  const [names, setNames] = useState([]);
  const [roleDropdown, setRoleDropdown] = useState([]);
  const [departmentDropdown, setDepartmentDropdown] = useState([]);
  const roles = [
    { text: AppConstants.OWNER, value: AppConstants.OWNER },
    { text: AppConstants.CREATOR, value: AppConstants.CREATOR },
    { text: AppConstants.USER, value: AppConstants.USER },
  ];

  const department = [
    { text: AppConstants.SALES, value: AppConstants.SALES },
    { text: AppConstants.ENGINEERING, value: AppConstants.ENGINEERING },
    { text: AppConstants.MARKETING, value: AppConstants.MARKETING },
    { text: AppConstants.ADMIN, value: AppConstants.Admin },
    { text: AppConstants.HR, value: AppConstants.HR },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    const userRole1 = localStorage.getItem("role");
    if (userRole1 !== AppConstants.OWNER) {
      navigate("/");
    }
  }, [userRole]);

  useEffect(() => {
    let departmentDropdown = [];
    departments.forEach((department) =>
      departmentDropdown.push({ label: department.name, value: department._id })
    );
    setDepartmentDropdown(departmentDropdown);
  }, [departments]);

  useEffect(() => {
    const users = [];
    let userNames = [];
    let rolesArray = [];
    activeCompany?.roles?.forEach((role) => {
      if (role.name !== AppConstants.OWNER) {
        const roleObj = {
          label: (
            <p
              className={
                role.name == "Creator"
                  ? "primary-highlight"
                  : role.name == "User"
                  ? "secondary-highlight"
                  : "tertiary-highlight"
              }
              style={{ height: "30px", padding: 0 }}
            >
              {role.name}
            </p>
          ),
          value: role._id,
        };
        rolesArray.push(roleObj);
      }
    });
    setRoleDropdown(rolesArray);
    activeCompany?.users?.forEach((user, index) => {
      let companyRole = user.role.filter(
        (role) => role.company === activeCompany._id
      );
      userNames.push({ text: user.name, value: user.name });
      let userDepartment = activeCompany.userDepartments.filter(
        (userDepartment) => userDepartment.user === user._id
      );
      let userData = {
        key: user._id,
        name: user.name,
        email: user.email,
        isUserProfileUploaded: user.isUserProfileUploaded,
        role: companyRole[0],
        department: userDepartment[0] ? userDepartment[0].department : "",
        createdAt: convertFromUtcToLocaleCustom(user.createdDate),
      };
      users.push(userData);
    });
    setNames(userNames);
    setData(users);
  }, [
    activeCompany,
    activeCompany.users,
    activeCompany.role,
    activeCompany.userDepartments,
  ]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      filters: names,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Avatar
            alt=""
            // size={25}
            className="avatar-text-img"
            src={
              record?.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` +
                  record?.email?.replace("@", "%40")
                : ""
            }
          >
            {record?.name?.slice(0, 1)}
          </Avatar>
          {record?.name}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) =>
        a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
    },
    {
      title: "Role",
      dataIndex: "role",
      filters: roles,
      render: (_, record) => (
        <Space size="middle">
          {record?.role?.name && record.role.name !== "Owner" ? (
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="Select Role"
              style={{ width: 250, border: "none ", background: "transparent" }}
              options={roleDropdown}
              onChange={(e) => {
                let update = {
                  oldRole: record.role._id,
                  newRole: e,
                };
                dispatch(
                  updateUserRole({ userId: record.key, rolePayload: update })
                );
              }}
              defaultValue={record?.role?._id}
            />
          ) : (
            <p
              className="tertiary-highlight"
              style={{ marginLeft: "11px", padding: "6px" }}
            >
              {record?.role?.name}
            </p>
          )}
        </Space>
      ),
      onFilter: (value, record) => record.role.name === value,
    },
    {
      title: "Department",
      dataIndex: "department",
      filters: department,
      render: (_, record) => (
        <Space size="middle">
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Select Department"
            style={{ width: 250, border: "none ", background: "transparent" }}
            options={departmentDropdown}
            onChange={(e) => {
              let update = {
                userId: record.key,
                departmentId: e,
              };
              dispatch(
                updateUserDepartment({
                  companyId: activeCompany._id,
                  userPayload: update,
                })
              );
            }}
            defaultValue={record?.department?._id}
          />
        </Space>
      ),
      onFilter: (value, record) => record.department.name === value,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {record?.role?.name && record.role.name !== "Owner" ? (
            // <Popconfirm
            //   title={`Are you sure to delete ${record.name}`}
            //   description={`Are you sure to delete ${record.name}`}
            //   okText="Yes"
            //   cancelText="No"
            //   onConfirm={() => {
            //     dispatch(removeUser({ userId: record.key }));
            //   }}
            // >
            // </Popconfirm>
            <p
              onClick={() => {
                setIsDeleteOpen(true);
                setDelUserName(record.name);
                setDelUser({ userId: record.key });
              }}
              style={{ cursor: "pointer" }}
            >
              {deletebtn}
            </p>
          ) : null}
        </Space>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {};

  const handleRemoveUser = () => {
    dispatch(removeUser(delUser));
  };
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    dispatch(addUser({ companyId: activeCompany._id, user: values }));
    form.resetFields();
    handleCancel();
  };

  return (
    <>
      <ConfirmModal
        title={`Are you sure you want to delete ${delUserName}?`}
        isOpen={isDeleteOpen}
        handleClose={() => setIsDeleteOpen(false)}
        handleConfirm={handleRemoveUser}
      />
      <Modal
        title="Add User"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          {...layout}
          layout="vertical"
          name="basic"
          preserve={false}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input username!",
              },
            ]}
          >
            <Input placeholder="Enter username" />
          </Form.Item>

          <Form.Item
            name={"email"}
            label="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter correct user email",
              },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              allowClear
              style={{
                width: "100%",
              }}
              options={roleDropdown}
              placeholder="Select Role"
              isValidNewOption={() => false}
            />
          </Form.Item>
          <Form.Item
            label="Department"
            name="department"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              allowClear
              style={{
                width: "100%",
              }}
              options={departmentDropdown}
              placeholder="Select Department"
              isValidNewOption={() => false}
            />
          </Form.Item>
          <Form.Item
          // wrapperCol={{
          //   offset: 8,
          //   span: 16,
          // }}
          >
            <div
              className="d-flex align-center justify-space-between"
              style={{ marginTop: "10px" }}
            >
              <Button style={{ marginRight: "20px" }} onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                className="theme-bg-gradient"
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Card className="admin-container" style={{ marginTop: "30px" }}>
        <Row>
          <Col
            xs={24}
            md={24}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h1 className="theme-color-primary page-heading">Users</h1>
            <Button
              className="theme-bg-gradient"
              key="1"
              type="primary"
              onClick={showModal}
            >
              Add User
            </Button>
          </Col>
          <Col
            className="projects-table"
            xs={24}
            md={24}
            style={{ marginTop: "20px" }}
          >
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              onChange={onChange}
              bordered
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Admin;
