import { Avatar, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppConstants from "../utils/AppConstants";
import { BellFilled, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  deleteNotification,
  getAllNotifications,
  markSeen,
} from "../redux/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import {
  getActiveProject,
  getActiveProjetAndTask,
} from "../redux/slices/ProjectSlice";
import ConfirmModal from "../components/common/ConfirmModal";

const MyNotifications = () => {
  const { allNotifications, activeCompany } = useSelector(
    (state) => state.user
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [unseen, setUnseenCount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeCompany && Object.keys(activeCompany).length !== 0) {
      dispatch(getAllNotifications(activeCompany?._id));
    }
  }, [activeCompany]);

  useEffect(() => {
    let count = allNotifications?.filter((noti) => noti?.seen === false).length;
    setUnseenCount(count);
  }, [allNotifications]);

  const handleOnClickNotification = (notification) => {
    if (!notification.seen) {
      dispatch(
        markSeen({
          companyId: activeCompany?._id,
          notificationId: {
            notificationId: notification._id,
          },
        })
      );
    }
    if (notification.action === AppConstants.DAILY_REMINDER) {
      navigate(`/dashboard`);
      return;
    } else if (
      (notification.action === AppConstants.ASSIGNED_ASSET) ||
      (notification.action === AppConstants.RECOVERED_ASSET)
    ) {
      navigate(`/profile`);
      return;
    }
    if (!notification.project) {
      return;
    }
    switch (notification.action) {
      case AppConstants.ADD_MEMBER:
      case AppConstants.PROJECT_UPDATED:
        navigate(`/project/${notification.project?._id}/overview`, {
          replace: true,
        });
        dispatch(getActiveProject(notification.project?._id));
        break;

      case AppConstants.ASSIGNED_TASK:
      case AppConstants.TASK_UPDATED:
      case AppConstants.MENTIONED_IN_COMMENT:
      case AppConstants.ACCOUNTABLE_FOR_TASK:
      case AppConstants.ADDED_COMMENT:
      case AppConstants.UPDATED_COMMENT:
      case AppConstants.REACTED_ON_COMMENT:
        navigate(`/project/${notification.project?._id}/board`, {
          replace: true,
        });
        dispatch(
          getActiveProjetAndTask({
            projectId: notification.project?._id,
            taskId: notification.task?._id,
          })
        );
        break;

      case AppConstants.TASK_CLOSED:
        navigate(`/project/${notification.project?._id}/closed`, {
          replace: true,
        });
        dispatch(
          getActiveProjetAndTask({
            projectId: notification.project?._id,
            taskId: notification.task?._id,
          })
        );
        break;

      default:
        break;
    }
  };

  const markAllRead = () => {
    if (unseen !== 0) {
      dispatch(
        markSeen({
          companyId: activeCompany?._id,
          notificationId: {
            notificationId: "all",
          },
        })
      );
    } else {
      message.info("No Unread Notifications");
    }
  };
  const markSeenNoti = (id) => {
    dispatch(
      markSeen({
        companyId: activeCompany?._id,
        notificationId: {
          notificationId: id,
        },
      })
    );
  };

  const deleteNoti = () => {
    dispatch(deleteNotification({ notificationId: deleteId }));
  };

  return (
    <div className="my-notifications-container">
      <ConfirmModal
        title={`Are you sure you want to delete notification ?`}
        isOpen={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={deleteNoti}
      />
      <div
        className="d-flex align-center justify-space-between"
        style={{ paddingTop: "20px" }}
      >
        <h1 className="theme-color-primary page-heading">Notifications</h1>
        <Button
          className="theme-bg-gradient"
          style={{ color: "#fff" }}
          onClick={() => markAllRead()}
        >
          Mark all as read
        </Button>
      </div>
      <div style={{ padding: "20px 0" }}>
        {allNotifications?.length ? (
          allNotifications?.map((noti) => (
            <div
              className={
                noti.seen
                  ? "d-flex align-center justify-space-between notification-section"
                  : "d-flex align-center justify-space-between notification-section unseen-notification-bg"
              }
            >
              <div
                className="d-flex align-center w-full"
                onClick={() => handleOnClickNotification(noti)}
              >
                {noti.action === AppConstants.DAILY_REMINDER ? (
                  <Avatar
                    size={50}
                    className="avatar-text-img"
                    style={{ marginRight: "20px" }}
                  >
                    <BellFilled />
                  </Avatar>
                ) : (
                  <Avatar
                    src={
                      noti?.actionBy?.isUserProfileUploaded
                        ? `${AppConstants.BUCKET_URL}` +
                          noti?.actionBy?.email?.replace("@", "%40")
                        : ""
                    }
                    size={50}
                    style={{ marginRight: "20px" }}
                  />
                )}
                <p>
                  {noti.action === AppConstants.DAILY_REMINDER ? (
                    <b>{noti.action}</b>
                  ) : (
                    <>
                      <b>{noti?.actionBy?.name}</b>{" "}
                      {noti?.action?.toLowerCase()}
                      {" : "}
                      <b>
                        {noti?.targetValue?.length <= 50
                          ? noti?.targetValue
                          : `${noti?.targetValue?.slice(0, 50)}...`}
                      </b>
                    </>
                  )}
                </p>
              </div>
              <div className="d-flex align-center justify-space-between my-noti-actions">
                {!noti?.seen ? (
                  <EyeOutlined onClick={() => markSeenNoti(noti?._id)} />
                ) : (
                  <div></div>
                )}
                <DeleteOutlined
                  onClick={() => {
                    setDeleteModal(true);
                    setDeleteId(noti._id);
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <div style={{ color: "#000", fontSize: "14px" }}>
            No notification available.
          </div>
        )}
      </div>
    </div>
  );
};

export default MyNotifications;
