import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import AppConstants from "../utils/AppConstants";
import { useNavigate } from "react-router-dom";
import AssignedAssets from "../components/asset components/AssignedAssets";
import AllAssets from "../components/asset components/AllAssets";
import RecoveredAssets from "../components/asset components/RecoveredAssets";

const Assets = () => {
  const navigate = useNavigate();
  const { userRole } = useSelector((state) => state.user);

  const [current, setCurrent] = useState("Available Assets");
  const items = ["Available Assets", "Assigned Assets"];

  useEffect(() => {
    const userRole1 = localStorage.getItem("role");
    if (userRole1 === AppConstants.USER) {
      navigate("/");
    }
  }, [userRole]);

  return (
    <div className="assets-container ">
      <h1 className="theme-color-primary page-heading">Assets</h1>
      <Menu mode="horizontal" selectedKeys={[current]}>
        {items.map((item) => {
          return (
            <Menu.Item key={item} onClick={() => setCurrent(item)}>
              {item}
            </Menu.Item>
          );
        })}
      </Menu>
      {current === "Available Assets" ? <AllAssets /> : <AssignedAssets />}
    </div>
  );
};

export default Assets;
