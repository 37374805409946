import { Avatar, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import AppConstants from "../../utils/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { getMyAssets } from "../../redux/slices/AssetSlice";
import moment from "moment";

const MyAssets = () => {
  const dispatch = useDispatch();
  const { loginUser } = useSelector((state) => state.login);
  const { activeCompany } = useSelector((state) => state.user);

  const { myAssets } = useSelector((state) => state.asset);

  useEffect(() => {
    if (Object.keys(loginUser).length > 0) dispatch(getMyAssets());
  }, [loginUser, activeCompany]);

  useEffect(() => {
    if (myAssets.length) {
      let data = myAssets.map((item) => ({
        serialId: item.asset?.serialId || "NA",
        assetName: item.asset?.name,
        assignedTo: item.assignedTo,
        assignedBy: item.assignedBy,
        status: item.status,
        assignedAt: item?.assignedAt
          ? moment(item.assignedAt).format("MMM DD, YYYY")
          : "",
      }));
      setTableData([...data]);
    }
  }, [myAssets]);

  const [tableData, setTableData] = useState([]);
  const columns = [
    {
      title: "Asset",
      dataIndex: "assetName",
      key: "assetName",
    },
    {
      title: "Serial Id",
      dataIndex: "serialId",
      key: "serialId",
      width: "150px",
      render: (text) => (
        <>
          {" "}
          {text?.length < 17 ? (
            <p style={{ margin: 0, color: "#000" }}>{text}</p>
          ) : (
            <Tooltip title={text}>
              <p style={{ margin: 0, color: "#000" }}>
                {`${text?.slice(0, 16)}...`}
              </p>
            </Tooltip>
          )}{" "}
        </>
      ),
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      key: "assignedBy",
      render: (assignedBy) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Avatar
            alt=""
            // icon={<UserOutlined />}
            className="avatar-text-img"
            src={
              assignedBy?.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` +
                  assignedBy?.email?.replace("@", "%40")
                : ""
            }
          >
            {assignedBy?.name?.slice(0, 1)}
          </Avatar>
          {assignedBy?.name}
        </div>
      ),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text) => (
    //     <p
    //       className={
    //         text == "Assigned" ? "primary-highlight" : "secondary-highlight"
    //       }
    //     >
    //       {text}
    //     </p>
    //   ),
    // },
    {
      title: "Assigned At",
      dataIndex: "assignedAt",
      key: "assignedAt",
    },
  ];
  return (
    <div>
      <h1 className="theme-color-primary page-heading">My Assets</h1>
      <Table
        scroll={{
          x: 500,
          y: 550,
        }}
        dataSource={tableData}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default MyAssets;
