import {
  Avatar,
  Button,
  Input,
  Menu,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ClockCircleOutlined,
  FilterOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { getClosedTasksByProject } from "../redux/slices/ProjectSlice";
import { getSelectedTask } from "../redux/slices/TaskSlice";
import AppConstants from "../utils/AppConstants";

const ClosedTasks = () => {
  const { closedTasks, activeProject } = useSelector((state) => state.project);
  const [tableDataSource, setTableDataSource] = useState([]);
  const dispatch = useDispatch();
  const [toggleFilterDropdown, setToggleFilterDropdown] = useState(false);
  const [toggleSortDropdown, setToggleSortDropdown] = useState(false);

  const [filters, setFilters] = useState({
    selection: [],
  });
  const [sort, setSort] = useState({
    order: "Asc",
    column: "",
  });
  const [data, setData] = useState([]);

  const handleSearchAndCondition = (filters, sort) => {
    let records = [];
    let newData = [...tableDataSource];
    if (filters.selection.length > 0) {
      filters.selection.forEach((filter) => {
        if (filter.filter !== "") {
          if (filter.column === "tags") {
            newData.forEach((record) => {
              let foundElement = record[filter.column].some((tag) =>
                tag.name?.toLowerCase().includes(filter.filter.toLowerCase())
              );
              if (foundElement) {
                records.push(record);
              }
            });
          } else {
            newData.forEach((record) => {
              let foundElement = record[filter.column]
                ?.toLowerCase()
                .includes(filter.filter.toLowerCase());
              if (foundElement) {
                records.push(record);
              }
            });
          }

          newData = records;
          records = [];
        }
      });
      newData.length > 0 ? setData([...newData]) : setData([...newData]);
    }
    setToggleFilterDropdown(false);
  };

  const sortFields = (sort, data) => {
    if (sort.column !== "") {
      let sortData = [...data];
      sortData?.sort((a, b) => {
        let fa = a[sort.column]?.toLowerCase(),
          fb = b[sort.column]?.toLowerCase();
        if (sort.order) {
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        } else {
          if (fb < fa) {
            return -1;
          }
          if (fb > fa) {
            return 1;
          }
          return 0;
        }
      });
      setData(sortData);
    }
    setToggleSortDropdown(false);
  };

  const handleReset = () => {
    let updateFilter = { ...filters };
    updateFilter.selection = [];

    setFilters(updateFilter);
    setData([...tableDataSource]);
    sortFields(sort, tableDataSource);
  };

  const handleFilter = (index, e) => {
    let updatedFilter = { ...filters };
    updatedFilter.selection[index].filter = e.target.value;
    setFilters(updatedFilter);
  };

  const handleColumn = (index, e) => {
    let updateFilter = { ...filters };
    updateFilter.selection[index].column = e;
    setFilters(updateFilter);
  };

  const removeField = (index) => {
    if (filters.selection.length !== 1) {
      let updateFilter = { ...filters };
      updateFilter.selection.splice(index, 1);
      setFilters(updateFilter);
    } else {
      handleReset();
    }
  };

  const removeSort = () => {
    setSort({ ...sort, order: true, column: "" });
    setData(tableDataSource);
    handleSearchAndCondition(filters, { ...sort, order: true, column: "" });
    setToggleSortDropdown(false);
  };

  const addField = () => {
    let newFilter = { ...filters };
    newFilter.selection.push({
      filter: "",
      column: "",
      type: "text",
    });
    setFilters(newFilter);
  };

  useEffect(() => {
    if (activeProject?._id) {
      dispatch(getClosedTasksByProject({ projectId: activeProject?._id }));
    }
  }, [activeProject]);

  useEffect(() => {
    let tableData = [];
    closedTasks?.forEach((data) => {
      let assignees =
        data.assignee?.length > 0
          ? data.assignee.map((assignee) => assignee.name)
          : [];
      tableData.push({
        _id: data._id,
        name: data.name,
        tags: data.tags || [],
        ticketId: data.ticketId,
        createdBy: data.createdBy?.name,
        accountableAssignee: {
          name: data.accountableAssignee?.name,
          isProfile: data.accountableAssignee?.isUserProfileUploaded,
          email: data.accountableAssignee?.email,
          src: data.accountableAssignee?.isUserProfileUploaded
            ? `${AppConstants.BUCKET_URL}` +
              data.accountableAssignee?.email?.replace("@", "%40")
            : "",
        },
        assignee: assignees.toString(),
        status: data.status,
        createdAt: moment(data.createdAt).format("MMM DD, YYYY"),
        closedAt: moment(data.closedAt).format("MMM DD, YYYY"),
      });
    });
    setTableDataSource(tableData);
    setData(tableData);
  }, [closedTasks]);

  const handleProfileContent = (user) => {
    return (
      <div>
        {user?.isUserProfileUploaded ? (
          <img
            src={
              `${AppConstants.BUCKET_URL}` + user?.email?.replace("@", "%40")
            }
            alt=""
          />
        ) : (
          <div className="d-flex align-center justify-center theme-bg-color-primary name-letter-container">
            {user.name.slice(0, 1)}
          </div>
        )}

        <div className="popup-details">
          <h5>{user?.name}</h5>
          <div className="d-flex align-center">
            <MailOutlined />
            <p>{user?.email}</p>
          </div>
          <div className="d-flex align-center">
            <ClockCircleOutlined />
            <p>{moment(new Date()).format("h:mm A")} local time</p>
          </div>
        </div>
      </div>
    );
  };

  const removeEmptyFilters = () => {
    if (filters.selection?.length > 0) {
      let selections = filters.selection?.filter(
        (selection) => selection.filter !== ""
      );
      filters.selection = selections;
      setFilters(filters);
    }
  };

  const handleShowTask = (editTask) => {
    dispatch(getSelectedTask(editTask?._id));
  };

  const handleFilterChange = (newOpen) => {
    removeEmptyFilters();
    setToggleFilterDropdown(newOpen);
  };

  const handleSortChange = (newOpen) => {
    setToggleSortDropdown(newOpen);
  };

  function findCreatedBySrcByUserId(record) {
    let src = "";
    let task = closedTasks.find((task) => task._id === record._id);
    src = task?.createdBy?.isUserProfileUploaded
      ? `${AppConstants.BUCKET_URL}` +
        task.createdBy?.email?.replace("@", "%40")
      : "";
    return src;
  }

  function findAssigneesEmail(record) {
    let assignees = [];
    closedTasks.forEach((projectTask) => {
      if (projectTask._id === record._id) {
        assignees = projectTask.assignee;
      }
    });
    return assignees;
  }

  const filterList = [
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Assignee",
      value: "assignee",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "Ticket ID",
      value: "ticketId",
    },
    {
      label: "Status",
      value: "status",
    },
    { label: "Tags", value: "tags" },
  ];

  const tagsContent = (info) => {
    return (
      <div className="d-flex alignCenter" style={{ padding: "5px" }}>
        {info?.map((tag, i) => {
          if (i > 2) {
            return (
              <Tooltip title={tag.name}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `${tag.color}`,
                    minWidth: "100px",
                  }}
                >
                  {tag.name?.length < 13
                    ? tag.name
                    : `${tag.name?.slice(0, 12)}...`}
                </p>
              </Tooltip>
            );
          }
        })}
      </div>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "280px",
      render: (text, record) => (
        <h5
          onClick={() => handleShowTask(record)}
          style={{ cursor: "pointer" }}
        >
          <Tooltip placement="bottom" title={text}>
            {text?.length <= 35 ? text : `${text?.slice(0, 35)}...`}
          </Tooltip>
        </h5>
      ),
    },
    {
      title: "Ticket ID",
      dataIndex: "ticketId",
      key: "ticketId",
      width: "120px",
      render: (text) => (
        <h5
          style={{
            margin: "0",
            fontWeight: 600,
            color: "#AA145E",
          }}
        >
          {text}
        </h5>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "250px",
      render: (info) => (
        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          {info?.length > 4 ? (
            <>
              {" "}
              {info?.map((tag, i) => {
                if (i < 3) {
                  return (
                    <Tooltip title={tag.name}>
                      <p
                        className="primary-highlight"
                        style={{
                          margin: "5px",
                          backgroundColor: `${tag.color}`,
                          minWidth: "100px",
                        }}
                      >
                        {tag.name?.length < 13
                          ? tag.name
                          : `${tag.name?.slice(0, 12)}...`}
                      </p>
                    </Tooltip>
                  );
                }
              })}
              <Popover content={tagsContent(info)}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `#d8d8d8`,
                    minWidth: "100px",
                  }}
                >
                  +{info?.length - 3}
                </p>
              </Popover>
            </>
          ) : (
            info?.map((tag) => (
              <Tooltip title={tag.name}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `${tag.color}`,
                    minWidth: "100px",
                  }}
                >
                  {tag.name?.length < 13
                    ? tag.name
                    : `${tag.name?.slice(0, 12)}...`}
                </p>
              </Tooltip>
            ))
          )}
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "200px",
      render: (info, record) => (
        <div>
          <Avatar
            className="avatar-text-img"
            style={{ marginRight: "10px" }}
            src={findCreatedBySrcByUserId(record)}
          >
            {info.slice(0, 1)}
          </Avatar>
          {info}
        </div>
      ),
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
      width: "150px",
      render: (_, record) => (
        <Avatar.Group maxCount={2}>
          {findAssigneesEmail(record).map((assignee) => {
            return (
              <Popover content={() => handleProfileContent(assignee)}>
                <Avatar
                  // size="large"
                  className="avatar-text-img"
                  src={
                    assignee?.isUserProfileUploaded
                      ? `${AppConstants.BUCKET_URL}` +
                        assignee?.email?.replace("@", "%40")
                      : ""
                  }
                >
                  {assignee?.name?.slice(0, 1)}
                </Avatar>
              </Popover>
            );
          })}
        </Avatar.Group>
      ),
    },
    // {
    //   title: "Accountable Assignee",
    //   dataIndex: "accountableAssignee",
    //   key: "accountableAssignee",
    //   width: "200px",
    //   render: (info) => (
    //     <div>
    //       {info.name ? (
    //         <Popover content={() => handleProfileContent(info)}>
    //           <Avatar className="avatar-text-img" src={info?.src}>
    //             {info?.name?.slice(0, 1)}
    //           </Avatar>
    //           &nbsp; &nbsp;{info?.name}
    //         </Popover>
    //       ) : null}
    //     </div>
    //   ),
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "120px",
      render: (text) => (
        <p
          className={
            text == AppConstants.DONE
              ? "done-highlight"
              : text == AppConstants.ON_HOLD
              ? "hold-highlight"
              : text == AppConstants.IN_PROGRESS
              ? "progress-highlight"
              : "todo-highlight"
          }
        >
          {text}
        </p>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
    },
    {
      title: "Closed At",
      dataIndex: "closedAt",
      key: "closedAt",
      width: "120px",
    },
  ];

  return (
    <div style={{ margin: "20px 0" }}>
      <div
        className="d-flex justify-space-between"
        style={{ marginBottom: "10px" }}
      >
        <div>
          <Popover
            showArrow={false}
            content={
              <div className="filter-dropdown">
                {filters.selection?.map(function (select, index) {
                  return (
                    <div className="filter-field" key={index}>
                      <div>
                        <span
                          onClick={() => {
                            removeField(index);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          X &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <span className="">Filter by&nbsp;: </span>
                      </div>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={filterList}
                        onChange={(e) => {
                          handleColumn(index, e);
                        }}
                        defaultValue={select.column ? select.column : null}
                        style={{
                          width: "150px",
                          marginLeft: "4px",
                        }}
                        placeholder={"Select..."}
                      />
                      <Input
                        key={index}
                        placeholder={`Filter...`}
                        value={select.filter}
                        onChange={(e) => {
                          handleFilter(index, e);
                        }}
                        style={{
                          width: "150px",
                          marginLeft: "4px",
                          height: "32px",
                        }}
                      />
                    </div>
                  );
                })}
                <Space
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      addField();
                    }}
                  >
                    Add Field
                  </Button>
                  <Button
                    onClick={() => {
                      handleReset();
                    }}
                    size="small"
                    style={{
                      width: 90,
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSearchAndCondition(filters, sort);
                    }}
                    icon={<FilterOutlined />}
                    size="small"
                    style={{
                      width: 90,
                    }}
                  >
                    Apply
                  </Button>
                </Space>
              </div>
            }
            trigger="click"
            open={toggleFilterDropdown}
            onOpenChange={handleFilterChange}
          >
            <Button
              style={{ margin: "0 10px" }}
              type={
                filters.selection?.length > 0 &&
                filters.selection[0].filter !== ""
                  ? "primary"
                  : "default"
              }
            >
              {filters.selection?.length > 0 &&
              filters.selection[0].filter !== ""
                ? "FILTERED"
                : "FILTER"}
            </Button>
          </Popover>

          <Popover
            showArrow={false}
            content={
              <div className="filter-dropdown">
                <div className="filter-field">
                  <div className="d-flex align-center">
                    <div className="d-flex align-center">
                      <div>
                        {/* <span
                      onClick={() => {
                        removeSort();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      X &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span> */}
                        <span className="">Sort by&nbsp;: </span>
                      </div>
                      <Switch
                        checkedChildren="Asc"
                        unCheckedChildren="Desc"
                        checked={sort.order}
                        style={{ margin: "5px" }}
                        onChange={(value) => {
                          setSort({ ...sort, order: value });
                        }}
                      />
                    </div>
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      options={filterList}
                      value={sort.column ? sort.column : null}
                      onChange={(value) => {
                        setSort({ ...sort, column: value });
                      }}
                      style={{
                        width: "150px",
                        marginLeft: "4px",
                      }}
                      placeholder={"Select..."}
                    />
                  </div>
                  <div className="d-flex justify-space-between">
                    <Button
                      onClick={() => {
                        removeSort();
                      }}
                      style={{ width: "70px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        sortFields(sort, data);
                      }}
                      size="small"
                      className="sort-button"
                      style={{ width: "50px" }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            }
            trigger="click"
            open={toggleSortDropdown}
            onOpenChange={handleSortChange}
          >
            <Button type={sort.column ? "primary" : "default"}>
              {sort.column ? "SORTED" : "SORT"}
            </Button>
          </Popover>
        </div>
      </div>
      <Table
        bordered
        scroll={{
          x: 1200,
          y: 550,
        }}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default ClosedTasks;
