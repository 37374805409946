import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginUser: {},
  isLogin: false,
  navigateFlag: false,
};

if (localStorage.getItem("jwt")) {
  initialState.isLogin = true;
}

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setNavigateFlag: (state, action) => {
      state.navigateFlag = action.payload;
    },
    takeLoginUser: (state, action) => {},
    setLoginUser: (state, action) => {
      state.loginUser = action.payload;
      if (state.loginUser !== null && state.loginUser !== {}) {
        state.loginError = {};
        state.isLogin = true;
        state.loginUser?.role?.forEach((role) => {
          if (role?.company === state.loginUser?.selectedCompany) {
            localStorage.setItem("role", role.name);
          }
        });
      } else {
        state.isLogin = false;
      }
    },
    logout: (state, action) => {
      state.isLogin = false;
      state.loginUser = {};
      localStorage.clear();
      state.navigateFlag = true;
    },
    getCurrentUser: (state, action) => {},
    getMe: () => {},

    setSelectedCompanyToLoginUser: (state, action) => {
      state.loginUser.selectedCompany = action.payload;
    },
  },
});

export const {
  setNavigateFlag,
  takeLoginUser,
  setLoginUser,
  getCurrentUser,
  logout,
  getMe,
  setSelectedCompanyToLoginUser,
} = loginSlice.actions;
export default loginSlice.reducer;
