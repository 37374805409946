import { Button, Modal } from "antd";
import React from "react";

const ConfirmModal = ({title, isOpen, handleClose, handleConfirm }) => {
  return (
    <Modal
      className="delete-modal"
      centered
      closable={false}
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      style={{ width: "fit-content" }}
      maskClosable={false}
    >
      <div  style={{ minWidth: "150px" }}>
        <p >{title}</p>
        <div className="d-flex align-center justify-space-between">
          <Button onClick={handleClose}>No</Button>
          <Button
            type="primary "
            onClick={() => {
              handleClose();
              handleConfirm();
            }}
            className="theme-bg-gradient"
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
