import { Card } from "antd";
import React from "react";

const WidgetCard = ({ title, count, countColor, className }) => {
  return (
    <div>
      <Card className={className}>
        <h6 style={{ fontSize: "16px", fontWeight: 600 }}>{title}</h6>
        <h4
          style={{
            fontSize: "32px",
            fontWeight: 700,
            textAlign: "center",
            color: countColor,
          }}
        >
          {count}
        </h4>
      </Card>
    </div>
  );
};

export default WidgetCard;
