import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tags: [],
  dropdownTags: [],
};

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    getDropdownTags: () => {},
    setDropdownTags: (state, action) => {
      state.dropdownTags = action.payload;
    },
    getTags: () => {},
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    createTag: () => {},
    updateTagListAfterCreate: (state, action) => {
      let tags = [...state.tags];
      tags.unshift(action.payload);
      state.tags = tags;
    },
    deleteTag: () => {},
    updateTagListAfterDelete: (state, action) => {
      let tags = [...state.tags];
      tags = tags.filter((tag) => tag._id !== action.payload);
      state.tags = tags;
    },
    editTag: () => {},
    updateTagListAfterEdit: (state, action) => {
      let tags = [...state.tags];
      let index = tags.findIndex((tag) => tag._id === action.payload._id);
      tags[index] = action.payload;
      state.tags = tags;
    },
  },
});

export const {
  getDropdownTags,
  setDropdownTags,
  getTags,
  setTags,
  createTag,
  updateTagListAfterCreate,
  deleteTag,
  updateTagListAfterDelete,
  editTag,
  updateTagListAfterEdit,
} = tagSlice.actions;

export default tagSlice.reducer;
