import { Avatar, Button, Col, Input, Menu, Row, Select, message } from "antd";
import React, { useState, useEffect } from "react";
import {
  AlignLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  ControlOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { BarsOutlined, TableOutlined } from "@ant-design/icons";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteProject,
  getActiveProject,
  getTaskCount,
  projectCleanup,
  setActiveProject,
  setActiveProjectTaskCount,
  setProjectTasks,
  updateProjectField,
} from "../redux/slices/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../components/common/ConfirmModal";
import { pencil } from "../components/layout/SVG";
import AppConstants from "../utils/AppConstants";
import { getTags, setTags } from "../redux/slices/TagSlice";

const ProjectDetails = () => {
  const { activeProject, projectNamesList, taskCount } = useSelector(
    (state) => state.project
  );
  const { userRole } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [current, setCurrent] = useState("Overview");
  const [isConfirm, setIsConfirm] = useState(false);
  const [projectInfo, setProjectInfo] = useState({ name: "", description: "" });
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [editName, setEditName] = useState(false);
  const path = useLocation();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (Object.keys(activeProject).length === 0) {
      dispatch(getActiveProject(id));
    }
  }, []);

  useEffect(() => {
    if (path?.pathname?.includes("board")) {
      setCurrent("Board");
    } else if (path?.pathname?.includes("list")) {
      setCurrent("List");
    } else if (path?.pathname?.includes("closed")) {
      setCurrent("Closed");
    } else if (path?.pathname?.includes("views")) {
      setCurrent("Views");
    }
  }, [activeProject]);

  useEffect(() => {
    setProjectInfo({
      name: activeProject?.name,
      description: activeProject?.description,
    });
    let members = [];
    activeProject.team?.forEach((user) => {
      let element = (
        <div>
          <Avatar
            alt=""
            size={25}
            className="avatar-text-img"
            style={{
              marginRight: "10px",
            }}
            src={
              user?.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` + user?.email.replace("@", "%40")
                : ""
            }
          >
            {user.name?.slice(0, 1)}
          </Avatar>
          {user.name}
        </div>
      );
      let teamObj = { label: element, value: user?._id, name: user?.name };
      members.push(teamObj);
    });
    setUsers(members);
    if (activeProject?._id) dispatch(getTags(activeProject?._id));

    return () => {
      setEditName(false);
    };
  }, [activeProject]);

  useEffect(() => {
    return () => {
      dispatch(projectCleanup());
      dispatch(setTags([]));
    };
  }, []);

  const items = [
    {
      title: "Overview",
      icon: <AlignLeftOutlined />,
      url: "/overview",
    },
    {
      title: "Board",
      icon: <TableOutlined />,
      url: "/board",
    },
    {
      title: "List",
      icon: <BarsOutlined />,
      url: "/list",
    },
    {
      title: "Views",
      icon: <ControlOutlined />,
      url: "/views",
    },
    {
      title: "Closed",
      icon: <FileDoneOutlined />,
      url: "/closed",
    },
  ];

  const handleonChangeNav = (item, e) => {
    if (current !== item.title) {
      setCurrent(item.title);
      dispatch(setProjectTasks([]));
      navigate(`/project/${id}${item.url}`);
    }
  };

  const handleUpdateDetails = () => {
    if (
      projectInfo.name.trim()?.length > 0 &&
      projectInfo.name.trim() !== activeProject?.name?.trim()
    ) {
      if (projectNamesList?.includes(projectInfo.name.trim().toLowerCase())) {
        message.error("Please Enter a Unique Project Name");
      } else {
        dispatch(
          updateProjectField({
            updateField: { name: projectInfo.name.trim() },
            projectId: activeProject._id,
          })
        );
      }
    } else if (!projectInfo.name.trim()?.length > 0) {
      message.error("Project Name cannot be blank");
    }
    if (projectInfo.description.trim() !== activeProject?.description?.trim()) {
      dispatch(
        updateProjectField({
          updateField: { description: projectInfo.description.trim() },
          projectId: activeProject._id,
        })
      );
    }
    // if (userList?.length > 0) {
    //   dispatch(
    //     addTeamMembers({
    //       projectId: activeProject._id,
    //       addedUsers: { ids: userList },
    //     })
    //   );
    // }
  };

  const handleUpdateName = () => {
    if (
      projectInfo.name.trim()?.length > 0 &&
      projectInfo.name.trim() !== activeProject?.name?.trim()
    ) {
      if (projectNamesList?.includes(projectInfo.name.trim().toLowerCase())) {
        message.error("Please Enter a Unique Project Name");
      } else {
        dispatch(
          updateProjectField({
            updateField: { name: projectInfo.name.trim() },
            projectId: activeProject._id,
          })
        );
      }
    } else if (!projectInfo.name.trim()?.length > 0) {
      message.error("Project Name cannot be blank");
      return;
    }
    setEditName(!editName);
  };

  const handleDeleteProject = (id) => {
    dispatch(deleteProject(id));
    dispatch(setActiveProject({}));
    navigate("/");
  };

  return (
    <>
      <ConfirmModal
        title="Are you sure you want to delete ?"
        isOpen={isDeleteOpen}
        handleClose={() => setIsDeleteOpen(false)}
        handleConfirm={() => handleDeleteProject(activeProject?._id)}
      />
      <div className="layout-content">
        <ConfirmModal
          title="Are sure you want to save ?"
          isOpen={isConfirm}
          handleClose={() => {
            setIsConfirm(false);
          }}
          handleConfirm={() => {
            setIsConfirm(false);
            handleUpdateDetails();
          }}
        />
        <Row className="project-detail-container" style={{ marginTop: "20px" }}>
          <Col
            xs={24}
            md={24}
            className="d-flex justify-space-between align-center"
          >
            <div>
              <p
                className={
                  activeProject?.type == "Regular"
                    ? "primary-highlight"
                    : "secondary-highlight"
                }
              >
                {activeProject?.type}
              </p>
              <div className="d-flex align-center">
                {editName ? (
                  <div>
                    <Input
                      style={{
                        width: 250,
                        margin: " 10px 20px",
                      }}
                      defaultValue={projectInfo.name}
                      onChange={(e) =>
                        setProjectInfo({ ...projectInfo, name: e.target.value })
                      }
                    />
                  </div>
                ) : (
                  <h1
                    className="theme-color-primary  page-heading"
                    style={{ margin: "0 20px" }}
                  >
                    {activeProject?.name}
                  </h1>
                )}
                {editName ? (
                  <div>
                    <Button onClick={handleUpdateName} type="link">
                      <CheckOutlined />
                    </Button>
                    <Button
                      onClick={() => setEditName(!editName)}
                      type="link"
                      style={{ color: "red", padding: 0 }}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                ) : (
                  <>
                    {(userRole == AppConstants.OWNER ||
                      userRole == AppConstants.CREATOR) && (
                      <Button
                        onClick={() => setEditName(!editName)}
                        type="link"
                      >
                        {pencil}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            {userRole == AppConstants.OWNER ||
            userRole == AppConstants.CREATOR ? (
              <div>
                <Button
                  className="theme-bg-gradient"
                  style={{ color: "#fff" }}
                  onClick={() => setIsDeleteOpen(true)}
                >
                  Delete
                </Button>
              </div>
            ) : null}
          </Col>
          <Col
            xs={24}
            md={24}
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
            className="tabs-container"
          >
            <Menu
              mode="horizontal"
              selectedKeys={[current]}
              style={{ width: "522px" }}
            >
              {items.map((item) => {
                return (
                  <Menu.Item
                    key={item.title}
                    icon={item.icon}
                    onClick={(e) => handleonChangeNav(item, e)}
                  >
                    {item.title}
                  </Menu.Item>
                );
              })}
            </Menu>
            {activeProject?.type === AppConstants.REGULAR ? (
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Select user"
                style={{ width: "180px" }}
                options={users}
                allowClear
                onChange={(e) => {
                  if (e !== undefined) {
                    dispatch(
                      getTaskCount({ projectId: activeProject._id, userId: e })
                    );
                  } else {
                    dispatch(setActiveProjectTaskCount(taskCount));
                  }
                }}
              />
            ) : null}
          </Col>
          <Col xs={24} md={24}>
            <Outlet />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProjectDetails;
