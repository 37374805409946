import { call, fork, put, takeLatest } from "redux-saga/effects";
import agent from "../../agent";
import {
  createTag,
  deleteTag,
  editTag,
  getDropdownTags,
  getTags,
  setDropdownTags,
  setTags,
  updateTagListAfterCreate,
  updateTagListAfterDelete,
  updateTagListAfterEdit,
} from "../slices/TagSlice";
import showMessage from "../../utils/messageUtil";
import { updateProjectAudits } from "../slices/ProjectSlice";

function* getDropdownTagsByProjectId(data) {
  const response = yield call(agent.Tag.getTagsByProject, data.payload);
  if (response.statusCode === 200) {
    yield put(setDropdownTags(response.body));
  } else showMessage(response, "", "Failed to fetch all tags: ");
}

function* getTagsByProjectId(data) {
  const response = yield call(agent.Tag.getTagsByProject, data.payload);
  if (response.statusCode === 200) {
    yield put(setTags(response.body));
  } else showMessage(response, "", "Failed to fetch all tags: ");
}

function* createTagAsync(data) {
  const response = yield call(agent.Tag.createTag, data.payload);
  if (response.statusCode === 200) {
    yield put(updateTagListAfterCreate(response.body.tag));
    yield put(updateProjectAudits(response.body.audit));
  }
  showMessage(response, response.message, "Failed to create new tag: ");
}

function* editTagAsync(data) {
  const response = yield call(
    agent.Tag.editTag,
    data.payload.id,
    data.payload.editPayload
  );
  if (response.statusCode === 200) {
    yield put(updateTagListAfterEdit(response.body.tag));
    if (response.body.audit)
      yield put(updateProjectAudits(response.body.audit));
  }
  showMessage(response, response.message, "Failed to edit tag: ");
}

function* deleteTagAsync(data) {
  const response = yield call(agent.Tag.deleteTag, data.payload);
  if (response.statusCode === 200) {
    yield put(updateTagListAfterDelete(data.payload));
    yield put(updateProjectAudits(response.body.audit));
  }
  showMessage(response, response.message, "Failed to delete tag: ");
}

function* tags() {
  yield takeLatest(getDropdownTags.type, getDropdownTagsByProjectId);
  yield takeLatest(getTags.type, getTagsByProjectId);
  yield takeLatest(createTag.type, createTagAsync);
  yield takeLatest(editTag.type, editTagAsync);
  yield takeLatest(deleteTag.type, deleteTagAsync);
}

export const tagSaga = [fork(tags)];
