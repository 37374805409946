import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isNewTaskModalOpen: false,
  isNewProjectModalOpen: false,
  globalAddTask: true,
  isNewInviteModalOpen: false,
};

const createButtonSlice = createSlice({
  name: "createButton",
  initialState,
  reducers: {
    setShowNewTaskModalOpen: (state, action) => {
      state.isNewTaskModalOpen = action.payload;
    },
    setShowNewProjectModalOpen: (state, action) => {
      state.isNewProjectModalOpen = action.payload;
    },
    setGlobalAddTask: (state, action) => {
      state.globalAddTask = action.payload;
    },
    setShowNewInviteModalOpen: (state, action) => {
      state.isNewInviteModalOpen = action.payload;
    },
  },
});

export const {
  setShowNewTaskModalOpen,
  setShowNewProjectModalOpen,
  setGlobalAddTask,
  setShowNewInviteModalOpen,
} = createButtonSlice.actions;
export default createButtonSlice.reducer;
