import React from "react";
import soon from "../../assets/images/coming-soon.png";

const ComingSoon = () => {
  return (
    <div className="d-flex align-center justify-center">
      <img src={soon} alt="Coming soon" />
    </div>
  );
};

export default ComingSoon;
