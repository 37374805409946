import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAsset, updateAsset } from "../../redux/slices/AssetSlice";

const AddAssetModal = ({ isOpen, handleClose, editAsset }) => {
  const dispatch = useDispatch();
  const { assetCategories, selectedCategory } = useSelector(
    (state) => state.asset
  );
  const [propertiesData, setPropertiesData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isRecoverable, setIsRecoverable] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [assetInfo, setAssetInfo] = useState([]);
  const [assetsDetails, setAssetsDetails] = useState({});
  const [form] = Form.useForm();
  const statusOptions = [
    {
      label: (
        <div
          style={{ height: "30px", lineHeight: "25px" }}
          className="done-highlight"
        >
          New
        </div>
      ),
      value: "New",
    },
    // {
    //   label: (
    //     <div
    //       style={{ height: "30px", lineHeight: "25px" }}
    //       className="todo-highlight"
    //     >
    //       Recovered
    //     </div>
    //   ),
    //   value: "Recovered",
    // },
    {
      label: (
        <div
          style={{ height: "30px", lineHeight: "25px" }}
          className="hold-highlight"
        >
          Damaged
        </div>
      ),
      value: "Damaged",
    },
    // {
    //   label: (
    //     <div
    //       style={{ height: "30px", lineHeight: "25px" }}
    //       className="closed-highlight"
    //     >
    //       Repaired
    //     </div>
    //   ),
    //   value: "Repaired",
    // },
  ];

  useEffect(() => {
    if (editAsset) {
      form.setFieldsValue({ ...editAsset, category: editAsset.category?._id });
      let propertyData = JSON.parse(editAsset?.properties);
      setPropertiesData([...propertyData]);
      setIsRecoverable(editAsset.recoverable);
      setIsEdit(true);
      setIsFormUpdated(false);
    } else {
      form.setFieldsValue({ category: selectedCategory?._id });
    }
  }, [editAsset, isOpen]);

  useEffect(() => {
    let options = [];
    if (assetCategories?.length) {
      assetCategories.forEach((category) => {
        options.push({ label: category.name, value: category._id });
      });
    }
    setCategoryOptions([...options]);
  }, [assetCategories]);

  const handleSubmit = (values) => {
    let error = false;
    values.name = values?.name?.trim();
    values.vendor = values?.vendor?.trim();
    if (propertiesData?.length) {
      propertiesData.forEach((property) => {
        property.key = property.key.trim();
        property.value = property.value.trim();
        if (
          !property.key ||
          property.key.trim().length <= 0 ||
          !property.value ||
          property.value.trim().length <= 0
        ) {
          error = true;
        }
      });
      values.properties = propertiesData;
    } else {
      values.properties = [];
    }
    if (error) {
      message.error("Please enter valid details");
      return;
    }
    if (isRecoverable) {
      values.groupId = editAsset?.groupId;
      values.recoverable = true;
    } else {
      values.recoverable = false;
    }
    if (isRecoverable && !(isEdit && editAsset?.recoverable)) {
      let details = [];
      for (let i = 0; i < values.count; i++) {
        details.push({ uniqueId: "", status: "New" });
      }
      setAssetInfo([...details]);
      setAssetsDetails(values);
      setIsDetails(true);
    } else {
      if (isEdit) {
        if (isFormUpdated)
          dispatch(updateAsset({ id: editAsset._id, updatePayload: values }));
        else message.info("No changes were made");
      } else {
        dispatch(addAsset(values));
      }
      close();
    }
  };
  const handleAddProperty = () => {
    if (!isFormUpdated) setIsFormUpdated(true);
    let data = propertiesData;
    data.push({ key: "", value: "" });
    setPropertiesData([...data]);
  };

  const handleRemoveProperty = (index) => {
    if (!isFormUpdated) setIsFormUpdated(true);
    let data = propertiesData;
    data.splice(index, 1);
    setPropertiesData([...data]);
  };

  const close = () => {
    handleClose();
    setPropertiesData([]);
    setAssetInfo([]);
    setIsEdit(false);
    setIsDetails(false);
    form.resetFields();
  };

  const handlePropertyChange = (e, i) => {
    if (!isFormUpdated) setIsFormUpdated(true);
    let data = propertiesData;
    if (e.target.name === "value") {
      data[i].value = e.target.value;
    } else if (e.target.name === "key") {
      data[i].key = e.target.value;
    }
    setPropertiesData([...data]);
  };

  const handleInfoChange = (e, i) => {
    let data = assetInfo;
    data[i].uniqueId = e.target.value;
    setAssetInfo([...data]);
  };

  const handleStatusChange = (e, i) => {
    let data = assetInfo;
    data[i].status = e;
    setAssetInfo([...data]);
  };

  const handleFormChange = () => {
    if (!isFormUpdated) setIsFormUpdated(true);
  };

  const handleAddAsset = () => {
    let body = assetsDetails;
    let error = false;
    if (assetInfo?.length) {
      for (let asset of assetInfo) {
        asset.uniqueId = asset.uniqueId.trim();
        asset.status = asset.status.trim();
        if (
          !asset.uniqueId ||
          asset.uniqueId.trim().length <= 0 ||
          !asset.status ||
          asset.status.trim().length <= 0
        ) {
          error = true;
        }
      }
      body.assetItems = assetInfo;
    }

    if (error) {
      message.error("Please enter valid details");
      return;
    }

    if (isEdit) {
      if (isFormUpdated)
        dispatch(updateAsset({ id: editAsset._id, updatePayload: body }));
      else message.info("No changes were made");
    } else {
      dispatch(addAsset(body));
    }
    close();
  };

  return (
    <>
      <Modal
        style={{ overflowY: "auto", height: "600px" }}
        title={`${isEdit ? "Update" : "Add"} Asset`}
        centered
        open={isOpen}
        footer={null}
        maskClosable={false}
        onCancel={close}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          onValuesChange={handleFormChange}
        >
          {!isDetails ? (
            <div>
              <Form.Item label="Name">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (value && value.trim().length > 0) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Name can not be blank");
                        }
                      },
                      message: "Name is required",
                    },
                  ]}
                  name="name"
                  noStyle
                >
                  <Input placeholder="Enter Asset Name" />
                </Form.Item>
              </Form.Item>
              <Form.Item label="Category">
                <Form.Item
                  name="category"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    options={categoryOptions}
                    placeholder="Select Category"
                    isValidNewOption={() => false}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item label="Vendor">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (value && value.trim().length > 0) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Vendor can not be blank");
                        }
                      },
                      message: "Vendor is required",
                    },
                  ]}
                  name="vendor"
                  noStyle
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    placeholder="Enter Vendor Details"
                  />
                </Form.Item>
              </Form.Item>
              <div className="d-flex">
                {!(isEdit && editAsset?.recoverable) ? (
                  <Form.Item className="form-item-horizontal" label="Count">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "required",
                        },
                        {
                          type: "number",
                          min: 0,
                          max: 50,
                          message: "Maximum count should be 50",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Value should contain just number",
                        },
                      ]}
                      className="form-item-horizontal"
                      label="Count"
                      name="count"
                      validateFirst
                      noStyle
                    >
                      <InputNumber />
                    </Form.Item>
                  </Form.Item>
                ) : null}
                {!isEdit ? (
                  <Form.Item
                    className="form-item-horizontal"
                    label="Recoverable"
                    valuePropName="checked"
                  >
                    <Form.Item name="recoverable" noStyle>
                      <Switch
                        checked={isRecoverable}
                        onChange={(e) => setIsRecoverable(e)}
                      />
                    </Form.Item>
                  </Form.Item>
                ) : null}
              </div>

              <div style={{ marginTop: "5px" }}>
                <Form.Item label="Additional Properties">
                  <div
                    style={{
                      maxHeight: "150px",
                      overflow: "auto",
                    }}
                  >
                    {propertiesData.length
                      ? propertiesData.map((property, index) => (
                          <div
                            className="d-flex align-center"
                            style={{ marginBottom: "10px" }}
                          >
                            <Input
                              placeholder="Key"
                              name="key"
                              value={property.key || ""}
                              onChange={(e) => {
                                handlePropertyChange(e, index);
                              }}
                            />{" "}
                            <span
                              style={{ padding: "0 10px", textAlign: "center" }}
                            >
                              :
                            </span>{" "}
                            <Input
                              placeholder="Value"
                              name="value"
                              value={property.value || ""}
                              onChange={(e) => {
                                handlePropertyChange(e, index);
                              }}
                            />
                            <CloseCircleOutlined
                              style={{ marginLeft: "10px", color: "red" }}
                              onClick={() => handleRemoveProperty(index)}
                            />
                          </div>
                        ))
                      : null}
                  </div>
                  <p
                    style={{
                      color: "#062c8f",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontWeight: 600,
                      width: "fit-content",
                    }}
                    onClick={handleAddProperty}
                  >
                    Add {propertiesData.length ? "More" : ""}
                  </p>
                </Form.Item>
              </div>
              <Form.Item>
                <div className="d-flex align-center justify-space-between">
                  <Button htmlType="button" onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="theme-bg-gradient"
                    style={{ marginLeft: "10px" }}
                  >
                    {isRecoverable && !(isEdit && editAsset?.recoverable)
                      ? "Next"
                      : "Submit"}
                  </Button>
                </div>
              </Form.Item>
            </div>
          ) : (
            <div>
              <div style={{ height: "380px", overflow: "auto" }}>
                <p>Please provide the details of each asset.</p>
                {assetInfo.length
                  ? assetInfo.map((info, index) => (
                      <div
                        className="d-flex align-center justify-space-between"
                        style={{ margin: "10px 0" }}
                      >
                        <div style={{ width: "48%" }}>
                          <label
                            style={{
                              fontSize: "12px",
                              color: "#6d6e6f",
                              fontWeight: 500,
                            }}
                          >
                            Serial Id
                          </label>
                          <Input
                            placeholder="Id"
                            name="uniqueId"
                            value={info.uniqueId || ""}
                            onChange={(e) => {
                              handleInfoChange(e, index);
                            }}
                            disabled={isEdit}
                          />
                        </div>
                        <div style={{ width: "48%" }}>
                          <label
                            style={{
                              fontSize: "12px",
                              color: "#6d6e6f",
                              fontWeight: 500,
                            }}
                          >
                            Status
                          </label>
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            style={{ width: "100%" }}
                            options={statusOptions}
                            value={info.status || ""}
                            placeholder="Select Status"
                            isValidNewOption={() => false}
                            onChange={(e) => handleStatusChange(e, index)}
                            disabled={isEdit}
                          />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <div
                className="d-flex align-center justify-space-between"
                style={{ paddingTop: "20px" }}
              >
                <Button htmlType="button" onClick={() => setIsDetails(false)}>
                  Back
                </Button>
                <Button
                  type="primary"
                  className="theme-bg-gradient"
                  style={{ marginLeft: "10px" }}
                  onClick={handleAddAsset}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddAssetModal;
