import { Card, Col, Row } from "antd";
import TeamMembersCard from "../components/dashboard/TeamMembersCard";
import WidgetCard from "../components/dashboard/WidgetCard";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AppConstants from "../utils/AppConstants";
import MyProjects from "../components/dashboard/MyProjects";
import { getDashboardDatasOfUser } from "../redux/slices/UserSlice";
import PendingTask from "./PendingTask";

function Home() {
  const { loginUser } = useSelector((state) => state.login);
  const { dashboardData, activeProjectsCount, totalTasksCount } = useSelector(
    (state) => state.user
  );

  const [dueTasksCount, setDueTasksCount] = useState(0);
  const [pendingTaskCount, setPendingTaskCount] = useState(0);
  const [completedTasksCount, setCompletedTasksCount] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loginUser?._id) {
      dispatch(getDashboardDatasOfUser(loginUser?._id));
    }
  }, [loginUser]);

  useEffect(() => {
    const completedTask = dashboardData?.filter(
      (data) => data.status === AppConstants.DONE || data.closed
    );
    setCompletedTasksCount(completedTask.length);

    const dueTask = dashboardData?.filter(
      (data) =>
        (data.status === AppConstants.TO_DO ||
          data.status === AppConstants.IN_PROGRESS) &&
        !data.closed
    );
    setDueTasksCount(dueTask.length);

    let count = 0;
    let currentDate = new Date();
    dashboardData?.forEach((data) => {
      const taskEndDate = data.endDate;
      const endDate = new Date(taskEndDate);
      // Check if the given date is today
      const isToday = currentDate.toDateString() === endDate.toDateString();
      if (!data.closed && isToday && data.status !== AppConstants.DONE) {
        count++;
      }
    });
    setPendingTaskCount(count);
  }, [dashboardData]);

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <div
            className="d-flex flex-col justify-center align-center"
            style={{
              width: "100%",
              /* paddingBottom: "30px", */ marginTop: "15px",
            }}
          >
            <h4 style={{ fontWeight: 500, fontSize: "16px", margin: 0 }}>
              {moment(new Date()).format("dddd, Do MMMM")}
            </h4>
            <h1 style={{ fontWeight: 400, fontSize: "32px" }}>
              Hello, {loginUser?.name?.split(" ")[0]}
            </h1>
          </div>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
            <WidgetCard
              title="Active Projects"
              count={activeProjectsCount}
              countColor="#4aaa8e"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
            <WidgetCard
              title="Total Tasks"
              count={totalTasksCount}
              countColor="#ff9800"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
            <WidgetCard
              title="Active Tasks"
              count={dueTasksCount}
              countColor="#f44336"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
            <WidgetCard
              title="Completed Tasks"
              count={completedTasksCount}
              countColor="#03a9f4"
            />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          {pendingTaskCount > 0 ? (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <Card className="criclebox h-full">
                <PendingTask />
              </Card>
            </Col>
          ) : null}
          <Col xs={24} sm={24} md={12} lg={12} xl={15} className="mb-24">
            <Card className="criclebox h-full">
              <MyProjects />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={9} className="mb-24">
            <Card className="criclebox">
              <TeamMembersCard />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
