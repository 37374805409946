import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal, message } from "antd";
import { Colorpicker } from "antd-colorpicker";
import { createTag, editTag } from "../../redux/slices/TagSlice";

const AddTagModal = ({ isOpen, close, selectedTag, projectId }) => {
  const [tag, setTag] = useState({
    name: "",
    color: "#eee",
    project: projectId,
  });
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTag) {
      setTag({
        name: selectedTag.name,
        color: selectedTag.color,
        project: selectedTag.project,
      });
    } else {
      setTag({
        name: "",
        color: "#eee",
        project: projectId,
      });
    }
  }, [selectedTag]);

  const handleSubmit = () => {
    if (!tag.name || tag.name.trim().length <= 0) {
      setTag({
        ...tag,
        name: selectedTag ? selectedTag.name : "",
        project: projectId,
      });
      setError(true);
      return;
    }
    if (selectedTag) {
      if (
        selectedTag.name.toLowerCase() === tag.name.trim().toLowerCase() &&
        selectedTag.color === tag.color
      ) {
        message.warn("Nothing is updated");
      } else {
        dispatch(
          editTag({
            id: selectedTag._id,
            editPayload: { ...tag, name: tag.name.trim() },
          })
        );
      }
    } else {
      dispatch(createTag({ ...tag, name: tag.name.trim() }));
    }

    handleClose();
  };

  const handleClose = () => {
    setTag({ name: "", color: "#eee", project: projectId });
    close();
  };

  const handleChangeName = (e) => {
    if (e.target.value) {
      setTag({ ...tag, name: e.target.value });
      setError(false);
    } else {
      setTag({ ...tag, name: e.target.value });
      setError(true);
    }
  };

  const handleChangeColor = (e) => {
    setTag({ ...tag, color: e.hex });
  };

  return (
    <Modal
      title="Add Tag"
      width={320}
      centered
      closable={true}
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      className="add-tags-modal"
    >
      <div>
        <div className="d-flex align-center justify-space-between">
          <div style={{ width: "200px", marginRight: "20px" }}>
            <label
              style={{
                fontSize: "12px",
                color: "#6d6e6f",
                fontWeight: 500,
              }}
            >
              Tag Name
            </label>
            <Input
              placeholder="Tag Name"
              value={tag.name}
              onChange={handleChangeName}
            />
            <div
              style={{
                display: error ? "block" : "none",
                color: "red",
                fontSize: "10px",
              }}
            >
              Tag name should not be empty*
            </div>
          </div>
          <div className="d-flex flex-col">
            <label
              style={{
                fontSize: "12px",
                color: "#6d6e6f",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Tag Color
            </label>
            <Colorpicker value={tag.color} onChange={handleChangeColor} popup />
          </div>
        </div>
      </div>
      <div
        className="d-flex align-center justify-space-between"
        style={{ paddingTop: "20px" }}
      >
        <Button htmlType="button" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          className="theme-bg-gradient"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default AddTagModal;
