import agent from "../../agent";
import { takeLatest, put, call, fork } from "redux-saga/effects";
import {
  addNewProjectToProjectList,
  addTeamMembers,
  deleteProject,
  getActiveProject,
  getActiveProjetAndTask,
  getClosedTasksByProject,
  getProjectAudits,
  getProjectForInvite,
  getProjectNamesList,
  getProjects,
  getTaskCount,
  getTasksByProject,
  removeTeamMember,
  setActiveProject,
  setActiveProjectTaskCount,
  setClosedTasks,
  setProjectAudits,
  setProjectForInvite,
  setProjectNamesList,
  setProjects,
  setProjectTasks,
  setTaskCount,
  takeProject,
  updateActiveProject,
  updateProjectAudits,
  updateProjectField,
  updateProjectListAfterDelete,
} from "../slices/ProjectSlice";
import showMessage from "../../utils/messageUtil";
import { setNavigateFlag } from "../slices/LoginSlice";
import { getSelectedTask } from "../slices/TaskSlice";

function* getAllProjectsByCompanyId(data) {
  const response = yield call(
    agent.Project.getProjectsByCompanyId,
    data.payload
  );
  yield put(setProjects(response.body));
}

function* getAllProjectsForInvite(data) {
  const response = yield call(
    agent.Project.getProjectsByCompanyId,
    data.payload
  );
  yield put(setProjectForInvite(response.body));
}

function* getTasksByProjectId(data) {
  try {
    const response = yield call(
      agent.Project.getTasksByProject,
      data.payload.projectId,
      data.payload.view
    );
    if (response?.statusCode === 200) {
      yield put(setProjectTasks(response.body));
    }
  } catch (e) {
    console.log(e);
  }
}

function* createNewProject(data) {
  try {
    const response = yield call(agent.Project.createProject, data.payload);
    showMessage(response, "New Project Created", "Project creation failed: ");
    if (response?.statusCode === 200 || response?.statusCode === 201) {
      yield put(addNewProjectToProjectList(response.body));
    }
  } catch (e) {
    showMessage(e, "", "Project creation failed: ");
    console.log(e);
  }
}

function* getProject(data) {
  try {
    const response = yield call(agent.Project.getProjectById, data.payload);
    if (response?.statusCode === 200 || response?.statusCode === 304) {
      yield put(setActiveProject(response.body.project));
      yield put(setProjectAudits(response.body.audits));
      yield put(setTaskCount(response.body.tasksPayload));
      yield put(setActiveProjectTaskCount(response.body.tasksPayload));
    } else {
      yield put(setNavigateFlag(true));
      yield put(updateProjectListAfterDelete(data.payload));
      yield put(setActiveProject({}));
      yield put(setProjectAudits({}));
      yield put(setTaskCount({}));

      showMessage(response, "", "");
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateProjectFieldById(data) {
  try {
    const response = yield call(
      agent.Project.updateProject,
      data.payload.projectId,
      data.payload.updateField
    );
    if (data.payload.updateField.name) {
      yield call(getAllProjectNames);
    }
    showMessage(
      response,
      "Project Updated Successfully",
      "Update Project Failed: "
    );
    yield put(updateProjectAudits(response.body.audit));
    yield put(updateActiveProject(response.body.updatedProject));
  } catch (e) {
    console.log(e);
  }
}

function* addTeamMembersToProject(data) {
  try {
    const response = yield call(
      agent.Project.addTeamMemberByProjectId,
      data.payload.projectId,
      data.payload.addedUsers
    );
    showMessage(response, "Member added Successfully", "Add Member Failed: ");
    yield put(updateProjectAudits(response.body.audit));
    yield put(updateActiveProject(response.body.updatedProject));
  } catch (e) {
    console.log(e);
  }
}

function* removeTeamMemberFromProject(data) {
  try {
    const response = yield call(
      agent.Project.removeTeamMemberByProjectId,
      data.payload.projectId,
      data.payload.removedUser
    );
    showMessage(
      response,
      "Member Removed Successfully",
      "Remove Member Failed: "
    );
    yield put(updateProjectAudits(response.body.audit));
    yield put(updateActiveProject(response.body.updatedProject));
  } catch (e) {
    console.log(e);
  }
}

function* getAllProjectNames() {
  try {
    const response = yield call(
      agent.Project.getAllProjectNamesOfActiveCompany
    );
    yield put(setProjectNamesList(response.body));
  } catch (e) {
    console.log(e);
  }
}

function* deleteProjectById(data) {
  try {
    const response = yield call(agent.Project.delete, data.payload);
    showMessage(
      response,
      "Project Deleted Successfully",
      "Delete Project Failed: "
    );
    yield put(updateProjectListAfterDelete(data.payload));
    yield call(getAllProjectNames);
  } catch (e) {
    console.log(e);
  }
}

function* getClosedTasks(data) {
  try {
    const response = yield call(
      agent.Project.getClosedTasksByProjectId,
      data.payload.projectId
    );
    if (response?.statusCode === 200) {
      yield put(setClosedTasks(response.body));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getAudits(data) {
  let response = yield call(agent.Project.getProjectAudits, data.payload);
  yield put(setProjectAudits(response.body));
}

function* getActiveProjectAndTask(data) {
  try {
    const response = yield call(
      agent.Project.getProjectById,
      data.payload.projectId
    );
    if (response?.statusCode === 200 || response?.statusCode === 304) {
      yield put(setActiveProject(response.body.project));
      yield put(setProjectAudits(response.body.audits));
      yield put(getSelectedTask(data.payload.taskId));
    } else {
      yield put(setNavigateFlag(true));
      yield put(updateProjectListAfterDelete(data.payload.projectId));
      yield put(setActiveProject({}));
      yield put(setProjectAudits({}));
      showMessage(response, "", "");
    }
  } catch (e) {
    console.log(e);
  }
}

function* getTasksCount(data) {
  const response = yield call(
    agent.Project.getTaskCount,
    data.payload.projectId,
    data.payload.userId
  );
  yield put(setActiveProjectTaskCount(response.body));
}

function* projects() {
  yield takeLatest(getProjects.type, getAllProjectsByCompanyId);
  yield takeLatest(getTasksByProject.type, getTasksByProjectId);
  yield takeLatest(takeProject.type, createNewProject);
  yield takeLatest(getActiveProject.type, getProject);
  yield takeLatest(getProjectForInvite.type, getAllProjectsForInvite);
  yield takeLatest(getProjectNamesList.type, getAllProjectNames);
  yield takeLatest(updateProjectField.type, updateProjectFieldById);
  yield takeLatest(addTeamMembers.type, addTeamMembersToProject);
  yield takeLatest(removeTeamMember.type, removeTeamMemberFromProject);
  yield takeLatest(deleteProject.type, deleteProjectById);
  yield takeLatest(getClosedTasksByProject.type, getClosedTasks);
  yield takeLatest(getProjectAudits.type, getAudits);
  yield takeLatest(getActiveProjetAndTask.type, getActiveProjectAndTask);
  yield takeLatest(getTaskCount.type, getTasksCount);
}

export const projectSaga = [fork(projects)];
