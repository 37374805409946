import { Avatar, Menu, Popover, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDatasOfUser } from "../../redux/slices/UserSlice";
import {
  ClockCircleOutlined,
  DoubleLeftOutlined,
  MailOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import AppConstants from "../../utils/AppConstants";
import { Link } from "react-router-dom";
import { getActiveProjetAndTask } from "../../redux/slices/ProjectSlice";

const MyTasks = () => {
  const { loginUser } = useSelector((state) => state.login);
  const { dashboardData } = useSelector((state) => state.user);

  const [current, setCurrent] = useState("To Do");
  const [tableDataSource, setTableDataSource] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loginUser?._id) {
      dispatch(getDashboardDatasOfUser(loginUser?._id));
    }
  }, [loginUser]);

  useEffect(() => {
    let tableData = [];
    dashboardData?.forEach((data) => {
      if (
        current === "To Do" &&
        data?.status === AppConstants.TO_DO &&
        !data.closed
      ) {
        tableData.push({
          key: data._id,
          name: data.name,
          tags: data.tags || [],
          ticketId: data.ticketId,
          projectName: data.project,
          createdBy: {
            name: data.createdBy?.name,
            isProfile: data.createdBy?.isUserProfileUploaded,
            email: data.createdBy?.email,
            src:
              `${AppConstants.BUCKET_URL}` +
              data.createdBy?.email?.replace("@", "%40"),
          },
          accountableAssignee: {
            name: data.accountableAssignee?.name,
            isProfile: data.accountableAssignee?.isUserProfileUploaded,
            email: data.accountableAssignee?.email,
            src:
              `${AppConstants.BUCKET_URL}` +
              data.accountableAssignee?.email?.replace("@", "%40"),
          },
          status: data.status,
          priority: data.priority,
          createdAt: moment(data.createdAt).format("MMM DD, YYYY"),
          endDate: data.endDate
            ? moment(data.endDate).format("MMM DD, YYYY")
            : "NA",
        });
      }
      if (
        current === "In Progress" &&
        data?.status === AppConstants.IN_PROGRESS &&
        !data.closed
      ) {
        tableData.push({
          key: data._id,
          name: data.name,
          tags: data.tags || [],
          ticketId: data.ticketId,
          projectName: data.project,
          createdBy: {
            name: data.createdBy?.name,
            isProfile: data.createdBy?.isUserProfileUploaded,
            email: data.createdBy?.email,
            src:
              `${AppConstants.BUCKET_URL}` +
              data.createdBy?.email?.replace("@", "%40"),
          },
          accountableAssignee: {
            name: data.accountableAssignee?.name,
            isProfile: data.accountableAssignee?.isUserProfileUploaded,
            email: data.accountableAssignee?.email,
            src:
              `${AppConstants.BUCKET_URL}` +
              data.accountableAssignee?.email?.replace("@", "%40"),
          },
          status: data.status,
          priority: data.priority,
          createdAt: moment(data.createdAt).format("MMM DD, YYYY"),
          endDate: data.endDate
            ? moment(data.endDate).format("MMM DD, YYYY")
            : "NA",
        });
      }
      if (
        current === "Done" &&
        data?.status === AppConstants.DONE &&
        !data.closed
      ) {
        tableData.push({
          key: data._id,
          name: data.name,
          tags: data.tags || [],
          ticketId: data.ticketId,
          projectName: data.project,
          createdBy: {
            name: data.createdBy?.name,
            isProfile: data.createdBy?.isUserProfileUploaded,
            email: data.createdBy?.email,
            src:
              `${AppConstants.BUCKET_URL}` +
              data.createdBy?.email?.replace("@", "%40"),
          },
          accountableAssignee: {
            name: data.accountableAssignee?.name,
            isProfile: data.accountableAssignee?.isUserProfileUploaded,
            email: data.accountableAssignee?.email,
            src:
              `${AppConstants.BUCKET_URL}` +
              data.accountableAssignee?.email?.replace("@", "%40"),
          },
          status: data.status,
          priority: data.priority,
          createdAt: moment(data.createdAt).format("MMM DD, YYYY"),
          endDate: data.endDate
            ? moment(data.endDate).format("MMM DD, YYYY")
            : "NA",
        });
      }
      if (
        current === "On Hold" &&
        data.status === AppConstants.ON_HOLD &&
        !data.closed
      ) {
        tableData.push({
          key: data._id,
          name: data.name,
          tags: data.tags || [],
          ticketId: data.ticketId,
          projectName: data.project,
          createdBy: {
            name: data.createdBy?.name,
            isProfile: data.createdBy?.isUserProfileUploaded,
            email: data.createdBy?.email,
            src:
              `${AppConstants.BUCKET_URL}` +
              data.createdBy?.email?.replace("@", "%40"),
          },
          accountableAssignee: {
            name: data.accountableAssignee?.name,
            isProfile: data.accountableAssignee?.isUserProfileUploaded,
            email: data.accountableAssignee?.email,
            src:
              `${AppConstants.BUCKET_URL}` +
              data.accountableAssignee?.email?.replace("@", "%40"),
          },
          status: data.status,
          priority: data.priority,
          createdAt: moment(data.createdAt).format("MMM DD, YYYY"),
          endDate: data.endDate
            ? moment(data.endDate).format("MMM DD, YYYY")
            : "NA",
        });
      }
    });
    setTableDataSource(tableData);
  }, [dashboardData, current]);

  const handleProfileContent = (user) => {
    return (
      <div>
        {user?.isProfile ? (
          <img src={user.src} alt="" />
        ) : (
          <div className="d-flex align-center justify-center theme-bg-color-primary name-letter-container">
            {user.name.slice(0, 1)}
          </div>
        )}

        <div className="popup-details">
          <h5>{user?.name}</h5>
          <div className="d-flex align-center">
            <MailOutlined />
            <p>{user?.email}</p>
          </div>
          <div className="d-flex align-center">
            <ClockCircleOutlined />
            <p>{moment(new Date()).format("h:mm A")} local time</p>
          </div>
          {/* <div className="d-flex flex-col align-center">
            <Button className="theme-bg-color-primary" type="primary">View Profile</Button>
            <Button>Send Message</Button>
            
          </div> */}
        </div>
      </div>
    );
  };

  const handleOnTaskClick = (task) => {
    dispatch(
      getActiveProjetAndTask({
        projectId: task.projectName._id,
        taskId: task.key,
      })
    );
  };

  function findAccountableAssigneeByEmail(record) {
    let email = "";
    dashboardData.forEach((projectTask) => {
      if (record?.status && projectTask?.key === record?.status) {
        projectTask.children.forEach((child) => {
          if (child._id === record.key) {
            email = child.accountableAssignee?.email
              ? child.accountableAssignee?.email
              : "";
          }
        });
      }
    });
    return email;
  }

  const tagsContent = (info) => {
    return (
      <div className="d-flex alignCenter" style={{ padding: "5px" }}>
        {info?.map((tag, i) => {
          if (i > 2) {
            return (
              <Tooltip title={tag.name}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `${tag.color}`,
                    minWidth: "100px",
                  }}
                >
                  {tag.name?.length < 13
                    ? tag.name
                    : `${tag.name?.slice(0, 12)}...`}
                </p>
              </Tooltip>
            );
          }
        })}
      </div>
    );
  };
  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      width: "280px",
      render: (text, record) => (
        <h5>
          <Link
            to={`/project/${record.projectName?._id}/board`}
            style={{ fontWeight: 600, color: "#000" }}
            onClick={(e) => handleOnTaskClick(record)}
          >
            <td> {text.length <= 35 ? text : `${text.slice(0, 35)}...`}</td>
          </Link>
        </h5>
      ),
    },
    {
      title: "Ticket ID",
      dataIndex: "ticketId",
      key: "ticketId",
      width: "100px",
      render: (text) => (
        <h5
          style={{
            margin: "0",
            fontWeight: 600,
            color: "#AA145E",
          }}
        >
          {text}
        </h5>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "250px",
      render: (info) => (
        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          {info?.length > 4 ? (
            <>
              {" "}
              {info?.map((tag, i) => {
                if (i < 3) {
                  return (
                    <Tooltip title={tag.name}>
                      <p
                        className="primary-highlight"
                        style={{
                          margin: "5px",
                          backgroundColor: `${tag.color}`,
                          minWidth: "100px",
                        }}
                      >
                        {tag.name?.length < 13
                          ? tag.name
                          : `${tag.name?.slice(0, 12)}...`}
                      </p>
                    </Tooltip>
                  );
                }
              })}
              <Popover content={tagsContent(info)}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `#d8d8d8`,
                    minWidth: "100px",
                  }}
                >
                  +{info?.length - 3}
                </p>
              </Popover>
            </>
          ) : (
            info?.map((tag) => (
              <Tooltip title={tag.name}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `${tag.color}`,
                    minWidth: "100px",
                  }}
                >
                  {tag.name?.length < 13
                    ? tag.name
                    : `${tag.name?.slice(0, 12)}...`}
                </p>
              </Tooltip>
            ))
          )}
        </div>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: "100px",
      render: (text) =>
        text ? (
          <div>
            <Tooltip title={`${text} Priority`}>
              {text === "High" ? (
                <DoubleLeftOutlined className="priority-high" />
              ) : text === "Medium" ? (
                <PauseOutlined className="priority-medium" />
              ) : text === "Low" ? (
                <DoubleLeftOutlined className="priority-low" />
              ) : null}
            </Tooltip>
          </div>
        ) : null,
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      width: "280px",
      render: (text) => (
        <h5>
          <Tooltip placement="bottom" title={text.name}>
            {text.name.length <= 35
              ? text.name
              : `${text.name.slice(0, 35)}...`}
          </Tooltip>
        </h5>
      ),
    },
    // {
    //   title: "Accountable Assignee",
    //   dataIndex: "accountableAssignee",
    //   key: "accountableAssignee",
    //   width: "200px",
    //   render: (info) => (
    //     <div>
    //       {info.name ? (
    //         <Popover content={() => handleProfileContent(info)}>
    //           <Avatar className="avatar-text-img" src={info.isProfile? info?.src : ""}>
    //             {info?.name?.slice(0, 1)}
    //           </Avatar>
    //           &nbsp; &nbsp;{info?.name}
    //         </Popover>
    //       ) : null}
    //     </div>
    //   ),
    // },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "200px",
      render: (info) => (
        <div>
          <Popover content={() => handleProfileContent(info)}>
            <Avatar
              className="avatar-text-img"
              src={info.isProfile ? info.src : ""}
            >
              {info?.name?.slice(0, 1)}
            </Avatar>
            &nbsp; &nbsp;{info?.name}
          </Popover>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "100px",
      render: (text) => (
        <p
          className={
            text == AppConstants.DONE
              ? "done-highlight"
              : text == AppConstants.ON_HOLD
              ? "hold-highlight"
              : text == AppConstants.IN_PROGRESS
              ? "progress-highlight"
              : "todo-highlight"
          }
        >
          {text}
        </p>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "120px",
    },
  ];

  const items = ["To Do", "In Progress", "Done", "On Hold"];
  return (
    <div>
      <h1 className="theme-color-primary page-heading">My Tasks</h1>
      <Menu
        mode="horizontal"
        selectedKeys={[current]}
        style={{ marginBottom: "20px" }}
      >
        {items.map((item) => {
          return (
            <Menu.Item
              key={item}
              onClick={() => {
                if (current !== item) {
                  setTableDataSource([]);
                  setCurrent(item);
                }
              }}
            >
              {item}
            </Menu.Item>
          );
        })}
      </Menu>
      <Table
        scroll={{
          x: 500,
          y: 550,
        }}
        dataSource={tableDataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default MyTasks;
