import { Avatar, Button, Card, Col, Input, Popover, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { pencil } from "../components/layout/SVG";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DoubleRightOutlined,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  removeTeamMember,
  updateProjectField,
} from "../redux/slices/ProjectSlice";
import { setShowAddMemberModalOpen } from "../redux/slices/TaskSlice";
import ConfirmModal from "../components/common/ConfirmModal";
import moment from "moment";
import AppConstants from "../utils/AppConstants";
import WidgetCard from "../components/dashboard/WidgetCard";

const ProjectOverview = () => {
  const { activeProject, projectAudits, activeProjectTaskCount } = useSelector(
    (state) => state.project
  );
  const { userRole } = useSelector((state) => state.user);

  const [isRemove, setIsRemove] = useState(false);
  const refOne = useRef(null);
  const dispatch = useDispatch();
  const [isDescription, setIsDescription] = useState(false);
  const [description, setDescription] = useState("");
  const [removeUser, setRemoveUser] = useState({ name: "", id: "" });
  const [auditData, setAuditData] = useState([]);

  useEffect(() => {
    if (projectAudits?.length) {
      let val = [...projectAudits].reverse();
      setAuditData(val);
    }
  }, [projectAudits]);

  useEffect(() => {
    setDescription(activeProject?.description);
  }, [activeProject]);

  const handleUpdateDescription = () => {
    if (description.trim() !== activeProject?.description?.trim()) {
      dispatch(
        updateProjectField({
          updateField: { description: description.trim() },
          projectId: activeProject._id,
        })
      );
    }
    setIsDescription(!isDescription);
  };

  const handleRemoveMember = () => {
    dispatch(
      removeTeamMember({
        projectId: activeProject._id,
        removedUser: { id: removeUser.id },
      })
    );
  };

  const handleProfileContent = (user) => {
    return (
      <div>
        {user?.isUserProfileUploaded ? (
          <img
            src={
              `${AppConstants.BUCKET_URL}` + user?.email?.replace("@", "%40")
            }
            alt=""
          />
        ) : (
          <div className="d-flex align-center justify-center theme-bg-color-primary name-letter-container">
            {user.name.slice(0, 1)}
          </div>
        )}
        <div className="popup-details">
          <h5>{user?.name}</h5>
          <div className="d-flex align-center">
            <MailOutlined />
            <p>{user?.email}</p>
          </div>
          <div className="d-flex align-center">
            <ClockCircleOutlined />
            <p>{moment(new Date()).format("h:mm A")} local time</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="overview-container">
      {activeProject?.type === AppConstants.REGULAR ? (
        <div style={{ margin: "20px 0" }}>
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <WidgetCard
                title="Total Tasks"
                count={activeProjectTaskCount.totalTasks}
                countColor="#03a9f4"
                className="project-task-cards"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <WidgetCard
                title="Active Tasks"
                count={
                  activeProjectTaskCount?.inProgressTasks +
                  activeProjectTaskCount?.toDoTasks
                }
                countColor="#ff9800"
                className="project-task-cards"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <WidgetCard
                title="Completed Tasks"
                count={activeProjectTaskCount?.doneTasks}
                countColor="#4aaa8e"
                className="project-task-cards"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <WidgetCard
                title="Closed Tasks"
                count={activeProjectTaskCount?.closedTasks}
                countColor="#f44336"
                className="project-task-cards"
              />
            </Col>
          </Row>
        </div>
      ) : null}
      <ConfirmModal
        title={`Are you sure you want to remove ${removeUser.name} ?`}
        isOpen={isRemove}
        handleClose={() => setIsRemove(false)}
        handleConfirm={handleRemoveMember}
      />
      <Card style={{ margin: "20px 0" }}>
        <div className="d-flex align-center justify-space-between">
          <h5 style={{ fontSize: "20px", fonstWeight: "500 !important" }}>
            Team Members
          </h5>
        </div>
        <Row>
          {(userRole == AppConstants.OWNER ||
            userRole == AppConstants.CREATOR) && (
            <Col
              xs={12}
              md={12}
              lg={5}
              xl={6}
              xxl={4}
              className="d-flex align-center"
            >
              <div
                onClick={() => dispatch(setShowAddMemberModalOpen(true))}
                className="d-flex align-center m-10 member-container"
              >
                <Avatar icon={<PlusOutlined />} />
                <div>
                  <h5
                    style={{
                      margin: "0 5px",
                      color: "#6d6e6f",
                      fontSize: "14px",
                    }}
                  >
                    Add Member
                  </h5>
                </div>
              </div>
            </Col>
          )}
          {activeProject?.team?.map((user) => (
            <Col xs={12} md={12} lg={6} xl={6} xxl={4}>
              <div className="d-flex align-center justify-space-between member-container">
                <div className="d-flex align-center">
                  <Popover content={() => handleProfileContent(user)}>
                    <Avatar
                      size="large"
                      className="avatar-text-img"
                      src={
                        user?.isUserProfileUploaded
                          ? `${AppConstants.BUCKET_URL}` +
                            user?.email?.replace("@", "%40")
                          : ""
                      }
                    >
                      {user.name?.slice(0, 1)}
                    </Avatar>
                  </Popover>
                  <div className="overview-member">
                    <h5 style={{ margin: "0 0 0 4px " }}>{user.name}</h5>
                    <p style={{ margin: "0 0 0 4px " }}>{user.email}</p>
                  </div>
                </div>
                {userRole !== AppConstants.USER ? (
                  <div className="overview-del-btn">
                    <CloseCircleOutlined
                      onClick={() => {
                        setRemoveUser({ name: user.name, id: user._id });
                        setIsRemove(true);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </Col>
          ))}
        </Row>
      </Card>
      <div className="d-flex justify-space-between">
        <Card style={{ width: "45%" }}>
          <div className="d-flex align-center justify-space-between">
            <h5 style={{ fontSize: "20px", fonstWeight: "500 !important" }}>
              Project description
            </h5>
            {isDescription ? (
              <div>
                <Button onClick={handleUpdateDescription} type="link">
                  <CheckOutlined />
                </Button>
                <Button
                  onClick={() => setIsDescription(!isDescription)}
                  type="link"
                  style={{ color: "red", padding: 0 }}
                >
                  <CloseOutlined />
                </Button>
              </div>
            ) : (
              <>
                {(userRole == AppConstants.OWNER ||
                  userRole == AppConstants.CREATOR) && (
                  <Button
                    onClick={() => setIsDescription(!isDescription)}
                    type="link"
                  >
                    {pencil}
                  </Button>
                )}
              </>
            )}
          </div>
          {isDescription ? (
            <div ref={refOne}>
              <Input.TextArea
                style={{
                  marginBottom: 10,
                }}
                defaultValue={description}
                // onPressEnter={(e) => handleOnEnterPressProjectDescription(e)}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          ) : (
            <p
              style={{ minHeight: "150px", color: "#000" }}
              className="text-dark"
            >
              {activeProject?.description}
            </p>
          )}
        </Card>
        <Card className="project-activity">
          <h5>Activities</h5>
          <Card
            className="project-activity-info"
            style={{ boxShadow: "none", height: "400px" }}
          >
            <div className="audit-body">
              {auditData?.map((audit) => {
                return (
                  <div className="comment-box d-flex flex-col">
                    <div
                      key={audit._id}
                      className="d-flex align-center justify-space-between"
                      style={{ width: "100%" }}
                    >
                      <div className="d-flex ">
                        <p className="comment-name">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                              paddingRight: "10px",
                            }}
                          >
                            <Avatar
                              size={25}
                              className="avatar-text-img"
                              src={
                                audit?.updatedBy?.isUserProfileUploaded
                                  ? `${AppConstants.BUCKET_URL}` +
                                    audit?.updatedBy?.email?.replace("@", "%40")
                                  : ""
                              }
                            >
                              {audit?.updatedBy?.name?.slice(0, 1)}
                            </Avatar>
                            {audit?.updatedBy?.name}
                          </div>
                        </p>
                        <p>
                          {audit?.operation} the{}&nbsp;
                        </p>
                        <p
                          style={{
                            fontWeight: 700,
                            textTransform: "capitalize",
                          }}
                        >
                          {audit.fieldName}.&nbsp;
                        </p>
                      </div>
                      <p
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          marginRight: "10px",
                        }}
                      >
                        {moment(audit?.updatedAt)?.format(
                          "h:mm a, Do MMMM, YYYY"
                        )}
                      </p>
                    </div>
                    {audit?.fieldName == "name" ||
                    audit?.fieldName == "description" ? (
                      <div className="d-flex" style={{ paddingLeft: "30px" }}>
                        <p
                          className="audit-box-p"
                          style={{
                            backgroundColor: "#f3ffea",
                            padding: "5px 10px",
                            borderRadius: "8px",
                          }}
                        >
                          {audit?.newValue}
                        </p>
                      </div>
                    ) : audit?.fieldName === "member" ? (
                      <div
                        className="d-flex align-center"
                        style={{
                          paddingLeft: "35px",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          className="d-flex"
                          style={
                            audit.operation === "removed"
                              ? {
                                  color: "#c92f54",
                                }
                              : { color: "#0d7f56" }
                          }
                        >
                          {JSON.parse(audit?.newValue)?.map((value, i) => (
                            <p style={{ margin: 0 }}>
                              {JSON.parse(audit?.newValue).length == i + 1
                                ? value.name
                                : `${value.name}, `}
                              &nbsp;
                            </p>
                          ))}
                        </div>
                      </div>
                    ) : audit?.fieldName == "tag" ? (
                      audit.operation == "updated" ? (
                        <div
                          className="d-flex align-center"
                          style={{ margin: "0 10px 20px 35px " }}
                        >
                          <p style={{ margin: 0, color: "#c92f54" }}>
                            {audit?.oldValue ? audit?.oldValue : "None"}
                          </p>{" "}
                          <DoubleRightOutlined style={{ margin: "0 20px" }} />
                          <p style={{ margin: 0, color: "#0d7f56" }}>
                            {audit?.newValue ? audit?.newValue : "None"}
                          </p>
                        </div>
                      ) : (
                        <div
                          className="d-flex align-center"
                          style={{
                            paddingLeft: "35px",
                            marginBottom: "20px",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={
                              audit.operation === "deleted"
                                ? {
                                    color: "#c92f54",
                                  }
                                : { color: "#0d7f56" }
                            }
                          >
                            <p style={{ margin: 0 }}>{audit?.newValue}</p>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })}
            </div>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default ProjectOverview;
