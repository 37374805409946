import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AppConstants from "../utils/AppConstants";
import {
  getTasksByProject,
  getActiveProject,
  setView,
  updateProjectTasks,
} from "../redux/slices/ProjectSlice";
import { useParams } from "react-router-dom";
import { Avatar, Button, Card, Col, Popover, Row, Tooltip } from "antd";
import {
  ClockCircleOutlined,
  DeleteOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MailOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import {
  setGlobalAddTask,
  setShowNewTaskModalOpen,
} from "../redux/slices/CreateButtonSlice";
import {
  deleteTask,
  setDefaultAddTaskPayload,
  getSelectedTask,
  updateTaskFields,
} from "../redux/slices/TaskSlice";
import { setProjectTasks } from "../redux/slices/ProjectSlice";
import { plusIcon } from "../components/layout/SVG";
import ConfirmModal from "../components/common/ConfirmModal";
import moment from "moment";

const TaskStatus = () => {
  const { projectTasks, activeProject } = useSelector((state) => state.project);
  const { loginUser } = useSelector((state) => state.login);
  const { showTaskDrawer } = useSelector((state) => state.task);

  const [stateData, updateStateData] = useState(projectTasks);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [delTask, setDelTask] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(setView("kanban"));
  }, []);

  useEffect(() => {
    if (Object.keys(activeProject).length !== 0) {
      dispatch(getTasksByProject({ projectId: id, view: "kanban" }));
    }
  }, [activeProject]);

  useEffect(() => {
    updateStateData(projectTasks);
  }, [projectTasks]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    if (result.destination.droppableId === result.source.droppableId) return;

    // remove from source array and put in destination array
    let newStateData = { ...stateData };

    let destinationArray = Array.from(
      stateData[result.destination.droppableId]
    );
    let sourceArray = Array.from(stateData[result.source.droppableId]);

    if (result.draggableId !== "Source") {
      let itemRemoved = sourceArray[result.source.index];
      let item = { ...itemRemoved };
      item.status = result.destination.droppableId;
      sourceArray.splice(result.source.index, 1);
      destinationArray.splice(result.destination.index, 0, item);

      newStateData[result.source.droppableId] = sourceArray;
      newStateData[result.destination.droppableId] = destinationArray;
      dispatch(
        updateTaskFields({
          taskId: itemRemoved._id,
          filter: { status: result.destination.droppableId },
        })
      );
    }
    updateStateData(newStateData);
    dispatch(updateProjectTasks(newStateData));
  }

  const showAddTaskModal = (status) => {
    dispatch(
      setDefaultAddTaskPayload({
        status: status,
        project: id,
      })
    );
    dispatch(setGlobalAddTask(false));
    dispatch(setShowNewTaskModalOpen(true));
  };

  const handleDeleteTask = (task) => {
    let projectTasks1 = { ...stateData };
    dispatch(deleteTask(delTask?._id));
    let tasks = projectTasks1[delTask?.status];
    tasks = tasks.filter((task1) => task1._id !== delTask?._id);
    projectTasks1[delTask?.status] = tasks;
    dispatch(setProjectTasks(projectTasks1));
    updateStateData(projectTasks1);
  };

  const handleOnClickCard = (e, task) => {
    dispatch(getSelectedTask(task?._id));
  };

  const handleProfileContent = (user) => {
    return (
      <div>
        {user?.isUserProfileUploaded ? (
          <img
            src={
              `${AppConstants.BUCKET_URL}` + user?.email?.replace("@", "%40")
            }
            alt=""
          />
        ) : (
          <div className="d-flex align-center justify-center theme-bg-color-primary name-letter-container">
            {user.name.slice(0, 1)}
          </div>
        )}
        <div className="popup-details">
          <h5>{user?.name}</h5>
          <div className="d-flex align-center">
            <MailOutlined />
            <p>{user?.email}</p>
          </div>
          <div className="d-flex align-center">
            <ClockCircleOutlined />
            <p>{moment(new Date()).format("h:mm A")} local time</p>
          </div>
          {/* <div className="d-flex flex-col align-center">
            <Button className="theme-bg-color-primary" type="primary">
              View Profile
            </Button>
            <Button>Send Message</Button>
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <div>
      <ConfirmModal
        title="Are you sure you want to delete ?"
        isOpen={isDeleteOpen}
        handleClose={() => setIsDeleteOpen(false)}
        handleConfirm={handleDeleteTask}
      />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Row
          className="task-status"
          style={{
            margin: "30px 0",
            gap: 12,
          }}
        >
          {activeProject?.statusList?.length > 0
            ? activeProject.statusList.map((status, i) => {
                return (
                  <Droppable key={status} droppableId={status} index={i}>
                    {(provided) => (
                      <Col xs={24} md={12} lg={6} sm={24}>
                        <Card
                          className="dropped-container"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="d-flex justify-space-between align-center">
                            <h3 style={{ fontSize: "16px", fontWeight: 600 }}>
                              {status}
                            </h3>
                            {activeProject?.type === AppConstants.ADMIN &&
                            status !== AppConstants.TO_DO ? null : (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => showAddTaskModal(status)}
                              >
                                {plusIcon}
                              </div>
                            )}
                            {/* <Button onClick={() => showAddTaskModal(status)}>
                            Add Task
                          </Button> */}
                          </div>

                          {projectTasks[status]?.map((task, i1) => {
                            return (
                              <Draggable
                                key={task._id}
                                draggableId={task._id}
                                index={i1}
                                isDragDisabled={
                                  activeProject?.type === AppConstants.ADMIN &&
                                  !task.assignee
                                    .map((user) => user._id)
                                    .includes(loginUser?._id)
                                }
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Card
                                        className="board-view-task"
                                        style={
                                          task?.endDate
                                            ? {
                                                border: "1px solid #eeeeee",
                                                margin: "10px 0",
                                                padding: "10px",
                                              }
                                            : {
                                                border: "1px solid #eeeeee",
                                                margin: "10px 0",
                                                padding: "10px",
                                                backgroundColor: "#e6e6e6",
                                                boxShadow: "none",
                                              }
                                        }
                                        onClick={(e) =>
                                          handleOnClickCard(e, task)
                                        }
                                      >
                                        <div>
                                          <div
                                            className="d-flex justify-space-between align-baseline"
                                            style={{ width: "100%" }}
                                          >
                                            <div className="d-flex align-center">
                                              <Tooltip
                                                title={`${task?.priority} Priority`}
                                              >
                                                {task?.priority === "High" ? (
                                                  <DoubleLeftOutlined className="priority-high" />
                                                ) : task?.priority ===
                                                  "Medium" ? (
                                                  <PauseOutlined className="priority-medium" />
                                                ) : task?.priority === "Low" ? (
                                                  <DoubleLeftOutlined className="priority-low" />
                                                ) : null}
                                              </Tooltip>
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "700",
                                                  margin: 0,
                                                }}
                                              >
                                                {task.ticketId}
                                              </p>
                                            </div>
                                            {activeProject?.type ===
                                              AppConstants.ADMIN &&
                                            loginUser?._id !==
                                              task?.createdBy?._id ? null : (
                                              <Button
                                                type="link"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setIsDeleteOpen(true);
                                                  setDelTask(task);
                                                }}
                                                style={{
                                                  color: "#848680",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            )}
                                          </div>
                                          <p>{task.name}</p>
                                          <div
                                            className="d-flex justify-space-between   align-center"
                                            style={{ marginTop: "6px" }}
                                          >
                                            <div className="d-flex align-center">
                                              <Avatar.Group maxCount={2}>
                                                {task?.assignee?.map(
                                                  (assignee) => (
                                                    <Popover
                                                      content={() =>
                                                        handleProfileContent(
                                                          assignee
                                                        )
                                                      }
                                                    >
                                                      <Avatar
                                                        className="avatar-text-img"
                                                        src={
                                                          assignee?.isUserProfileUploaded
                                                            ? `${AppConstants.BUCKET_URL}` +
                                                              assignee?.email?.replace(
                                                                "@",
                                                                "%40"
                                                              )
                                                            : ""
                                                        }
                                                      >
                                                        {assignee?.name?.slice(
                                                          0,
                                                          1
                                                        )}
                                                      </Avatar>
                                                    </Popover>
                                                  )
                                                )}
                                              </Avatar.Group>
                                            </div>
                                            <div>
                                              <div
                                                className={
                                                  status == AppConstants.TO_DO
                                                    ? "todo-highlight"
                                                    : status ==
                                                      AppConstants.IN_PROGRESS
                                                    ? "progress-highlight"
                                                    : status ==
                                                      AppConstants.DONE
                                                    ? "done-highlight"
                                                    : "hold-highlight"
                                                }
                                                style={{ minWidth: "70px" }}
                                              >
                                                {status}
                                              </div>
                                              <h5>
                                                {task?.endDate
                                                  ? moment(
                                                      task?.endDate
                                                    ).format("MMMM D, YYYY")
                                                  : ""}
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Card>
                      </Col>
                    )}
                  </Droppable>
                );
              })
            : null}
        </Row>
      </DragDropContext>
    </div>
  );
};
export default TaskStatus;
