import {
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Avatar,
  Popconfirm,
  Button,
  Tooltip,
  Menu,
  TimePicker,
  message,
  Popover,
  Space,
  Divider,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteComment,
  setIsEditComment,
  setSelectedTask,
  setShowTaskDrawer,
  updateTaskFields,
  closeTask,
  updateAccountableAssignee,
  addReaction,
  removeReaction,
  taskCleanup,
} from "../../redux/slices/TaskSlice";
import { getTasksByProject } from "../../redux/slices/ProjectSlice";
import moment from "moment";

import {
  ArrowRightOutlined,
  CheckOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  PauseOutlined,
  LikeOutlined,
  LikeFilled,
} from "@ant-design/icons";
import { deletebtn, pencil } from "./SVG";
import QuillEditor from "./QuillEditor";
import AppConstants from "../../utils/AppConstants";
import ConfirmModal from "../common/ConfirmModal";
import ClapIcon from "../../assets/images/clapping.png";
import AstonishedIcon from "../../assets/images/astonished.png";
import LikeIcon from "../../assets/images/thumb-up.png";
import AddReactionIcon from "../../assets/images/add-reaction.png";
import { Link } from "react-router-dom";

const dateFormat = "YYYY-MM-DD";

function TaskDrawer({ placement }) {
  const { comments, taskAudits } = useSelector((state) => state.task);
  const { showTaskDrawer, selectedTask } = useSelector((state) => state.task);
  const { activeProject, view } = useSelector((state) => state.project);
  const { loginUser } = useSelector((state) => state.login);
  const { allUsers } = useSelector((state) => state.user);
  const { tags } = useSelector((state) => state.tag);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isTaskUpdated, setIsTaskUpdated] = useState(false);
  const [assigneeIdsList, setAssigneeIdsList] = useState([]);
  const [assigneeDropdown, setAssigneeDropdown] = useState([]);
  const [defaultAssigneeDropdown, setDefaultAssigneeDropdown] = useState([]);
  const [accountableAssigneeDropdown, setAccountableAssigneeDropdown] =
    useState(defaultAssigneeDropdown);
  const [accountableAssignee, setAccountableAssignee] = useState(null);
  const [defaultAccountableAssignee, setDefaultAccountableAssignee] =
    useState(null);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [editComment, setEditComment] = useState({});
  const [closeModal, setCloseMadal] = useState(false);
  const [current, setCurrent] = useState("Comments");
  const [auditData, setAuditData] = useState([]);
  const [endTime, setEndTime] = useState("");
  const [endDateSelected, setEndDateSelected] = useState(false);
  const [tagDropdown, setTagDropdown] = useState([]);
  const [defaultTags, setDefaultTags] = useState([]);

  const priority = [
    {
      label: (
        <div className="high-highlight">
          <DoubleLeftOutlined className="priority-high" /> High
        </div>
      ),
      value: "High",
    },
    {
      label: (
        <div className="medium-highlight">
          <PauseOutlined className="priority-medium" />
          Medium
        </div>
      ),
      value: "Medium",
    },
    {
      label: (
        <div className="low-highlight">
          <DoubleLeftOutlined className="priority-low" /> Low
        </div>
      ),
      value: "Low",
    },
  ];
  const [priorityDropdown, setPriorityDropown] = useState(priority);

    useEffect(() => {
      var element = document.getElementById("comment-body");
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, []);

  useEffect(() => {
    if (taskAudits?.length) {
      let val = [...taskAudits].reverse();
      setAuditData(val);
    }
  }, [taskAudits]);

  useEffect(() => {
    createTagDropdown();
  }, [tags]);

  const createTagDropdown = () => {
    let tagList = [];
    if (tags.length > 0) {
      tagList = tags.map((tag) => {
        let tagObj = {
          label: (
            <Tooltip title={tag.name}>
              <p
                className="primary-highlight"
                style={{ minWidth: "110px", backgroundColor: `${tag.color}` }}
              >
                {tag.name?.length < 15
                  ? tag.name
                  : `${tag.name.slice(0, 15)}...`}
              </p>
            </Tooltip>
          ),
          value: tag._id,
        };
        return tagObj;
      });
    }
    setTagDropdown(tagList);
    handleCreateDefaultTags();
  };

  useEffect(() => {
    if (endTime) {
      setEndDate(new Date());
    } else {
      setEndDate("");
    }
  }, [endTime]);

  useEffect(() => {
    if (showTaskDrawer) {
      setVisible(true);
      setStartDate(selectedTask?.startDate);
      if (selectedTask?.endDate) {
        setEndDate(selectedTask?.endDate);
        setEndDateSelected(true);
      }
      setAssigneeIdsList(selectedTask?.assignee?.map((user) => user._id));
    }
  }, [showTaskDrawer, selectedTask]);

  useEffect(() => {
    let statusArray = [];
    activeProject?.statusList?.forEach((status) => {
      let statusDropdownVariable = {
        label: (
          <div
            className={
              status == AppConstants.TO_DO
                ? "todo-highlight"
                : status == AppConstants.IN_PROGRESS
                ? "progress-highlight"
                : status == AppConstants.DONE
                ? "done-highlight"
                : "hold-highlight"
            }
          >
            {status}
          </div>
        ),
        value: status,
      };
      statusArray.push(statusDropdownVariable);
    });
    setStatusDropdown(statusArray);
  }, [activeProject?.statusList]);

  useEffect(() => {
    let teamArray = [];
    activeProject?.team?.forEach((user, i) => {
      let element = (
        <div>
          <Avatar
            alt=""
            size={25}
            className="avatar-text-img"
            style={{
              marginRight: "10px",
            }}
            src={
              user?.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` + user?.email.replace("@", "%40")
                : ""
            }
          >
            {user.name?.slice(0, 1)}
          </Avatar>
          {user.name}
        </div>
      );
      let teamObj = { label: element, value: user?._id, name: user?.name };
      teamArray.push(teamObj);
    });
    setAssigneeDropdown(teamArray);
    handleCreateDefaultAssigneeDropdown(teamArray);
  }, [activeProject?.team]);

  const handleCreateDefaultTags = () => {
    let tagArray = [];
    if (selectedTask.tags.length > 0) {
      selectedTask?.tags?.forEach((tag) => {
        const data = {
          label: (
            <Tooltip title={tag?.name}>
              <p
                className="primary-highlight"
                style={{ minWidth: "110px", backgroundColor: `${tag?.color}` }}
              >
                {tag?.name?.length < 15
                  ? tag?.name
                  : `${tag?.name.slice(0, 15)}...`}
              </p>
            </Tooltip>
          ),
          value: tag?._id,
        };
        tagArray.push(data);
      });
    }

    setDefaultTags(tagArray);
  };

  const handleCreateDefaultAssigneeDropdown = (team) => {
    let teamArray = [];
    selectedTask?.assignee?.forEach((a) => {
      const data = team.find(({ value }) => value === a._id);
      if (data) {
        teamArray.push(data);
      }
    });
    setDefaultAssigneeDropdown(teamArray);
    setAccountableAssigneeDropdown(teamArray);
    if (selectedTask.accountableAssignee) {
      setAccountableAssignee(selectedTask.accountableAssignee?._id);
      let element = (
        <div>
          <Avatar
            alt=""
            size={25}
            className="avatar-text-img"
            style={{
              marginRight: "10px",
            }}
            src={
              selectedTask.accountableAssignee?.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` +
                  selectedTask.accountableAssignee?.email.replace("@", "%40")
                : ""
            }
          >
            {selectedTask.accountableAssignee.name?.slice(0, 1)}
          </Avatar>
          {selectedTask.accountableAssignee.name}
        </div>
      );
      let userObj = {
        label: element,
        value: selectedTask.accountableAssignee?._id,
        name: selectedTask.accountableAssignee?.name,
      };
      setDefaultAccountableAssignee(userObj);
    }
  };

  const hideDrawer = () => {
    setVisible(false);
    dispatch(setShowTaskDrawer(false));
    dispatch(setIsEditComment(false));
    setEndDateSelected(false);
    if (isTaskUpdated) {
      dispatch(
        getTasksByProject({ projectId: selectedTask?.project, view: view })
      );
      setIsTaskUpdated(false);
    }
    dispatch(taskCleanup());
  };

  const handleOnEnterPressTaskName = (e) => {
    if (e?.keyCode === 13) {
      e.preventDefault();
    }
    if (e?.target?.value?.trim() !== selectedTask?.name) {
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { name: e.target.value },
        })
      );
      // message.success("Task name updated successfully");
      setIsTaskUpdated(true);
    }
  };

  const handleOnEnterPressTaskDescription = (e) => {
    if (e?.keyCode === 13) {
      e.preventDefault();
    }
    if (e?.target?.value?.trim() !== selectedTask?.description?.trim()) {
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { description: e.target.value },
        })
      );
      // message.success("Task description updated successfully");
      setIsTaskUpdated(true);
    }
  };

  const handleOnChangeStartDate = (e) => {
    if (e?._d) {
      setStartDate(e._d);
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { startDate: e._d },
        })
      );
      // message.success("Task start date updated successfully");
      setIsTaskUpdated(true);
    }

    if (!e && e?._d === undefined) {
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { startDate: null },
        })
      );
      setStartDate("");
      // message.success("Task start date updated successfully");
      setIsTaskUpdated(true);
    }
  };

  const handleOnChangeEndDate = (e) => {
    if (e?._d) {
      setEndDate(e._d);
      setEndDateSelected(true);
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { endDate: e._d },
        })
      );
      // message.success("Task end date updated successfully");
      setIsTaskUpdated(true);
    }

    if (!e && e?._d === undefined) {
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { endDate: null },
        })
      );
      setEndDate("");
      setEndDateSelected(false);
      // message.success("Task end date updated successfully");
      setIsTaskUpdated(true);
    }
  };

  const handleOnChangeStatus = (e) => {
    if (e?.trim() !== selectedTask?.status?.trim()) {
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { status: e },
        })
      );
      // message.success("Task status updated successfully");
      setIsTaskUpdated(true);
    }
  };

  const handleOnChangePriority = (e) => {
    if (e?.trim() !== selectedTask?.priority?.trim()) {
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { priority: e },
        })
      );
      // message.success("Task status updated successfully");
      setIsTaskUpdated(true);
    }
  };

  const handleOnChangeTags = (e) => {
    if (e) {
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { tags: e },
        })
      );
    }
    setIsTaskUpdated(true);
  };

  const handleOnChangeAssignee = (e) => {
    if (e) {
      dispatch(
        updateTaskFields({
          taskId: selectedTask?._id,
          filter: { assignee: e },
        })
      );
      manageAccountableAssignee(e);
      setIsTaskUpdated(true);
    }
  };

  const manageAccountableAssignee = (e) => {
    if (e.length > 1) {
      let team = [...assigneeDropdown];
      let assigneeDropdownVariable = team?.filter((user) =>
        e?.includes(user?.value)
      );
      setAccountableAssigneeDropdown(assigneeDropdownVariable);
      if (
        accountableAssignee === null ||
        (accountableAssignee && !e.includes(accountableAssignee.toString()))
      ) {
        form.setFieldsValue({ accountableAssignee: e[0] });
        setAccountableAssignee(e[0]);
        dispatch(
          updateAccountableAssignee({
            taskId: selectedTask?._id,
            filter: { accountableAssignee: e[0] },
          })
        );
      }
    } else {
      setAccountableAssigneeDropdown([]);
      if (accountableAssignee) {
        dispatch(
          updateAccountableAssignee({
            taskId: selectedTask?._id,
            filter: { accountableAssignee: null },
          })
        );
      }
      form.setFieldsValue({ accountableAssignee: null });
      setAccountableAssignee(null);
    }
  };

  const handleOnChangeAccountableAssignee = (e) => {
    if (e) {
      setAccountableAssignee(e);
      form.setFieldsValue({ accountableAssignee: e });
      dispatch(
        updateAccountableAssignee({
          taskId: selectedTask?._id,
          filter: { accountableAssignee: e },
        })
      );
      setIsTaskUpdated(true);
    } else {
      message.error(
        "A Task with multiple assignees must have an accountable assignee"
      );
    }
  };

  const handleCloseTask = () => {
    dispatch(
      closeTask({
        taskId: selectedTask?._id,
        closed: { closed: true },
      })
    );
    hideDrawer();
  };

  const handleChangeEditEndTime = (time, timeString) => {
    setEndTime(timeString);
    dispatch(
      updateTaskFields({
        taskId: selectedTask?._id,
        filter: { endTime: timeString },
      })
    );
    setIsTaskUpdated(true);
  };

  const items = ["Comments", "Activity"];

  const commentReactions = [
    {
      name: "Like",
      icon: (
        <img src={LikeIcon} alt="" style={{ width: "20px", height: "24px" }} />
      ),
    },
    {
      name: "Dislike",
      icon: (
        <img
          src={LikeIcon}
          alt=""
          style={{ width: "20px", height: "24px", rotate: "180deg" }}
        />
      ),
    },
    {
      name: "Clap",
      icon: (
        <img src={ClapIcon} alt="" style={{ width: "20px", height: "24px" }} />
      ),
    },
    {
      name: "Astonished",
      icon: (
        <img
          src={AstonishedIcon}
          alt=""
          style={{ width: "24px", height: "24px" }}
        />
      ),
    },
  ];
  const reactionContent = (comment) => {
    return (
      <div
        className="comment-reaction-popover d-flex align-center"
        style={{ padding: "5px 10px", gap: 5 }}
      >
        {commentReactions?.map((reaction) => (
          <p
            style={{ margin: 0, fontSize: "16px" }}
            onClick={(e) => handleAddReaction(comment, reaction.name)}
          >
            {reaction.icon}
          </p>
        ))}
      </div>
    );
  };

  const handleAddReaction = (comment, reactionName) => {
    dispatch(
      addReaction({ commentId: comment._id, reactionName: reactionName })
    );
  };

  const handleCurrentUserReaction = (comment) => {
    let reacted = comment.commentReactions?.find(
      (reaction) => reaction.userReacted === true
    );
    if (reacted) {
      let userReaction = commentReactions?.find(
        (cr) => cr.name === reacted?._id
      );
      return userReaction.icon;
    } else return null;
  };

  const removeCurrentUserReaction = (comment) => {
    comment.commentReactions.forEach((reaction) => {
      if (reaction.userReacted) {
        let userReaction = reaction.userReactions.find(
          (userReaction) => userReaction.userId === loginUser._id
        );
        if (userReaction) dispatch(removeReaction(userReaction._id));
      }
    });
  };

  const handleReactionIcon = (commentReaction) => {
    if (commentReaction.userReactions.length > 0) {
      let reaction = commentReactions.find(
        (reaction) => reaction.name === commentReaction._id
      );
      return reaction?.icon;
    }
    return null;
  };

  const reactionUsers = (commentReaction) => {
    let userIds = commentReaction.userReactions?.map(
      (userReaction) => userReaction.userId
    );
    const namesForIds = userIds.map((id) => {
      const user = allUsers.find((user) => user._id === id);
      return user ? user.name : "Unknown"; // Replace 'Unknown' with a default value if needed
    });

    return namesForIds;
  };

  return (
    <div>
      <ConfirmModal
        title={`Are you sure you want to close task ?`}
        isOpen={closeModal}
        handleClose={() => setCloseMadal(false)}
        handleConfirm={handleCloseTask}
      />
      <Drawer
        className={
          current === "Comments"
            ? "comment-section settings-drawer"
            : "activity-section settings-drawer"
        }
        mask={true}
        width="45%"
        style={{ height: " calc(100% - 150px) !important" }}
        onClose={hideDrawer}
        placement={placement}
        open={visible}
        closable={false}
      >
        <div className="task-drawer-container">
          <div
            className="header-top  d-flex align-center justify-space-between"
            style={{ margin: "20px" }}
          >
            {!selectedTask?.closed &&
            loginUser?._id === selectedTask?.createdBy?._id ? (
              <Tooltip title="Mark this task as close">
                <Button onClick={() => setCloseMadal(true)}>
                  {" "}
                  <CheckOutlined style={{ margin: 0 }} />
                  Mark Close
                </Button>
              </Tooltip>
            ) : selectedTask?.closed ? (
              <div
                className="hold-bg-highlight"
                style={{ textAlign: "center" }}
              >
                Closed
              </div>
            ) : (
              <div> </div>
            )}
            <Button type="link" onClick={hideDrawer}>
              <ArrowRightOutlined />
            </Button>
          </div>
          <div className="sidebar-color">
            <div className="ant-thank">
              <Form
                form={form}
                name="complex-form"
                disabled={
                  selectedTask?.closed ||
                  (activeProject?.type === AppConstants.ADMIN &&
                    loginUser?._id !== selectedTask?.createdBy?._id &&
                    !assigneeIdsList.includes(loginUser?._id.toString()))
                }
                preserve={false}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                style={{
                  margin: "25px",
                }}
              >
                {/* <Form.Item label="Name"> */}
                {/* <Space> */}
                <div className="task-header-section">
                  <div>
                    <h5
                      style={{
                        width: "fit-content",
                        margin: "0 4px",
                        fontWeight: 700,
                        color: "#AA145E",
                      }}
                    >
                      {selectedTask?.ticketId}
                    </h5>
                  </div>
                  <Form.Item
                    name="name"
                    noStyle
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (value && value.trim().length > 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Task Name can not be blank");
                          }
                        },
                        message: "Task Name is required",
                      },
                    ]}
                  >
                    <Input.TextArea
                      disabled={
                        selectedTask?.closed ||
                        (activeProject?.type === AppConstants.ADMIN &&
                          loginUser?._id !== selectedTask?.createdBy?._id)
                      }
                      placeholder="Enter Task Name"
                      defaultValue={selectedTask?.name}
                      onPressEnter={(e) => handleOnEnterPressTaskName(e)}
                    />
                  </Form.Item>
                  {/* </Space> */}
                  {/* </Form.Item> */}
                  <div
                    className="d-flex align-center"
                    style={{ margin: "10px 0" }}
                  >
                    <Avatar
                      src={
                        selectedTask?.createdBy?.isUserProfileUploaded
                          ? `${AppConstants.BUCKET_URL}` +
                            selectedTask?.createdBy?.email?.replace("@", "%40")
                          : ""
                      }
                    >
                      {selectedTask?.createdBy?.name?.slice(0, 1)}
                    </Avatar>
                    <h5>{selectedTask?.createdBy?.name} created this task.</h5>
                    <p>{moment(selectedTask?.createdAt).format("Do MMMM")}</p>
                  </div>
                </div>
                <div
                  className="d-flex align-center task-drawer-status"
                  style={{ margin: "20px 0" }}
                >
                  <h5 className="task-drawer-label">Status</h5>
                  <Input.Group>
                    <Form.Item name="status">
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder="Select status"
                        style={{ width: 150 }}
                        options={statusDropdown}
                        defaultValue={selectedTask?.status}
                        onChange={(e) => handleOnChangeStatus(e)}
                        disabled={
                          selectedTask?.closed ||
                          (activeProject?.type === AppConstants.ADMIN &&
                            !assigneeIdsList.includes(
                              loginUser?._id.toString()
                            ))
                        }
                      ></Select>
                    </Form.Item>
                  </Input.Group>
                </div>
                <div
                  className="d-flex align-center task-drawer-status"
                  style={{ margin: "20px 0" }}
                >
                  <h5 className="task-drawer-label">Priority</h5>
                  <Input.Group>
                    <Form.Item name="priority">
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder="Select priority"
                        style={{ width: 150 }}
                        options={priorityDropdown}
                        defaultValue={
                          selectedTask?.priority
                            ? selectedTask?.priority
                            : priority[1]
                        }
                        onChange={(e) => handleOnChangePriority(e)}
                        disabled={
                          selectedTask?.closed ||
                          (activeProject?.type === AppConstants.ADMIN &&
                            !assigneeIdsList.includes(
                              loginUser?._id.toString()
                            ))
                        }
                      ></Select>
                    </Form.Item>
                  </Input.Group>
                </div>

                <div
                  className="d-flex align-center task-drawer-assignee"
                  style={{ margin: "20px 0" }}
                >
                  <h5 className="task-drawer-label">Tags</h5>
                  <Input.Group>
                    <Form.Item name="tags">
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder="Select tags"
                        // style={{ width: 250 }}
                        options={tagDropdown}
                        disabled={
                          activeProject?.type === AppConstants.ADMIN ||
                          selectedTask.closed
                        }
                        mode="multiple"
                        allowClear
                        defaultValue={defaultTags}
                        onChange={(e) => handleOnChangeTags(e)}
                        filterOption={(inputValue, option) => {
                          if (
                            option.name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          ) {
                            return true;
                          } else return false;
                        }}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: "8px 0",
                              }}
                            />
                            <Space
                              style={{
                                padding: "0 8px",
                              }}
                            >
                              <p
                                style={{ textAlign: "center" }}
                                onClick={hideDrawer}
                              >
                                <Link to={`/project/${activeProject._id}/tags`}>
                                  Add more tags
                                </Link>
                              </p>
                            </Space>
                          </>
                        )}
                      ></Select>
                    </Form.Item>
                  </Input.Group>
                </div>
                <div
                  className="d-flex align-center task-drawer-assignee"
                  style={{ margin: "20px 0" }}
                >
                  <h5 className="task-drawer-label">Assignee</h5>
                  <Input.Group>
                    <Form.Item name="assignee">
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder="Select assignee"
                        // style={{ width: 250 }}
                        options={assigneeDropdown}
                        disabled={
                          activeProject?.type === AppConstants.ADMIN ||
                          selectedTask.closed
                        }
                        mode="multiple"
                        allowClear
                        defaultValue={defaultAssigneeDropdown}
                        onChange={(e) => handleOnChangeAssignee(e)}
                        filterOption={(inputValue, option) => {
                          if (
                            option.name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          ) {
                            return true;
                          } else return false;
                        }}
                      ></Select>
                    </Form.Item>
                  </Input.Group>
                </div>

                {activeProject.type !== AppConstants.ADMIN &&
                accountableAssigneeDropdown.length > 1 ? (
                  <div
                    className="d-flex align-center task-drawer-assignee"
                    style={{ margin: "20px 0" }}
                  >
                    <h5 className="task-drawer-label">Accountable Assignee</h5>
                    <Input.Group>
                      <Form.Item name="accountableAssignee">
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          placeholder="Select accountable assignee"
                          // style={{ width: 250 }}
                          options={accountableAssigneeDropdown}
                          disabled={
                            activeProject?.type === AppConstants.ADMIN ||
                            selectedTask.closed
                          }
                          defaultValue={defaultAccountableAssignee}
                          onChange={(e) => handleOnChangeAccountableAssignee(e)}
                          filterOption={(inputValue, option) => {
                            if (
                              option.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            ) {
                              return true;
                            } else return false;
                          }}
                        ></Select>
                      </Form.Item>
                    </Input.Group>
                  </div>
                ) : null}

                {/* <div
                  className="d-flex align-center task-start-date"
                  style={{ margin: "20px 0" }}
                >
                  <h5 className="task-drawer-label">Start Date</h5>
                  <Form.Item name="startDate">
                    <DatePicker getPopupContainer={(trigger) => trigger.parentNode}
                      defaultValue={
                        selectedTask?.startDate
                          ? moment(selectedTask?.startDate)
                          : ""
                      }
                      disabledDate={(d) => {
                        if (endDate) {
                          return (
                            !d ||
                            (d.isAfter(endDate) && !d.isSame(endDate, "days"))
                          );
                        }
                      }}
                      onChange={(e) => handleOnChangeStartDate(e)}
                      showToday={false}
                      disabled
                    />
                  </Form.Item>
                </div> */}
                <div
                  className="d-flex align-center task-end-date"
                  style={{ margin: "20px 0" }}
                >
                  <h5 className="task-drawer-label">End Date</h5>
                  <Form.Item name="endDate">
                    <DatePicker
                      getPopupContainer={(trigger) => trigger.parentNode}
                      defaultValue={
                        selectedTask?.endDate
                          ? moment(selectedTask?.endDate)
                          : ""
                      }
                      format={dateFormat}
                      disabledDate={(d) => {
                        return moment().add(-1, "days") >= d;
                      }}
                      onChange={(e) => handleOnChangeEndDate(e)}
                      showToday={false}
                    />
                  </Form.Item>
                </div>

                <div
                  className="d-flex align-center task-end-date"
                  style={{ margin: "20px 0" }}
                >
                  <h5 className="task-drawer-label">End Time</h5>
                  <Form.Item name="endTime">
                    <TimePicker
                      getPopupContainer={(trigger) => trigger.parentNode}
                      defaultValue={
                        selectedTask?.endTime
                          ? moment(selectedTask?.endTime, "h:mm A")
                          : ""
                      }
                      onChange={handleChangeEditEndTime}
                      showNow={false}
                      disabled={!endDateSelected || selectedTask?.closed}
                      use12Hours
                      format="h:mm A"
                    />
                  </Form.Item>
                </div>

                <div
                  className="task-drawer-description"
                  style={{ margin: "20px 0" }}
                >
                  <h5
                    className="task-drawer-label"
                    style={{ textAlign: "initial" }}
                  >
                    Description
                  </h5>
                  <Form.Item
                    // label="Description"
                    style={{
                      marginBottom: 0,
                    }}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value.trim() !== selectedTask?.description.trim()
                          ) {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Form.Item
                      name="description"
                      style={{
                        display: "inline-block",
                        // width: 250,
                      }}
                    >
                      <Input.TextArea
                        placeholder="Enter Task Description"
                        defaultValue={selectedTask?.description}
                        disabled={
                          selectedTask?.closed ||
                          (activeProject?.type === AppConstants.ADMIN &&
                            loginUser?._id !== selectedTask?.createdBy?._id)
                        }
                        onPressEnter={(e) =>
                          handleOnEnterPressTaskDescription(e)
                        }
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
              </Form>

              <Menu
                mode="horizontal"
                style={{ backgroundColor: "#f9f8f8" }}
                selectedKeys={[current]}
              >
                {items.map((item) => {
                  return (
                    <Menu.Item key={item} onClick={() => setCurrent(item)}>
                      {item}
                    </Menu.Item>
                  );
                })}
              </Menu>

              {current == "Comments" ? (
                <>
                  <div className="comment-container">
                    {comments?.length == 0 ? (
                      <div className="comment-header">
                        {/* <Avatar className="comment-avatar">
                          {<CommentOutlined />}
                        </Avatar> */}
                        No comment found !!
                      </div>
                    ) : null}
                    {/* <hr className="comment-hr" /> */}
                    <div className="comment-body" id="comment-body">
                      {comments?.map((c) => {
                        return (
                          <div
                            className="d-flex align-baseline w-full"
                            style={{ margin: "0 10px" }}
                          >
                            <div>
                              <Avatar
                                className="avatar-text-img"
                                src={
                                  c?.createdBy?.isUserProfileUploaded
                                    ? `${AppConstants.BUCKET_URL}` +
                                      c?.createdBy?.email?.replace("@", "%40")
                                    : ""
                                }
                              >
                                {c?.createdBy?.name?.slice(0, 1)}
                              </Avatar>
                            </div>
                            <div key={c._id} className="comment-box w-full">
                              <div className="d-flex align-baseline justify-space-between">
                                <p className="comment-name d-flex align-center">
                                  <div style={{ color: "#000" }}>
                                    {c?.createdBy?.name}&nbsp;
                                  </div>
                                  <Tooltip
                                    title={moment(c?.createdAt).format(
                                      "h:mm a, Do MMMM, YYYY"
                                    )}
                                  >
                                    <p>{moment(c?.createdAt).fromNow()}</p>
                                  </Tooltip>
                                </p>
                                {loginUser.name === c.createdBy?.name ? (
                                  <>
                                    <p
                                      className="comment-edit"
                                      onClick={() => {
                                        setEditComment(c);
                                        dispatch(setIsEditComment(true));
                                      }}
                                    >
                                      {pencil}
                                    </p>
                                    <Popconfirm
                                      placement="topRight"
                                      title={`Are you sure to delete this comment ?`}
                                      description={`Are you sure to delete this comment`}
                                      overlayClassName="delete-popover"
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={() =>
                                        dispatch(deleteComment(c._id))
                                      }
                                    >
                                      <p className="comment-delete">
                                        {deletebtn}
                                      </p>
                                    </Popconfirm>
                                  </>
                                ) : null}
                              </div>
                              <p
                                className="comment-message"
                                dangerouslySetInnerHTML={{
                                  __html: c.context,
                                }}
                              />
                              <div
                                className="comment-reaction-container d-flex align-center"
                                style={{ paddingBottom: "10px" }}
                              >
                                <Popover content={() => reactionContent(c)}>
                                  {handleCurrentUserReaction(c) ? (
                                    <span
                                      onClick={(e) =>
                                        removeCurrentUserReaction(c)
                                      }
                                      className="comment-reaction-user"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {handleCurrentUserReaction(c)}
                                    </span>
                                  ) : (
                                    <img
                                      src={AddReactionIcon}
                                      alt=""
                                      style={{
                                        width: "22px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </Popover>
                                {c.commentReactions?.length ? (
                                  <>
                                    {c.commentReactions.map((reaction) => (
                                      <Tooltip
                                        overlayClassName="comment-reaction-tooltip"
                                        title={
                                          reactionUsers(reaction).join() +
                                          `  Reacted with '${reaction._id}'`
                                        }
                                      >
                                        <span style={{ marginLeft: "10px" }}>
                                          <p
                                            className="user-reaction-container"
                                            style={{ margin: 0 }}
                                          >
                                            {handleReactionIcon(reaction)}
                                            {reaction?.userReactions?.length}
                                          </p>
                                        </span>
                                      </Tooltip>
                                    ))}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="text-editor-container">
                      <QuillEditor comment={editComment} />
                    </div>
                  </div>
                </>
              ) : (
                <div className="comment-container">
                  {auditData?.length == 0 ? (
                    <div className="comment-header">
                      {/* <Avatar className="comment-avatar">
                    {<CommentOutlined />}
                  </Avatar> */}
                      No Activity found !!
                    </div>
                  ) : null}
                  {/* <hr className="comment-hr" /> */}
                  <div className="comment-body" id="comment-body">
                    {auditData?.map((audit) => {
                      return (
                        <div className="comment-box d-flex flex-col">
                          <div
                            key={audit._id}
                            className="d-flex align-center justify-space-between"
                            style={{ width: "100%" }}
                          >
                            <div className="d-flex ">
                              <p className="comment-name">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <Avatar
                                    size={25}
                                    className="avatar-text-img"
                                    src={
                                      audit?.updatedBy?.isUserProfileUploaded
                                        ? `${AppConstants.BUCKET_URL}` +
                                          audit?.updatedBy?.email?.replace(
                                            "@",
                                            "%40"
                                          )
                                        : ""
                                    }
                                  >
                                    {audit?.updatedBy?.name?.slice(0, 1)}
                                  </Avatar>
                                  {audit?.updatedBy?.name}&nbsp;
                                </div>
                              </p>
                              <p>
                                {audit?.operation} the{}&nbsp;
                              </p>
                              <p
                                style={{
                                  fontWeight: 700,
                                  textTransform: "capitalize",
                                }}
                              >
                                {audit.fieldName}.&nbsp;
                              </p>
                            </div>
                            <p
                              style={{
                                fontSize: "10px",
                                fontWeight: 400,
                                marginRight: "10px",
                              }}
                            >
                              {moment(audit?.updatedAt).fromNow()}
                            </p>
                          </div>
                          {audit?.fieldName == "status" ? (
                            <div
                              className="d-flex align-center"
                              style={{ margin: "0 10px 20px 35px " }}
                            >
                              <div
                                className={
                                  audit?.oldValue == AppConstants.TO_DO
                                    ? "todo-bg-highlight"
                                    : audit?.oldValue ==
                                      AppConstants.IN_PROGRESS
                                    ? "progress-bg-highlight"
                                    : audit?.oldValue == AppConstants.DONE
                                    ? "done-bg-highlight"
                                    : "hold-bg-highlight"
                                }
                              >
                                {audit?.oldValue}
                              </div>
                              <div>
                                <DoubleRightOutlined
                                  style={{ margin: "0 20px" }}
                                />
                              </div>
                              <div
                                className={
                                  audit?.newValue == AppConstants.TO_DO
                                    ? "todo-bg-highlight"
                                    : audit?.newValue ==
                                      AppConstants.IN_PROGRESS
                                    ? "progress-bg-highlight"
                                    : audit?.newValue == AppConstants.DONE
                                    ? "done-bg-highlight"
                                    : "hold-bg-highlight"
                                }
                              >
                                {audit?.newValue}
                              </div>
                            </div>
                          ) : audit?.fieldName == "name" ||
                            audit?.fieldName == "description" ? (
                            <div
                              className="d-flex"
                              style={{ paddingLeft: "30px" }}
                            >
                              <p
                                style={{
                                  backgroundColor: "#f3ffea",
                                  padding: "5px 10px",
                                  borderRadius: "8px",
                                }}
                              >
                                {audit?.newValue}
                              </p>
                            </div>
                          ) : audit?.fieldName == "startDate" ||
                            audit?.fieldName == "endDate" ? (
                            <div
                              className="d-flex align-center"
                              style={{ margin: "0 10px 20px 35px " }}
                            >
                              <p style={{ margin: 0, color: "#c92f54" }}>
                                {audit?.oldValue
                                  ? moment(audit?.oldValue).format("DD/MM/YYYY")
                                  : "None"}
                              </p>{" "}
                              <DoubleRightOutlined
                                style={{ margin: "0 20px" }}
                              />
                              <p style={{ margin: 0, color: "#0d7f56" }}>
                                {audit?.newValue
                                  ? moment(audit?.newValue).format("DD/MM/YYYY")
                                  : "None"}
                              </p>
                            </div>
                          ) : audit?.fieldName === "accountable assignee" ||
                            audit?.fieldName == "endTime" ||
                            audit?.fieldName == "tags" ? (
                            <div
                              className="d-flex align-center"
                              style={{ margin: "0 10px 20px 35px " }}
                            >
                              <p style={{ margin: 0, color: "#c92f54" }}>
                                {audit?.oldValue ? audit?.oldValue : "None"}
                              </p>{" "}
                              <DoubleRightOutlined
                                style={{ margin: "0 20px" }}
                              />
                              <p style={{ margin: 0, color: "#0d7f56" }}>
                                {audit?.newValue ? audit?.newValue : "None"}
                              </p>
                            </div>
                          ) : audit?.fieldName === "assignee" ? (
                            <div
                              className="d-flex align-center"
                              style={{
                                paddingLeft: "35px",
                                marginBottom: "20px",
                              }}
                            >
                              <div
                                className="d-flex"
                                style={
                                  audit.operation == "added"
                                    ? {
                                        color: "#c92f54",
                                      }
                                    : { color: "#0d7f56" }
                                }
                              >
                                {audit?.oldValue !== "Unassigned" ? (
                                  JSON.parse(audit?.oldValue)?.map(
                                    (value, i) => (
                                      <p style={{ margin: 0 }}>
                                        {JSON.parse(audit?.oldValue).length ==
                                        i + 1
                                          ? value.name
                                          : `${value.name}, `}
                                      </p>
                                    )
                                  )
                                ) : (
                                  <p style={{ margin: 0 }}>Unassigned</p>
                                )}
                              </div>
                              <DoubleRightOutlined
                                style={{ margin: "0 20px" }}
                              />
                              <div
                                className="d-flex"
                                style={
                                  audit.operation === "removed"
                                    ? {
                                        color: "#c92f54",
                                      }
                                    : { color: "#0d7f56" }
                                }
                              >
                                {JSON.parse(audit?.newValue)?.map(
                                  (value, i) => (
                                    <p style={{ margin: 0 }}>
                                      {JSON.parse(audit?.newValue).length ==
                                      i + 1
                                        ? value.name
                                        : `${value.name}, `}
                                    </p>
                                  )
                                )}
                              </div>
                            </div>
                          ) : audit?.fieldName === "priority" ? (
                            <div
                              className="d-flex align-center"
                              style={{ margin: "0 10px 20px 35px " }}
                            >
                              <div>
                                {audit?.oldValue === "Medium" ? (
                                  <PauseOutlined className="priority-medium" />
                                ) : (
                                  <DoubleLeftOutlined
                                    className={
                                      audit.oldValue === "High"
                                        ? "priority-high"
                                        : "priority-low"
                                    }
                                  />
                                )}
                                {audit.oldValue}
                              </div>
                              <div>
                                <DoubleRightOutlined
                                  style={{ margin: "0 20px" }}
                                />
                              </div>
                              <div>
                                {audit?.newValue === "Medium" ? (
                                  <PauseOutlined className="priority-medium" />
                                ) : (
                                  <DoubleLeftOutlined
                                    className={
                                      audit.newValue === "High"
                                        ? "priority-high"
                                        : "priority-low"
                                    }
                                  />
                                )}
                                {audit.newValue}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default TaskDrawer;
