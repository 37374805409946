import { Avatar, Button, Modal, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AWS from "aws-sdk";
import {
  setActiveUserProfile,
  setActiveCompanyProfile,
  setUploaded,
  uploadUserProfile,
} from "../../redux/slices/UserSlice";
import AppConstants from "../../utils/AppConstants";

const UploadProfileModal = ({ isOpen, handleClose }) => {
  const { userRole, activeCompany, activeUserProfile, activeCompanyProfile } =
    useSelector((state) => state.user);
  const { loginUser } = useSelector((state) => state.login);

  const [companyImage, setCompanyImage] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [companyImgSrc, setCompanyImgSrc] = useState("");

  useEffect(() => {
    if (activeUserProfile) {
      setImageSrc(activeUserProfile);
    }
  }, [activeUserProfile]);

  useEffect(() => {
    if (activeCompanyProfile) {
      setCompanyImgSrc(activeCompanyProfile);
    }
  }, [activeCompanyProfile]);

  const dispatch = useDispatch();

  const S3_BUCKET = `${AppConstants.BUCKET_NAME}/`;
  const REGION = "us-east-1";

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_BUCKET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const handleUpload = () => {
    if (companyImage) {
      var companyKey = activeCompany?.name?.replace(" ", "_").toLowerCase();
      const params = {
        ACL: "public-read",
        Body: companyImage,
        Bucket: S3_BUCKET + "company_logo",
        Key: companyKey,
      };
      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          if (Math.round((evt.loaded / evt.total) * 100) + "" === "100") {
            const reader = new FileReader();
            reader.onload = () => {
              dispatch(setActiveCompanyProfile(reader.result));
              setCompanyImgSrc(reader.result);
            };
            reader.readAsDataURL(companyImage);
            message.success("Company Image Uploaded Successfully!");
          }
        })
        .send((err) => {
          if (err) console.log(err);
        });
    }

    if (userProfile) {
      var userKey = loginUser?.email?.replace(" ", "_").toLowerCase();
      const params1 = {
        ACL: "public-read",
        Body: userProfile,
        Bucket: S3_BUCKET + "user_profile",
        Key: userKey,
      };
      myBucket
        .putObject(params1)
        .on("httpUploadProgress", (evt) => {
          if (Math.round((evt.loaded / evt.total) * 100) + "" === "100") {
            const reader = new FileReader();
            reader.onload = () => {
              dispatch(setActiveUserProfile(reader.result));
              dispatch(
                uploadUserProfile({
                  userId: loginUser?._id,
                  profile: { isUserProfileUploaded: true },
                })
              );
              setImageSrc(reader.result);
              window.location.reload();
            };
            reader.readAsDataURL(userProfile);
            message.success("User Image Uploaded Successfully!");
          }
        })
        .send((err) => {
          if (err) {
            console.log(err);
            message.error("User Image Uploaded Failed!");
          }
        });
    }

    dispatch(setUploaded());
    resetFileInput();
    handleClose();
  };

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  const resetFileInput = () => {
    setCompanyImgSrc("");
    setImageSrc("");
    setUserProfile("");
    setCompanyImage("");
    // 👇️ reset input value
    inputRef1.current.value = null;
    if (inputRef2?.current) {
      inputRef2.current.value = null;
    }

    if (activeUserProfile) {
      setImageSrc(activeUserProfile);
    }

    if (activeCompanyProfile) {
      setCompanyImgSrc(activeCompanyProfile);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      const file = e.target.files[0];
      if (file) {
        setUserProfile(file);
        const reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      message.error("Please select a valid image (jpg, jpeg, or png)");
      resetFileInput();
    }
  };

  const handleCompanyProfileChange = (e) => {
    if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      const file = e.target.files[0];
      if (file) {
        setCompanyImage(file);
        const reader = new FileReader();
        reader.onload = () => {
          setCompanyImgSrc(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      message.error("Please select a valid image (jpg, jpeg, or png)");
      resetFileInput();
    }
  };

  return (
    <div>
      <Modal
        title="Edit Profile"
        open={isOpen}
        onCancel={() => {
          resetFileInput();
          handleClose();
        }}
        footer={null}
      >
        <div className="d-flex ">
          <div>
            <Avatar
              size={100}
              className="theme-bg-gradient"
              style={{ fontSize: "50px" }}
              src={imageSrc}
            >
              {loginUser?.name?.slice(0, 1)}
            </Avatar>
          </div>
          <div className="ml-20">
            <h4>Upload Profile Picture</h4>
            <input
              className="input-file"
              type="file"
              ref={inputRef1}
              onChange={handleFileChange}
            />
          </div>
        </div>
        {/* {userRole === AppConstants.OWNER ? (
          <div className="d-flex " style={{ marginTop: "25px" }}>
            <div>
              <Avatar
                size={100}
                className="theme-bg-gradient"
                style={{ fontSize: "50px" }}
                src={companyImgSrc}
              >
                {activeCompany?.name?.slice(0, 1)}
              </Avatar>
            </div>
            <div className="ml-20">
              <h4>Upload Company Logo</h4>
              <input
                className="input-file"
                type="file"
                ref={inputRef2}
                onChange={handleCompanyProfileChange}
              />
            </div>
          </div>
        ) : null} */}
        <div
          className="d-flex justify-space-between"
          style={{ marginTop: "25px" }}
        >
          <Button
            htmlType="button"
            onClick={() => {
              resetFileInput();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpload}
            className="theme-bg-gradient"
            style={{ color: "#fff" }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UploadProfileModal;
