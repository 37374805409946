import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addTaskPayload: {
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    endTime: "",
    status: "",
    project: {},
    assignee: [],
  },
  comments: [],
  showTaskDrawer: false,
  selectedTask: {},
  addMemberModal: false,
  isEditComment: false,
  taskAudits: [],
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    deleteTask: () => {},
    updateTaskFields: () => {},
    updateAccountableAssignee: () => {},
    setDefaultAddTaskPayload: (state, action) => {
      state.addTaskPayload.status = action.payload.status;
      state.addTaskPayload.project = action.payload.project;
    },
    resetAddTaskPayload: (state, action) => {
      state.addTaskPayload = initialState.addTaskPayload;
    },
    addNewTask: (state, action) => {
      state.addTaskPayload = action.payload;
    },
    setShowTaskDrawer: (state, action) => {
      state.showTaskDrawer = action.payload;
    },
    getSelectedTask: () => {},
    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },
    setShowAddMemberModalOpen: (state, action) => {
      state.addMemberModal = action.payload;
    },
    addComment: () => {},
    getComments: () => {},
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    addNewComment: (state, action) => {
      state.comments.push(action.payload);
    },
    removeComment: (state, action) => {
      let index = state.comments.findIndex(
        (comment) => comment._id === action.payload._id
      );
      let comments = state.comments.slice();
      comments.splice(index, 1);

      state.comments = comments;
    },
    deleteComment: () => {},
    editComment: () => {},
    setIsEditComment: (state, action) => {
      state.isEditComment = action.payload;
    },
    updateComment: (state, action) => {
      let index = state.comments.findIndex(
        (comment) => comment._id === action.payload._id
      );
      let comments = state.comments.slice();
      comments[index] = action.payload;

      state.comments = comments;
    },
    closeTask: () => {},
    fetchTaskAudits: () => {},
    setTaskAudits: (state, action) => {
      state.taskAudits = action.payload;
    },
    updateTaskAudits: (state, action) => {
      let audits = [...state.taskAudits];
      audits.push(action.payload);
      state.taskAudits = audits;
    },
    addReaction: () => {},
    updateCommentsReaction: (state, action) => {
      let comments = [...state.comments];
      let commentIndex = comments.findIndex(
        (comment) => comment._id === action.payload.commentId
      );
      comments[commentIndex].commentReactions = action.payload.commentReaction;
      state.comments = comments;
    },
    removeReaction: (state, action) => {},
    taskCleanup: (state, action) => {
      state.selectedTask = {};
      state.comments = [];
      state.taskAudits = [];
    },
  },
});

export const {
  deleteTask,
  updateTaskFields,
  updateAccountableAssignee,
  setDefaultAddTaskPayload,
  resetAddTaskPayload,
  addNewTask,
  setShowTaskDrawer,
  getSelectedTask,
  setSelectedTask,
  setShowAddMemberModalOpen,
  addComment,
  getComments,
  setComments,
  addNewComment,
  removeComment,
  deleteComment,
  setIsEditComment,
  editComment,
  updateComment,
  closeTask,
  fetchTaskAudits,
  setTaskAudits,
  updateTaskAudits,
  addReaction,
  removeReaction,
  updateCommentsReaction,
  taskCleanup
} = taskSlice.actions;

export default taskSlice.reducer;
