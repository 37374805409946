import { Avatar, Popover, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppConstants from "../../utils/AppConstants";
import { Link } from "react-router-dom";
import { ClockCircleOutlined, MailOutlined } from "@ant-design/icons";
import { setProjectTasks } from "../../redux/slices/ProjectSlice";

const MyProjects = () => {
  const { activeCompany } = useSelector((state) => state.user);
  const { projects } = useSelector((state) => state.project);
  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let data = [];
    projects?.forEach((project, index) => {
      data.push({
        id: project?._id,
        name: { projectName: project?.name, projectId: project?._id },
        createdBy: {
          userName: project?.createdBy?.name,
          userEmail: project?.createdBy?.email,
          isUserProfileUploaded: project?.createdBy?.isUserProfileUploaded,
        },
        members: project?.team,
        type: project?.type,
        date: moment(project?.createdAt).format("DD MMMM YYYY"),
      });
    });
    setTableData(data);
  }, [projects]);

  const handleProfileContent = (user) => {
    return (
      <div>
        {user?.isUserProfileUploaded ? (
          <img
            src={
              `${AppConstants.BUCKET_URL}` + user?.email?.replace("@", "%40")
            }
            alt=""
          />
        ) : (
          <div className="d-flex align-center justify-center theme-bg-color-primary name-letter-container">
            {user.name.slice(0, 1)}
          </div>
        )}
        <div className="popup-details">
          <h5>{user?.name}</h5>
          <div className="d-flex align-center">
            <MailOutlined />
            <p>{user?.email}</p>
          </div>
          <div className="d-flex align-center">
            <ClockCircleOutlined />
            <p>{moment(new Date()).format("h:mm A")} local time</p>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    // {
    //   title: "S. No",
    //   dataIndex: "sno",
    //   key: "sno",
    // },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      width: "180px",
      render: (project) => (
        <Link
          to={`/project/${project?.projectId}/board`}
          style={{ fontWeight: 600, color: "#000" }}
          onClick={(e) => dispatch(setProjectTasks([]))}
        >
          <td>
            {project?.projectName?.length <= 20
              ? project?.projectName
              : `${project?.projectName?.slice(0, 20)}...`}{" "}
          </td>
        </Link>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "200px",
      render: (createdBy) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Avatar
            alt=""
            // icon={<UserOutlined />}
            className="avatar-text-img"
            src={
              createdBy.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` +
                  createdBy?.userEmail?.replace("@", "%40")
                : ""
            }
          >
            {createdBy?.userName.slice(0, 1)}
          </Avatar>
          {createdBy?.userName}
        </div>
      ),
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      width: "150px",
      render: (members) => (
        <Avatar.Group maxCount={2} maxPopoverTrigger="">
          {members.map((member) => (
            <Popover content={() => handleProfileContent(member)}>
              <Avatar
                alt=""
                className="avatar-text-img"
                src={
                  member?.isUserProfileUploaded
                    ? `${AppConstants.BUCKET_URL}` +
                      member?.email?.replace("@", "%40")
                    : ""
                }
              >
                {member?.name.slice(0, 1)}
              </Avatar>
            </Popover>
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "120px",
      render: (text) => (
        <p
          className={
            text == "Regular" ? "primary-highlight" : "secondary-highlight"
          }
        >
          {text}
        </p>
      ),
    },
    // {
    //   title: "Start Date",
    //   dataIndex: "date",
    //   key: "date",
    // },
  ];

  return (
    <div>
      <h1 className="theme-color-primary page-heading">My Projects</h1>
      <Table
        scroll={{
          x: 200,
          y: 300,
        }}
        dataSource={tableData}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default MyProjects;
