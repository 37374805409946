import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  views: [],
};

const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    getViews: () => {},
    addView: () => {},
    deleteView: () => {},
    updateView: () => {},
    setViews: (state, action) => {
      state.views = action.payload;
    },
    updateExistingView: (state, action) => {
      let views = [...state.views];
      const index = views.findIndex((view) => view._id === action.payload._id);
      views[index] = action.payload;
      state.views = views;
    },
    addViewToList: (state, action) => {
      let views = [...state.views];
      views.push(action.payload);
      state.views = views;
    },
    removeViewFromList: (state, action) => {
      let views = [...state.views];
      const index = views.findIndex((view) => view._id === action.payload._id);
      views.splice(index, 1);
      state.views = views;
    },
  },
});

export const {
  getViews,
  setViews,
  updateView,
  addViewToList,
  addView,
  deleteView,
  removeViewFromList,
  updateExistingView,
} = viewSlice.actions;
export default viewSlice.reducer;
