import { Avatar, Modal } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import AppConstants from "../../utils/AppConstants";
import { DoubleRightOutlined } from "@ant-design/icons";
import { setAssetHistory } from "../../redux/slices/AssetSlice";

const AssetsHistoryModal = ({ isOpen, close }) => {
  const { history } = useSelector((state) => state.asset);

  const handleClose = () => {
    setAssetHistory([]);
    close();
  };

  return (
    <div>
      <Modal
        title="Asset History"
        centered
        closable={true}
        open={isOpen}
        onCancel={handleClose}
        footer={null}
        width={600}
        className="assets-history-modal"
      >
        <div className="asset-history-body">
          {history?.length ? (
            history?.map((audit) => {
              return (
                <div className="comment-box d-flex flex-col">
                  <div
                    key={audit._id}
                    className="d-flex align-center justify-space-between"
                    style={{ width: "100%" }}
                  >
                    <div className="d-flex ">
                      <p className="comment-name">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            paddingRight: "10px",
                          }}
                        >
                          <Avatar
                            size={25}
                            className="avatar-text-img"
                            src={
                              audit?.updatedBy?.isUserProfileUploaded
                                ? `${AppConstants.BUCKET_URL}` +
                                  audit?.updatedBy?.email?.replace("@", "%40")
                                : ""
                            }
                          >
                            {audit?.updatedBy?.name?.slice(0, 1)}
                          </Avatar>
                          {audit?.updatedBy?.name}
                        </div>
                      </p>
                      <p>
                        {audit?.operation} the{}&nbsp;
                      </p>
                      <p
                        style={{
                          fontWeight: 700,
                          textTransform: "capitalize",
                        }}
                      >
                        {audit.fieldName}&nbsp;
                      </p>
                      {audit?.operation === "assigned" ||
                      audit?.operation === "recovered" ? (
                        <p>
                          {audit?.operation === "assigned" ? "to" : "from"}{" "}
                        </p>
                      ) : null}
                    </div>
                    <p
                      style={{
                        fontSize: "10px",
                        fontWeight: 400,
                        marginRight: "10px",
                      }}
                    >
                      {moment(audit?.updatedAt)?.format("h:mm a, Do MMM, YYYY")}
                    </p>
                  </div>
                  {audit?.fieldName == "properties" ? (
                    <div className="d-flex" style={{ paddingLeft: "30px" }}>
                      {audit?.newValue && JSON.parse(audit?.newValue).length ? (
                        <div className="asset-history-property-card">
                          {audit?.newValue && JSON.parse(audit?.newValue).length
                            ? JSON.parse(audit?.newValue).map((property) => (
                                <div className=" d-flex align-center ">
                                  <p style={{ minWidth: "100px", margin: 0 }}>
                                    {" "}
                                    {property.key}
                                  </p>
                                  <span style={{ margin: "0 10px" }}>:</span>
                                  <p style={{ color: "#1079ff", margin: 0 }}>
                                    {property.value}{" "}
                                  </p>
                                </div>
                              ))
                            : null}
                        </div>
                      ) : null}
                    </div>
                  ) : audit?.fieldName === "category" ||
                    audit?.fieldName === "count" ||
                    audit?.fieldName === "name" ||
                    audit?.fieldName === "vendor" ? (
                    <div
                      className="d-flex align-center"
                      style={{ margin: "0 10px 20px 35px " }}
                    >
                      <p style={{ margin: 0, color: "#c92f54" }}>
                        {audit?.oldValue ? audit?.oldValue : "None"}
                      </p>{" "}
                      <DoubleRightOutlined style={{ margin: "0 20px" }} />
                      <p style={{ margin: 0, color: "#0d7f56" }}>
                        {audit?.newValue ? audit?.newValue : "None"}
                      </p>
                    </div>
                  ) : audit?.fieldName === "status" ? (
                    <div
                      className="d-flex align-center"
                      style={{ margin: "0 10px 20px 35px " }}
                    >
                      <div
                        className={
                          audit?.oldValue === "New"
                            ? "progress-bg-highlight"
                            : audit?.oldValue === "Recovered"
                            ? "todo-bg-highlight"
                            : "hold-bg-highlight"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {audit?.oldValue}
                      </div>
                      <div>
                        <DoubleRightOutlined style={{ margin: "0 20px" }} />
                      </div>
                      <div
                        className={
                          audit?.newValue === "New"
                            ? "progress-bg-highlight"
                            : audit?.newValue === "Recovered"
                            ? "todo-bg-highlight"
                            : "hold-bg-highlight"
                        }
                        style={{ textAlign: "center" }}
                      >
                        {audit?.newValue}
                      </div>
                    </div>
                  ) : (audit?.operation === "assigned" ||
                      audit?.operation === "recovered") &&
                    audit?.newValue ? (
                    <div
                      className="d-flex align-center"
                      style={{ margin: "0 10px 20px 35px " }}
                    >
                      {Array.isArray(JSON.parse(audit?.newValue)) ? (
                        <div className="d-flex" style={{ color: "#0d7f56" }}>
                          {JSON.parse(audit?.newValue)?.map((value, i) => (
                            <p style={{ margin: 0 }}>
                              {JSON.parse(audit?.newValue).length == i + 1
                                ? value.name
                                : `${value.name}, `}
                              &nbsp;
                            </p>
                          ))}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            paddingRight: "10px",
                            fontWeight: 700,
                          }}
                        >
                          <Avatar
                            size={25}
                            className="avatar-text-img"
                            src={
                              JSON.parse(audit?.newValue)?.isUserProfileUploaded
                                ? `${AppConstants.BUCKET_URL}` +
                                  JSON.parse(audit?.newValue)?.email?.replace(
                                    "@",
                                    "%40"
                                  )
                                : ""
                            }
                          >
                            {JSON.parse(audit?.newValue)?.name?.slice(0, 1)}
                          </Avatar>
                          {JSON.parse(audit?.newValue)?.name}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <p>No history available</p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AssetsHistoryModal;
