import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo3 from "../assets/images/Google__G__Logo.svg.png";
import { Layout, Button, Col, Form, Input, Divider } from "antd";
import logo from "../assets/images/ontrack.png";
import { useDispatch, useSelector } from "react-redux";
import { takeLoginUser } from "../redux/slices/LoginSlice";
import AppConstants from "../utils/AppConstants";

const { Footer, Content } = Layout;

function SignIn() {
  const { loginUser } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(loginUser).length !== 0) {
      navigate("/");
    }
  }, [loginUser]);

  const onFinish = (values) => {
    dispatch(takeLoginUser(values));
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          {/* <div gutter={[24, 0]} justify="space-around"> */}
          <div className="theme-color-primary" style={{ width: "130px" }}>
            <img src={logo} alt="Grappler" style={{ margin: "10px 20px" }} />
          </div>
          <div className="d-flex justify-center align-center w-full h-full">
            <Col
              xs={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              className="d-flex flex-col align-center  h-full"
              // style={{ backgroundColor: "#fff" }}
            >
              <div
                className="d-flex flex-col align-center justify-center signin-container"
                style={{ maxWidth: "400px", marginTop: "20px" }}
              >
                <h3>Welcome to Grappler</h3>
                <h4>To get started, please sign in</h4>
                <div
                  className="card-signup header-solid w-full "
                  style={{
                    width: 400,
                    border: "none",
                    boxShadow: "none",
                    background: "transparent",
                    marginBottom: 0,
                  }}
                >
                  <div className="sign-up-gateways d-flex justify-center">
                    <a
                      href={`${AppConstants.SERVER_URL}/auth/google`}
                      style={{ width: "100%" }}
                    >
                      <Button type="false" style={{ width: "100%", margin: 0 }}>
                        <img src={logo3} alt="logo 3" />
                        <span
                          style={{
                            marginLeft: "10px",
                            fontSize: "16px",
                            fonstWeight: "400 !important",
                          }}
                        >
                          Continue with Google
                        </span>
                      </Button>
                    </a>
                  </div>
                </div>
                <div className="w-full">
                  <Divider>or</Divider>
                </div>
                {/* <Title className="mb-15 theme-color-primary">Sign In</Title> */}
                <Form
                  onFinish={onFinish}
                  layout="vertical"
                  className="row-col"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    className="username"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>

                  <Form.Item
                    style={{ margin: 0 }}
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters",
                      },
                    ]}
                  >
                    <Input type="password" placeholder="Enter your password" />
                  </Form.Item>
                  <Form.Item>
                    <Link
                      to="/forgotPassword"
                      style={{ fontSize: "12px", fontWeight: 400 }}
                    >
                      Forgot Password ?
                    </Link>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      className="theme-bg-color-primary"
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                </Form>
                {/* Sign Up Disabled */}
                {/* <p className=" text-muted">
                  Don't have an account?{" "}
                  <Link to="/sign-up" className="text-dark ">
                    Sign Up
                  </Link>
                </p> */}
              </div>
            </Col>
          </div>
          {/* <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col> */}
        </Content>
        <Footer>
          <p className="copyright">
            Copyright © 2024 Grappler by Innogent Technologies
          </p>
        </Footer>
      </Layout>
    </>
  );
}

export default SignIn;
