import { Button, Input, Modal } from "antd";
import React, { useState } from "react";

const ViewNameModal = ({ isOpen, handleClose, handleConfirm }) => {
  const [viewName, setViewName] = useState(null);
  return (
    <Modal
      className="delete-modal"
      centered
      closable={false}
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      style={{ width: "fit-content" }}
      maskClosable={false}
    >
      <div style={{ minWidth: "150px" }}>
        <Input
          placeholder="Enter the name for the view"
          style={{ marginBottom: "20px" }}
          onChange={(e) => setViewName(e.target.value)}
          value={viewName}
        />
        <div className="d-flex align-center justify-space-between">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="primary "
            onClick={() => {
              handleClose();
              handleConfirm(viewName);
              setViewName(null);
            }}
            className="theme-bg-gradient"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewNameModal;
