import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  AppstoreAddOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  ProfileOutlined,
  ProjectOutlined,
  RightOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUserRole } from "../../redux/slices/UserSlice";
import AppConstants from "../../utils/AppConstants";
import {
  getActiveProject,
  setActiveProject,
  setProjectTasks,
} from "../../redux/slices/ProjectSlice";
import Logo from "../../assets/images/inno_icon.png";
function Sidenav() {
  const { projects, activeProject } = useSelector((state) => state.project);
  const { loginUser } = useSelector((state) => state.login);
  const { activeCompany, userRole } = useSelector((state) => state.user);

  const [isCollapse, setIsCollapse] = useState(false);

  const dispatch = useDispatch();
  const path = useLocation();

  // useEffect(() => {
  //   if (activeCompanyProfile) {
  //     setCompanyImage(activeCompanyProfile);
  //   }
  // }, [activeCompanyProfile]);

  useEffect(() => {
    if (Object.keys(loginUser).length !== 0) {
      loginUser?.role?.forEach((role) => {
        if (role?.company === activeCompany?._id) {
          dispatch(setUserRole(role.name));
          localStorage.setItem("role", role.name);
        }
      });
    }
  }, [loginUser, activeCompany]);

  const handleChangeActiveProject = (id) => {
    if (!id) {
      dispatch(setActiveProject({}));
      dispatch(setProjectTasks([]));
    } else if (id && activeProject._id !== id) {
      dispatch(getActiveProject(id));
      dispatch(setProjectTasks([]));
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div className=" d-flex align-center justify-center company-logo">
          <img
            src={Logo}
            style={{ width: "30px", marginRight: "5px" }}
            alt="Logo"
          />
          {activeCompany?.name}
        </div>
        <Menu theme="light" mode="inline">
          <Menu.Item
            className={
              path.pathname.includes("dashboard") ? "active-nav-item" : ""
            }
            onClick={() => handleChangeActiveProject(null)}
          >
            <Link to={"/dashboard"}>
              {/* <span className="icon">{dashboard}</span> */}
              <DashboardOutlined
                className={path.pathname.includes("dashboard") ? "active" : ""}
                style={{ fontSize: "18px" }}
              />
              <span
                className={`${
                  path.pathname.includes("dashboard")
                    ? "active-nav-label"
                    : "nav-label"
                }`}
              >
                Dashboard
              </span>
            </Link>
          </Menu.Item>

          <Menu.Item onClick={() => setIsCollapse(!isCollapse)}>
            <Link
              className="d-flex align-center justify-space-between"
              to={"#"}
            >
              <div>
                <ProjectOutlined
                  // className={path.pathname.includes("project") ? "active" : ""}
                  style={{ fontSize: "18px" }}
                />
                <span className="nav-label">Projects</span>
              </div>
              {isCollapse ? (
                <RightOutlined style={{ fontSize: "12px" }} />
              ) : (
                <DownOutlined style={{ fontSize: "12px" }} />
              )}
            </Link>
          </Menu.Item>
          {isCollapse
            ? projects?.map((project) => (
                <Menu.Item
                  className={
                    activeProject?.name === project?.name
                      ? "active-nav-item"
                      : ""
                  }
                  style={
                    activeProject?.name === project?.name
                      ? {
                          // backgroundColor: "#fff",
                          backgroundImage:
                            "linear-gradient(to right, #8D84E8 , #0037A2)",
                          // boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                        }
                      : {}
                  }
                  onClick={() => handleChangeActiveProject(project?._id)}
                >
                  <Link to={`/project/${project?._id}/board`}>
                    {project?.name?.length < 23 ? (
                      <span
                        className={`${
                          activeProject?.name === project.name
                            ? "active-sub-nav-label"
                            : "nav-label-sub-item"
                        }`}
                      >
                        {project.name}
                      </span>
                    ) : (
                      <span
                        className={`${
                          activeProject?.name === project.name
                            ? "active-sub-nav-label"
                            : "nav-label-sub-item"
                        }`}
                      >
                        {`${project?.name?.slice(0, 25)}...`}
                      </span>
                    )}
                    {/* <span
                      className={`${
                        activeProject?.name === project.name
                          ? "active-sub-nav-label"
                          : "nav-label-sub-item"
                      }`}
                    >
                      {project.name}
                    </span> */}
                  </Link>
                </Menu.Item>
              ))
            : null}
        </Menu>
        {userRole === AppConstants.OWNER ? (
          <Menu>
            <Menu.Item
              onClick={() => handleChangeActiveProject(null)}
              className={
                path.pathname.includes("admin") ? "active-nav-item" : ""
              }
            >
              <Link to={"/admin"}>
                <UserAddOutlined
                  className={path.pathname.includes("admin") ? "active" : ""}
                  style={{ fontSize: "18px" }}
                />
                <span
                  className={`${
                    path.pathname.includes("admin")
                      ? "active-nav-label"
                      : "nav-label"
                  }`}
                >
                  Admin
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        ) : null}
        <Menu>
          <Menu.Item
            onClick={() => handleChangeActiveProject(null)}
            className={
              path.pathname.includes("my-tasks") ? "active-nav-item" : ""
            }
          >
            <Link to={"/my-tasks"}>
              <ProfileOutlined
                className={path.pathname.includes("my-tasks") ? "active" : ""}
                style={{ fontSize: "18px" }}
              />
              <span
                className={`${
                  path.pathname.includes("my-tasks")
                    ? "active-nav-label"
                    : "nav-label"
                }`}
              >
                My Tasks
              </span>
            </Link>
          </Menu.Item>
          {userRole !== AppConstants.USER ? (
            <Menu.Item
              onClick={() => handleChangeActiveProject(null)}
              className={
                path.pathname.includes("assets") ? "active-nav-item" : ""
              }
            >
              <Link to={"/assets"}>
                <AppstoreAddOutlined
                  className={path.pathname.includes("assets") ? "active" : ""}
                  style={{ fontSize: "18px" }}
                />
                <span
                  className={`${
                    path.pathname.includes("assets")
                      ? "active-nav-label"
                      : "nav-label"
                  }`}
                >
                  Assets
                </span>
              </Link>
            </Menu.Item>
          ) : null}
          <Menu.Item
            onClick={() => handleChangeActiveProject(null)}
            className={
              path.pathname.includes("insights") ? "active-nav-item" : ""
            }
          >
            <Link to={"insights"}>
              <DeploymentUnitOutlined
                className={path.pathname.includes("insights") ? "active" : ""}
                style={{ fontSize: "18px" }}
              />
              <span
                className={`${
                  path.pathname.includes("insights")
                    ? "active-nav-label"
                    : "nav-label"
                }`}
              >
                Insights
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleChangeActiveProject(null)}
            className={
              path.pathname.includes("reports") ? "active-nav-item" : ""
            }
          >
            <Link to={"reports"}>
              <PieChartOutlined
                className={path.pathname.includes("reports") ? "active" : ""}
                style={{ fontSize: "18px" }}
              />
              <span
                className={`${
                  path.pathname.includes("reports")
                    ? "active-nav-label"
                    : "nav-label"
                }`}
              >
                Reports
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleChangeActiveProject(null)}
            className={path.pathname.includes("more") ? "active-nav-item" : ""}
          >
            <Link to={"more"}>
              <MenuUnfoldOutlined
                className={path.pathname.includes("more") ? "active" : ""}
                style={{ fontSize: "18px" }}
              />
              <span
                className={`${
                  path.pathname.includes("more")
                    ? "active-nav-label"
                    : "nav-label"
                }`}
              >
                More
              </span>
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
}

export default Sidenav;
