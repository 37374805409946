import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Input, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import AppConstants from "../../utils/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  getResults,
  getTasks,
  resetResults,
} from "../../redux/slices/SearchSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  getActiveProjetAndTask,
  setProjectTasks,
} from "../../redux/slices/ProjectSlice";

const SearchModal = ({ isOpen, close, values }) => {
  const { results } = useSelector((state) => state.search);
  const [searchVal, setSearchVal] = values;
  const [filter, setFilter] = useState({
    projects: false,
    tasks: false,
    users: false,
  });
  const searchString = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchString?.current?.input?.value !== "") {
      let filters = [];
      if (filter.projects) filters.push("project");
      if (filter.tasks) filters.push("task");
      if (filter.users) filters.push("assignee");
      dispatch(
        getResults({ search: searchString?.current?.input?.value, filters })
      );
    }
  }, [filter]);

  useEffect(() => {
    if (isOpen && searchString.current) {
      searchString.current.focus();
    }
  }, [isOpen]);

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
    let search = e.target.value;
    let filters = [];
    if (filter.projects) filters.push("project");
    if (filter.tasks) filters.push("task");
    if (filter.users) filters.push("assignee");
    dispatch(getResults({ search, filters }));
  };

  const handleOnTaskClick = (task) => {
    dispatch(
      getActiveProjetAndTask({
        projectId: task.project,
        taskId: task._id,
      })
    );
  };

  const handleClose = () => {
    dispatch(resetResults());
    setSearchVal("");
    close();
  };

  const handleUser = (user) => {
    dispatch(getTasks(user._id));
    handleClose();
    navigate("user-tasks");
  };

  return (
    <div>
      <Modal
        style={{ top: 0 }}
        width="500px"
        mask={false}
        title={
          <Input
            className="header-search"
            placeholder="Search"
            ref={searchString}
            value={searchVal}
            prefix={<SearchOutlined />}
            suffix={
              searchVal ? (
                <CloseCircleOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(getResults({ search: "", filters: [] }));
                    setSearchVal("");
                  }}
                />
              ) : null
            }
            onChange={(e) => handleSearch(e)}
            // onPressEnter={() => {
            //   close();
            //   navigate("search");
            // }}
            autoFocus
          />
        }
        closable={false}
        className="search-modal"
        open={isOpen}
        footer={null}
        onCancel={() => {
          handleClose();
        }}
      >
        <div className="search-container">
          <div>
            <h5 style={{ fontWeight: 700, color: "#000" }}>Add filters:</h5>
            <div className="d-flex align-center filter-tags">
              <div
                className="filter-item"
                onClick={() => {
                  setFilter({ ...filter, projects: !filter.projects });
                }}
                style={
                  filter.projects
                    ? {
                        backgroundColor: "#1e56ca",
                        color: "#fff",
                        border: "none",
                      }
                    : {}
                }
              >
                Projects
              </div>
              <div
                className="filter-item"
                onClick={() => {
                  setFilter({ ...filter, tasks: !filter.tasks });
                }}
                style={
                  filter.tasks
                    ? {
                        backgroundColor: "#1e56ca",
                        color: "#fff",
                        border: "none",
                      }
                    : {}
                }
              >
                Tasks
              </div>
              <div
                className="filter-item"
                onClick={() => {
                  setFilter({ ...filter, users: !filter.users });
                }}
                style={
                  filter.users
                    ? {
                        backgroundColor: "#1e56ca",
                        color: "#fff",
                        border: "none",
                      }
                    : {}
                }
              >
                Users
              </div>
            </div>
          </div>
          {results?.projects?.length ? (
            <div style={{ marginBottom: "20px" }}>
              <div className="d-flex align-center">
                <h5 style={{ paddingRight: "3px", fontWeight: 700 }}>
                  Projects
                </h5>
                <hr style={{ width: "100%", height: 0 }}></hr>
              </div>
              <div>
                {results?.projects?.map((project) => (
                  <Link
                    to={`/project/${project?._id}/board`}
                    onClick={(e) => {
                      handleClose();
                      dispatch(setProjectTasks([]));
                    }}
                  >
                    <div className="searched-item d-flex align-center justify-space-between">
                      <div className=" d-flex align-center">
                        <p
                          className={
                            project?.type == "Regular"
                              ? "primary-highlight"
                              : "secondary-highlight"
                          }
                          style={{ minWidth: "80px", marginLeft: 0 }}
                        >
                          {project?.type}
                        </p>
                        {/* {project.name} */}
                        <p style={{ marginLeft: 10 }}>{project.name}</p>
                      </div>
                      <Avatar.Group maxCount={2} maxPopoverTrigger="">
                        {project?.team &&
                          project?.team?.map((member) => (
                            // <Popover content={() => handleProfileContent(member)}>
                            <Avatar
                              alt=""
                              size="small"
                              className="avatar-text-img"
                              src={
                                member?.isUserProfileUploaded
                                  ? `${AppConstants.BUCKET_URL}` +
                                    member?.email?.replace("@", "%40")
                                  : ""
                              }
                            >
                              {member?.name.slice(0, 1)}
                            </Avatar>
                            // </Popover>
                          ))}
                      </Avatar.Group>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : null}
          {results?.tasks?.length ? (
            <div style={{ marginBottom: "20px" }}>
              <div className="d-flex align-center">
                <h5 style={{ paddingRight: "3px", fontWeight: 700 }}>Tasks</h5>
                <hr style={{ width: "100%", height: 0 }}></hr>
              </div>
              <div>
                {results?.tasks?.map((task) => (
                  <Link
                    to={`/project/${task.project}/board`}
                    style={{ fontWeight: 600, color: "#000" }}
                    onClick={(e) => {
                      handleClose();
                      handleOnTaskClick(task);
                    }}
                  >
                    <div className="searched-item d-flex align-center">
                      <h5
                        style={{
                          minWidth: "40px",
                          margin: 0,
                          fontWeight: 700,
                          color: "#AA145E",
                        }}
                      >
                        {task?.ticketId}
                      </h5>
                      <p>{task.name} </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : null}
          {results?.users?.length ? (
            <div style={{ marginBottom: "20px" }}>
              <div className="d-flex align-center">
                <h5 style={{ paddingRight: "3px", fontWeight: 700 }}>Users</h5>
                <hr style={{ width: "100%", height: 0 }}></hr>
              </div>
              <div>
                {results?.users?.map((user) => (
                  <div
                    className="searched-item d-flex align-center"
                    onClick={(e) => handleUser(user)}
                  >
                    <Avatar
                      alt=""
                      size="small"
                      className="avatar-text-img"
                      src={
                        user?.isUserProfileUploaded
                          ? `${AppConstants.BUCKET_URL}` +
                            user?.email?.replace("@", "%40")
                          : ""
                      }
                    >
                      {user?.name.slice(0, 1)}
                    </Avatar>
                    <div className="d-flex align-center">
                      <p style={{ marginLeft: "10px" }}>{user.name}</p>
                      <p style={{ color: "#808080", marginLeft: "10px" }}>
                        {user.email}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default SearchModal;
