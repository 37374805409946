import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  results: [],
  user: {},
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    getResults: () => {},
    setResults: (state, action) => {
      state.results = action.payload;
    },
    resetResults: (state, action) => {
      state.results = [];
    },
    getTasks: () => {},
    setUserTasks: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { getResults, setResults, getTasks, resetResults, setUserTasks } =
  searchSlice.actions;
export default searchSlice.reducer;
