import agent from "../../agent";
import { takeLatest, put, call, fork } from "redux-saga/effects";
import showMessage from "../../utils/messageUtil";
import {
  getResults,
  getTasks,
  setResults,
  setUserTasks,
} from "../slices/SearchSlice";

function* getSearchResults(data) {
  const response = yield agent.Company.searchResults(
    data.payload.search,
    data.payload.filters
  );
  yield put(setResults(response.body));
}

function* tasksByUserId(data) {
  const response = yield agent.Company.getTasksByUserId(data.payload);
  yield put(setUserTasks(response.body));
}

function* search() {
  yield takeLatest(getTasks.type, tasksByUserId);
  yield takeLatest(getResults.type, getSearchResults);
}

export const searchSaga = [fork(search)];
