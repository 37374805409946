import { Avatar, Popover, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ClockCircleOutlined,
  DoubleLeftOutlined,
  MailOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import AppConstants from "../../utils/AppConstants";
import { Link } from "react-router-dom";
import { getActiveProjetAndTask } from "../../redux/slices/ProjectSlice";
import noDataImage from "../../assets/images/no-data-found.png";

const UserTasks = () => {
  const { user } = useSelector((state) => state.search);
  const [tableDataSource, setTableDataSource] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let tableData = [];
    user?.userTasks?.length &&
      user?.userTasks?.forEach((data) => {
        tableData.push({
          key: data._id,
          name: data.name,
          ticketId: data.ticketId,
          projectName: data.project,
          createdBy: {
            name: data.createdBy?.name,
            isProfile: data.createdBy?.isUserProfileUploaded,
            email: data.createdBy?.email,
            src: data.createdBy?.isUserProfileUploaded
              ? `${AppConstants.BUCKET_URL}` +
                data.createdBy?.email?.replace("@", "%40")
              : "",
          },
          accountableAssignee: {
            name: data.accountableAssignee?.name,
            isProfile: data.accountableAssignee?.isUserProfileUploaded,
            email: data.accountableAssignee?.email,
            src: data.accountableAssignee?.isUserProfileUploaded
              ? `${AppConstants.BUCKET_URL}` +
                data.accountableAssignee?.email?.replace("@", "%40")
              : "",
          },
          status: data.status,
          priority: data.priority,
          createdAt: moment(data.createdAt).format("MMM DD, YYYY"),
          endDate: data.endDate
            ? moment(data.endDate).format("MMM DD, YYYY")
            : "NA",
        });
      });
    setTableDataSource(tableData);
  }, [user]);

  const handleProfileContent = (user) => {
    return (
      <div>
        {user?.isProfile ? (
          <img src={user.src} alt="" />
        ) : (
          <div className="d-flex align-center justify-center theme-bg-color-primary name-letter-container">
            {user.name.slice(0, 1)}
          </div>
        )}

        <div className="popup-details">
          <h5>{user?.name}</h5>
          <div className="d-flex align-center">
            <MailOutlined />
            <p>{user?.email}</p>
          </div>
          <div className="d-flex align-center">
            <ClockCircleOutlined />
            <p>{moment(new Date()).format("h:mm A")} local time</p>
          </div>
        </div>
      </div>
    );
  };

  const handleOnTaskClick = (task) => {
    dispatch(
      getActiveProjetAndTask({
        projectId: task.projectName._id,
        taskId: task.key,
      })
    );
  };
  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      width: "280px",
      render: (text, record) => (
        <h5>
          <Link
            to={`/project/${record.projectName?._id}/board`}
            style={{ fontWeight: 600, color: "#000" }}
            onClick={(e) => handleOnTaskClick(record)}
          >
            <td> {text.length <= 35 ? text : `${text.slice(0, 35)}...`}</td>
          </Link>
        </h5>
      ),
    },
    {
      title: "Ticket ID",
      dataIndex: "ticketId",
      key: "ticketId",
      width: "100px",
      render: (text) => (
        <h5
          style={{
            margin: "0",
            fontWeight: 600,
            color: "#AA145E",
          }}
        >
          {text}
        </h5>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: "100px",
      render: (text) =>
        text ? (
          <div>
            <Tooltip title={`${text} Priority`}>
              {text === "High" ? (
                <DoubleLeftOutlined className="priority-high" />
              ) : text === "Medium" ? (
                <PauseOutlined className="priority-medium" />
              ) : text === "Low" ? (
                <DoubleLeftOutlined className="priority-low" />
              ) : null}
            </Tooltip>
          </div>
        ) : null,
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      width: "280px",
      render: (text) => (
        <h5>
          <Tooltip placement="bottom" title={text?.name}>
            {text?.name?.length <= 35
              ? text.name
              : `${text?.name?.slice(0, 35)}...`}
          </Tooltip>
        </h5>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "200px",
      render: (info) => (
        <div>
          <Popover content={() => handleProfileContent(info)}>
            <Avatar
              className="avatar-text-img"
              src={info?.isProfile ? info?.src : ""}
            >
              {info?.name?.slice(0, 1)}
            </Avatar>
            &nbsp; &nbsp;{info?.name}
          </Popover>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "100px",
      render: (text) => (
        <p
          className={
            text == AppConstants.DONE
              ? "done-highlight"
              : text == AppConstants.ON_HOLD
              ? "hold-highlight"
              : text == AppConstants.IN_PROGRESS
              ? "progress-highlight"
              : "todo-highlight"
          }
        >
          {text}
        </p>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "120px",
    },
  ];

  return (
    <>
      <div>
        <h1
          className="theme-color-primary page-heading"
          style={{ marginBottom: "30px" }}
        >
          {user?.user?.name ? `${user?.user?.name}'s` : "User"} Tasks
        </h1>
        {tableDataSource.length ? (
          <Table
            scroll={{
              x: 500,
              y: 550,
            }}
            dataSource={tableDataSource}
            columns={columns}
            pagination={false}
            bordered
          />
        ) : (
          <div
            className="d-flex align-center justify-center"
            style={{ padding: "50px" }}
          >
            <img src={noDataImage} alt="empty" style={{ width: "500px" }} />
          </div>
        )}
      </div>
    </>
  );
};

export default UserTasks;
