import { Button, Input, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const AddCategoryModal = ({ isOpen, handleClose, handleConfirm }) => {
  const { categoryNames } = useSelector((state) => state.asset);

  const [categoryVal, setCategoryVal] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorStr, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const onChangeName = (name) => {
    if (
      name.trim().length == 0 ||
      categoryNames.includes(name.trim().toLowerCase())
    ) {
      let err =
        name.trim().length > 0
          ? "Category already present"
          : "Category name required";
      setIsDisabled(true);
      setError(err);
      setShowError(true);
    } else {
      setShowError(false);
      setIsDisabled(false);
    }
    setCategoryVal(name);
  };

  const resetModal = () => {
    setIsDisabled(true);
    setError("");
    setShowError(false);
    setCategoryVal("");
  };

  const handleCloseAndReset = () => {
    resetModal();
    handleClose();
  };
  return (
    <Modal
      className="delete-modal"
      title="Add Category"
      centered
      closable={false}
      open={isOpen}
      onCancel={handleCloseAndReset}
      footer={null}
      style={{ width: "fit-content" }}
      maskClosable={false}
    >
      <div style={{ minWidth: "300px" }}>
        <Input
          placeholder="Enter Category Name"
          value={categoryVal}
          onChange={(e) => onChangeName(e.target.value)}
        />
        <div
          style={{
            display: showError ? "block" : "none",
            color: "red",
          }}
        >
          {errorStr}
        </div>
        <div
          className="d-flex align-center justify-space-between"
          style={{ marginTop: "20px" }}
        >
          <Button onClick={handleCloseAndReset}>Cancel</Button>
          <Button
            type="primary"
            disabled={isDisabled}
            onClick={() => {
              handleCloseAndReset();
              handleConfirm(categoryVal.trim());
            }}
            className="theme-bg-gradient"
            style={{ color: "#fff" }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCategoryModal;
