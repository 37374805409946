import { CheckOutlined, CloseOutlined, MailOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyTasks from "../components/dashboard/MyTasks";
import TeamMembersCard from "../components/dashboard/TeamMembersCard";
import UploadProfileModal from "../components/common/UploadProfileModal";
import MyProjects from "../components/dashboard/MyProjects";
import { pencil } from "../components/layout/SVG";
import { uploadUserProfile } from "../redux/slices/UserSlice";
import AppConstants from "../utils/AppConstants";
import MyAssets from "../components/asset components/MyAssets";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { loginUser } = useSelector((state) => state.login);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isEditAbout, setIsEditAbout] = useState(false);
  const [aboutVal, setAboutVal] = useState("");

  useEffect(() => {
    loginUser?.about && setAboutVal(loginUser.about);
  }, [loginUser]);

  const handleUpdateAbout = () => {
    dispatch(
      uploadUserProfile({
        userId: loginUser?._id,
        profile: { about: aboutVal },
      })
    );
    setIsEditAbout(false);
  };

  return (
    <div className="profile-container" style={{ margin: "50px" }}>
      <UploadProfileModal
        isOpen={isUploadModal}
        handleClose={() => {
          setIsUploadModal(false);
        }}
      />
      <Row gutter={[24, 0]}>
        <Col xs={24} md={4}>
          <div>
            {/* <Badge count={5}> */}
            <Avatar
              size={150}
              style={{
                fontSize: "100px",
                textTransform: "capitalize",
                backgroundColor: "#0037a2",
              }}
              src={
                loginUser?.isUserProfileUploaded
                  ? `${AppConstants.BUCKET_URL}` +
                    loginUser?.email?.replace("@", "%40")
                  : ""
              }
            >
              {loginUser?.name?.slice(0, 1)}
            </Avatar>
            {/* </Badge> */}
          </div>
        </Col>
        <Col xs={24} md={16}>
          <div className="d-flex align-center h-full">
            <div>
              <h5 style={{ fontSize: "32px", fontWeight: 400, margin: 0 }}>
                {loginUser?.name}
              </h5>
              <div className="d-flex align-center">
                <MailOutlined style={{ fontSize: "16px", color: "#6d6e6f" }} />
                <p
                  style={{
                    margin: "0 10px",
                    fontSize: "16px",
                    color: "#6d6e6f",
                    cursor: "default",
                  }}
                >
                  {loginUser?.email}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} md={4}>
          <div className="d-flex align-end justify-flex-end h-full">
            <Button
              onClick={() => setIsUploadModal(true)}
              className="theme-bg-gradient"
              type="primary"
            >
              Edit Profile
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 0]} style={{ margin: "30px 0" }}>
        <Col xs={24} md={24}>
          <Card>
            <div>
              <div className="d-flex align-center justify-space-between">
                <h1 className="theme-color-primary page-heading">About me</h1>
                {isEditAbout ? (
                  <div>
                    <Button onClick={handleUpdateAbout} type="link">
                      <CheckOutlined />
                    </Button>
                    <Button
                      onClick={() => {
                        setAboutVal(loginUser?.about);
                        setIsEditAbout(false);
                      }}
                      type="link"
                      style={{ color: "red", padding: 0 }}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                ) : (
                  <Button onClick={() => setIsEditAbout(true)} type="link">
                    {pencil}
                  </Button>
                )}
              </div>

              {isEditAbout ? (
                <div>
                  <Input.TextArea
                    style={{
                      marginBottom: 10,
                    }}
                    placeholder=""
                    defaultValue={aboutVal || " "}
                    // onPressEnter={(e) => handleOnEnterPressProjectDescription(e)}
                    onChange={(e) => setAboutVal(e.target.value)}
                  />
                </div>
              ) : (
                <p style={{ color: "#6d6e6f" }}>
                  {aboutVal || "Use this space to tell people about yourself"}
                </p>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]} style={{ margin: "30px 0" }}>
        <Col xs={24} md={10}>
          <Card>
            <TeamMembersCard />
          </Card>
        </Col>
        <Col xs={24} md={13} offset={1}>
          <Card>
            <MyProjects />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24}>
          <Card>
            <MyAssets />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserProfile;
