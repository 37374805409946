import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectNamesList,
  getProjects,
} from "../../redux/slices/ProjectSlice";
import NewTaskModal from "./NewTaskModal";
import NewProjectModal from "./NewProjectModal";
import NewInviteModal from "./NewInviteModal";
import { getMe, setNavigateFlag } from "../../redux/slices/LoginSlice";
import {
  addWebsocketNotifications,
  getActiveCompany,
  getAllUsers,
  getNotifications,
  setActiveCompanyProfile,
  setActiveUserProfile,
  getDepartments,
} from "../../redux/slices/UserSlice";
import TaskDrawer from "./TaskDrawer";
import AddMemberModal from "./AddMemberModal";
import AppConstants from "../../utils/AppConstants";

const { Header: AntHeader, Content, Sider, Footer } = Layout;

function Main({ children }) {
  const { loginUser, navigateFlag } = useSelector((state) => state.login);
  const { activeCompany } = useSelector((state) => state.user);

  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  const { isNewTaskModalOpen, isNewProjectModalOpen, isNewInviteModalOpen } =
    useSelector((state) => state.createButton);

  const { showTaskDrawer, addMemberModal } = useSelector((state) => state.task);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  // pathname = pathname.replace("/", "");

  useEffect(() => {
    if (navigateFlag) {
      dispatch(setNavigateFlag(false));
      navigate("/");
    }
  }, [navigateFlag]);

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  useEffect(() => {
    if (Object.keys(loginUser).length === 0) {
      dispatch(getMe());
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    if (loginUser) {
      dispatch(
        setActiveUserProfile(
          loginUser?.isUserProfileUploaded
            ? `${AppConstants.BUCKET_URL}` +
                loginUser?.email?.replace("@", "%40")
            : ""
        )
      );
    }
  }, [loginUser]);

  //For Websocket
  useEffect(() => {
    if (Object.keys(loginUser).length !== 0) {
      // Establish a WebSocket connection
      const socket = new WebSocket(
        `${AppConstants.WEBSOCKET_URL}/websocket?userID=${loginUser._id}`
      );

      socket.onopen = () => {
        console.log("WebSocket connected");
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        // Pushing the notification to notification list
        if (Object.keys(message).length !== 0)
          dispatch(addWebsocketNotifications(message));
      };

      // close the WebSocket when the component unmounts
      return () => {
        socket.close();
        console.log("WebSocket disconnected for user");
      };
    }
  }, [loginUser]);

  useEffect(() => {
    if (activeCompany) {
      dispatch(
        setActiveCompanyProfile(
          `https://${AppConstants.BUCKET_URL}.s3.amazonaws.com/company_logo/` +
            activeCompany?.name?.replace(" ", "_").toLowerCase()
        )
      );
    }
  }, [activeCompany]);

  useEffect(() => {
    if (
      activeCompany &&
      Object.keys(activeCompany).length === 0 &&
      loginUser?.selectedCompany
    ) {
      dispatch(getActiveCompany(loginUser.selectedCompany));
    }
  }, [activeCompany, loginUser]);

  useEffect(() => {
    if (activeCompany?._id) {
      dispatch(getDepartments(activeCompany?._id));
      dispatch(getProjects(activeCompany?._id));
      dispatch(getProjectNamesList());
      dispatch(getNotifications(activeCompany?._id));
    }
  }, [activeCompany]);

  return (
    <>
      {isNewTaskModalOpen ? <NewTaskModal /> : null}

      {isNewProjectModalOpen ? <NewProjectModal /> : null}
      {isNewInviteModalOpen ? <NewInviteModal /> : null}
      {showTaskDrawer ? <TaskDrawer /> : null}
      {addMemberModal ? <AddMemberModal /> : null}
      <Layout
        className={`layout-dashboard ${
          pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
      >
        <Drawer
          title={false}
          placement={placement === "right" ? "left" : "right"}
          closable={false}
          onClose={() => setVisible(false)}
          open={visible}
          key={placement === "right" ? "left" : "right"}
          width={250}
          className={`drawer-sidebar ${
            pathname === "rtl" ? "drawer-sidebar-rtl" : ""
          } `}
        >
          <Layout
            className={`layout-dashboard ${
              pathname === "rtl" ? "layout-dashboard-rtl" : ""
            }`}
          >
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${
                sidenavType === "#fff" ? "active-route" : ""
              }`}
              style={{
                background: sidenavType,
              }}
            >
              <Sidenav />
            </Sider>
          </Layout>
        </Drawer>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {}}
          trigger={null}
          width={250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${
            sidenavType === "#fff" ? "active-route" : ""
          }`}
          style={{ background: sidenavType }}
        >
          <Sidenav color={sidenavColor} />
        </Sider>
        <Layout>
          {fixed ? (
            <Affix>
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                  onPress={openDrawer}
                  name={pathname}
                  subName={pathname}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                />
              </AntHeader>
            </Affix>
          ) : (
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          )}
          <Content className="content-ant">{children}</Content>
          {/* <Footer /> */}
          <Footer
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p className="copyright">
              Copyright © 2024 Grappler by Innogent Technologies
            </p>
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}

export default Main;
