import { Avatar, Button, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowAddMemberModalOpen } from "../../redux/slices/TaskSlice";
import { addTeamMembers } from "../../redux/slices/ProjectSlice";
import { UserOutlined } from "@ant-design/icons";
import AppConstants from "../../utils/AppConstants";

function AddMemberModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);

  const { addMemberModal } = useSelector((state) => state.task);
  const { activeCompany } = useSelector((state) => state.user);
  const { activeProject } = useSelector((state) => state.project);

  const dispatch = useDispatch();

  useEffect(() => {
    let users = [];
    if (activeProject?.team?.length > 0) {
      let teamList = activeProject.team;

      if (activeCompany?.users?.length > 0) {
        const result = activeCompany.users.filter(
          (user) => !teamList.some((data) => user._id === data._id)
        );

        result.forEach((res) => {
          let element = (
            <div>
              <Avatar
                alt=""
                size={25}
                className="avatar-text-img"
                style={{
                  marginRight: "10px",
                }}
                src={
                  res.isUserProfileUploaded
                    ? `${AppConstants.BUCKET_URL}` +
                      res?.email.replace("@", "%40")
                    : ""
                }
              >
                {res.name?.slice(0, 1)}
              </Avatar>
              {res.name}
            </div>
          );
          const resObj = { label: element, value: res._id, name: res.name };
          users.push(resObj);
        });
        setUsersDropdown(users);
      }
    }
  }, [activeCompany?.users, activeProject?.team]);

  useEffect(() => {
    if (addMemberModal) {
      setIsModalOpen(addMemberModal);
    }
  }, [addMemberModal]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    dispatch(setShowAddMemberModalOpen(false));
  };

  const handleOnOk = () => {
    if (userList?.length > 0) {
      dispatch(
        addTeamMembers({
          projectId: activeProject._id,
          addedUsers: { ids: userList },
        })
      );
      setIsModalOpen(false);
      dispatch(setShowAddMemberModalOpen(false));
    }
  };

  const handleOnChangeTeamMember = (e) => {
    setUserList(e);
  };

  return (
    <>
      <Modal
        title={activeProject?.name}
        centered
        open={isModalOpen}
        footer={null}
        // okText="Add"
        // onOk={handleOnOk}
        onCancel={handleCloseModal}
        // okButtonProps={
        //   userList?.length > 0 ? { disabled: false } : { disabled: true }
        // }
      >
        <div>
          <div>
            <p>
              Add member will get access to given {activeProject?.name} project.
            </p>
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="Select users"
              style={{ width: "100%" }}
              options={usersDropdown}
              mode="multiple"
              allowClear
              onChange={(e) => handleOnChangeTeamMember(e)}
              filterOption={(inputValue, option) => {
                if (
                  option.name.toLowerCase().includes(inputValue.toLowerCase())
                ) {
                  return true;
                } else return false;
              }}
            ></Select>
          </div>
          <div
            className="d-flex align-center justify-space-between"
            style={{ marginTop: "20px" }}
          >
            <Button htmlType="button" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="theme-bg-gradient"
              style={{ marginLeft: "10px", color: "#fff" }}
              onClick={handleOnOk}
              disabled={userList?.length > 0 ? false : true}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default AddMemberModal;
