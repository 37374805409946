import {
  Avatar,
  Button,
  Input,
  Menu,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import "./../assets/styles/main.css";
import AppConstants from "../utils/AppConstants";
import noDataImg from "../assets/images/no-data-found.png";

import {
  getTasksByProject,
  setProjectTasks,
  setView,
} from "../redux/slices/ProjectSlice";
import {
  FilterOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  MailOutlined,
  ClockCircleOutlined,
  DoubleLeftOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { deletebtn, plusIcon } from "../components/layout/SVG";
import {
  deleteTask,
  setDefaultAddTaskPayload,
  getSelectedTask,
} from "../redux/slices/TaskSlice";
import {
  setGlobalAddTask,
  setShowNewTaskModalOpen,
} from "../redux/slices/CreateButtonSlice";
import { convertFromUtcToLocaleCustom } from "../utils/dateUtil";
import ConfirmModal from "../components/common/ConfirmModal";
import moment from "moment";
import ViewNameModal from "../components/common/ViewNameModal";
import {
  addView,
  deleteView,
  getViews,
  updateView,
} from "../redux/slices/ViewSlice";

const List = () => {
  const { activeProject, projectTasks } = useSelector((state) => state.project);
  const { loginUser } = useSelector((state) => state.login);
  const { showTaskDrawer } = useSelector((state) => state.task);
  const { views } = useSelector((state) => state.view);
  const location = useLocation();
  const [toggleFilterDropdown, setToggleFilterDropdown] = useState(false);
  const [toggleSortDropdown, setToggleSortDropdown] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewDeleteOpen, setIsViewDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [delTask, setDelTask] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedView, setSelectedView] = useState(null);
  const [isView, setIsView] = useState(false);

  const [filters, setFilters] = useState({
    selection: [],
  });
  const [sort, setSort] = useState({
    order: "Asc",
    column: "",
  });
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(setView("list"));
  }, []);

  useEffect(() => {
    if (Object.keys(activeProject).length !== 0) {
      dispatch(getTasksByProject({ projectId: id, view: "list" }));
      let path = location.pathname.split("/");
      if (path[3].toString() === "views") {
        dispatch(getViews(activeProject._id));
        setIsView(true);
      } else {
        setSelectedView(null);
        setIsView(false);
        resetState();
      }
    }
  }, [activeProject, location.pathname]);

  useEffect(() => {
    transformDataForGrid(projectTasks);
  }, [projectTasks]);

  useEffect(() => {
    if (views[0]?._id && listData.length > 0 && isView) {
      if (!selectedView) {
        handleOnViewSelect(views[0]);
      } else {
        let view = views.find((view) => view._id === selectedView);
        handleOnViewSelect(view);
      }
    }
  }, [views, listData]);

  const resetState = () => {
    let updateFilter = { ...filters };
    updateFilter.selection = [];

    setFilters(updateFilter);
    setSort({ ...sort, order: true, column: "" });
  };

  const handleOnViewSelect = (view) => {
    setSelectedView(view._id);
    const config = JSON.parse(view.config);
    setFilters(config.filters);
    setSort(config.sort);
    handleSearchAndCondition(config.filters, config.sort);
  };

  const transformDataForGrid = () => {
    let tasks = [];
    projectTasks?.forEach((projectTask) => {
      let taskList = [];
      projectTask.children.forEach((task) => {
        let assignees =
          task.assignee?.length > 0
            ? task.assignee.map((assignee) => assignee.name)
            : [];
        let child = {
          key: task._id,
          taskName: task.name,
          ticketId: task.ticketId,
          tags: task.tags,
          assignee: assignees.toString(),
          accountableAssignee: task.accountableAssignee?.name,
          createdBy: task.createdBy.name,
          createdAt: task.createdAt
            ? convertFromUtcToLocaleCustom(task.createdAt)
            : "NA",
          endDate: task.endDate
            ? convertFromUtcToLocaleCustom(task.endDate)
            : "NA",
          status: task.status,
          priority: task.priority,
        };
        taskList.push(child);
      });
      let task = { ...projectTask };
      task.children = taskList;
      tasks.push(task);
    });
    setListData([...tasks]);
    setData([...tasks]);
    if (tasks.length > 0) expandAll([...tasks]);
  };

  const handleSearchAndCondition = (filters, sort) => {
    let records = [];
    let newData = [...listData];
    if (filters.selection.length > 0) {
      filters.selection.forEach((filter) => {
        if (filter.filter !== "") {
          if (filter.column === "tags") {
            newData.forEach((record) => {
              let foundChild = record.children?.filter((child) =>
                child.tags.some((tag) =>
                  tag.name.toLowerCase().includes(filter.filter.toLowerCase())
                )
              );
              if (foundChild.length > 0) {
                let foundRecord = { ...record };
                foundRecord.children = foundChild;
                records.push(foundRecord);
              }
            });
          } else {
            newData.forEach((record) => {
              let foundChild = record.children?.filter((child) =>
                child[filter.column]
                  ?.toLowerCase()
                  .includes(filter.filter.toLowerCase())
              );
              if (foundChild.length > 0) {
                let foundRecord = { ...record };
                foundRecord.children = foundChild;
                records.push(foundRecord);
              }
            });
          }

          newData = records;
          records = [];
        }
      });
      newData.length > 0 ? setData([...newData]) : setData([...newData]);
    }
    sortFields(sort, newData);
    setToggleFilterDropdown(false);
  };

  const handleSearchOrCondition = () => {
    let records = [];
    let recordMap = new Map();
    filters.selection.forEach((filter) => {
      listData.forEach((record) => {
        let foundChild = record.children?.filter((child) =>
          child[filter.column]
            .toLowerCase()
            .includes(filter.filter.toLowerCase())
        );
        if (foundChild.length > 0) {
          let foundRecord = { ...record };
          foundRecord.children = foundChild;
          if (!recordMap.get(record.taskName)) {
            recordMap.set(record.taskName, new Set(foundChild));
          } else {
            foundChild.forEach(
              recordMap.get(record.taskName).add,
              recordMap.get(record.taskName)
            );
          }
        }
      });
    });
    records = Array.from(recordMap, ([task, value], index) => ({
      key: index,
      taskName: task,
      children: Array.from(value),
    }));
    records.length > 0 ? setData([...records]) : setData([...listData]);
    setToggleFilterDropdown(false);
  };

  const sortFields = (sort, data) => {
    let records = [];
    if (sort.column !== "") {
      data.forEach((record) => {
        let childs = [...record.children];
        childs?.sort((a, b) => {
          let fa = a[sort.column]?.toLowerCase(),
            fb = b[sort.column]?.toLowerCase();
          if (sort.order) {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            if (fb < fa) {
              return -1;
            }
            if (fb > fa) {
              return 1;
            }
            return 0;
          }
        });

        let sortedRecord = { ...record };
        sortedRecord.children = childs;
        records.push(sortedRecord);
      });
      setData(records);
    }
    setToggleSortDropdown(false);
  };

  const handleReset = () => {
    let updateFilter = { ...filters };
    updateFilter.selection = [];

    setFilters(updateFilter);
    setData([...listData]);
    sortFields(sort, listData);
  };

  const handleFilter = (index, e) => {
    let updatedFilter = { ...filters };
    updatedFilter.selection[index].filter = e.target.value;
    setFilters(updatedFilter);
  };

  const handleColumn = (index, e) => {
    let updateFilter = { ...filters };
    updateFilter.selection[index].column = e;
    setFilters(updateFilter);
  };

  const removeField = (index) => {
    if (filters.selection.length !== 1) {
      let updateFilter = { ...filters };
      updateFilter.selection.splice(index, 1);
      setFilters(updateFilter);
    } else {
      handleReset();
    }
  };

  const removeSort = () => {
    setSort({ ...sort, order: true, column: "" });
    setData(listData);
    handleSearchAndCondition(filters, { ...sort, order: true, column: "" });
    setToggleSortDropdown(false);
  };

  const addField = () => {
    let newFilter = { ...filters };
    newFilter.selection.push({
      filter: "",
      column: "",
      type: "text",
    });
    setFilters(newFilter);
  };

  const handleShowTask = (editTask) => {
    dispatch(getSelectedTask(editTask?.key));
  };

  const handleDeleteTask = (task) => {
    let updatedProjectTasks = [];
    dispatch(deleteTask(delTask.key));
    projectTasks.forEach((projectTask) => {
      if (delTask?.status && projectTask?.key === delTask?.status) {
        let newProjectTasks = projectTask.children?.filter(
          (task1) => task1?._id !== delTask.key
        );
        let updatedProjectTask = { ...projectTask };
        updatedProjectTasks.push(updatedProjectTask);
        updatedProjectTask.children = newProjectTasks;
      } else {
        let updatedProjectTask = { ...projectTask };
        updatedProjectTasks.push(updatedProjectTask);
      }
    });
    dispatch(setProjectTasks(updatedProjectTasks));
    transformDataForGrid(updatedProjectTasks);
  };

  const showAddTaskModal = (status) => {
    dispatch(
      setDefaultAddTaskPayload({
        status: status,
        project: id,
      })
    );
    dispatch(setGlobalAddTask(false));
    dispatch(setShowNewTaskModalOpen(true));
  };

  function handleAdminProject(record) {
    let value = false;
    projectTasks.forEach((projectTask) => {
      if (record?.status && projectTask?.key === record?.status) {
        projectTask.children.forEach((child) => {
          if (child._id === record.key) {
            if (child.createdBy?._id !== loginUser?._id) {
              value = true;
            } else {
              value = false;
            }
          }
        });
      }
    });
    return value;
  }

  function findCreatedBySrcByUserId(record) {
    let src = "";
    projectTasks.forEach((projectTask) => {
      if (record?.status && projectTask?.key === record?.status) {
        let task = projectTask.children.find((task) => task._id === record.key);
        src = task?.createdBy?.isUserProfileUploaded
          ? `${AppConstants.BUCKET_URL}` +
            task.createdBy?.email?.replace("@", "%40")
          : "";
        return;
      }
    });
    return src;
  }

  function findAccountableAssigneeByEmail(record) {
    let src = "";
    projectTasks.forEach((projectTask) => {
      if (record?.status && projectTask?.key === record?.status) {
        let task = projectTask.children.find((task) => task._id === record.key);
        src = task.accountableAssignee?.isUserProfileUploaded
          ? `${AppConstants.BUCKET_URL}` +
            task.accountableAssignee?.email?.replace("@", "%40")
          : "";
        return;
      }
    });
    return src;
  }

  function findAssigneesEmail(record) {
    let assignees = [];
    projectTasks.forEach((projectTask) => {
      if (record?.status && projectTask?.key === record?.status) {
        projectTask.children.forEach((child) => {
          if (child._id === record.key) {
            assignees = child.assignee;
          }
        });
      }
    });
    return assignees;
  }

  const filterList = [
    {
      label: "Task Name",
      value: "taskName",
    },
    {
      label: "Assignee",
      value: "assignee",
    },
    {
      label: "Accountable Assignee",
      value: "accountableAssignee",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "Priority",
      value: "priority",
    },
    {
      label: "Ticket ID",
      value: "ticketId",
    },
    { label: "Tags", value: "tags" },
  ];

  const handleProfileContent = (user) => {
    return (
      <div>
        {user?.isUserProfileUploaded ? (
          <img
            src={
              `${AppConstants.BUCKET_URL}` + user?.email?.replace("@", "%40")
            }
            alt=""
          />
        ) : (
          <div className="d-flex align-center justify-center theme-bg-color-primary name-letter-container">
            {user.name.slice(0, 1)}
          </div>
        )}
        <div className="popup-details">
          <h5>{user?.name}</h5>
          <div className="d-flex align-center">
            <MailOutlined />
            <p>{user?.email}</p>
          </div>
          <div className="d-flex align-center">
            <ClockCircleOutlined />
            <p>{moment(new Date()).format("h:mm A")} local time</p>
          </div>
          {/* <div className="d-flex flex-col align-center">
            <Button className="theme-bg-color-primary" type="primary">
              View Profile
            </Button>
            <Button>Send Message</Button>
          </div> */}
        </div>
      </div>
    );
  };

  const tagsContent = (info) => {
    return (
      <div className="d-flex alignCenter" style={{ padding: "5px" }}>
        {info?.map((tag, i) => {
          if (i > 2) {
            return (
              <Tooltip title={tag.name}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `${tag.color}`,
                    minWidth: "100px",
                  }}
                >
                  {tag.name?.length < 13
                    ? tag.name
                    : `${tag.name?.slice(0, 12)}...`}
                </p>
              </Tooltip>
            );
          }
        })}
      </div>
    );
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "taskName",
      key: "taskName",
      render: (_, record) => (
        <Tooltip
          placement="bottom"
          title={!record?.children ? record?.taskName : ""}
        >
          <td
            onClick={() => !record.children && handleShowTask(record)}
            style={{ cursor: "pointer" }}
          >
            {record?.taskName?.length <= 60
              ? record?.taskName
              : `${record?.taskName.slice(0, 60)}...`}
          </td>{" "}
        </Tooltip>
      ),
    },
    {
      title: "Ticket ID",
      dataIndex: "ticketId",
      key: "ticketId",
      render: (text) => (
        <h5
          style={{
            margin: "0",
            fontWeight: 600,
            color: "#AA145E",
          }}
        >
          {text}
        </h5>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "250px",
      render: (info) => (
        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          {info?.length > 4 ? (
            <>
              {" "}
              {info?.map((tag, i) => {
                if (i < 3) {
                  return (
                    <Tooltip title={tag.name}>
                      <p
                        className="primary-highlight"
                        style={{
                          margin: "5px",
                          backgroundColor: `${tag.color}`,
                          minWidth: "100px",
                        }}
                      >
                        {tag.name?.length < 13
                          ? tag.name
                          : `${tag.name?.slice(0, 12)}...`}
                      </p>
                    </Tooltip>
                  );
                }
              })}
              <Popover content={tagsContent(info)}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `#d8d8d8`,
                    minWidth: "100px",
                  }}
                >
                  +{info?.length - 3}
                </p>
              </Popover>
            </>
          ) : (
            info?.map((tag) => (
              <Tooltip title={tag.name}>
                <p
                  className="primary-highlight"
                  style={{
                    margin: "5px",
                    backgroundColor: `${tag.color}`,
                    minWidth: "100px",
                  }}
                >
                  {tag.name?.length < 13
                    ? tag.name
                    : `${tag.name?.slice(0, 12)}...`}
                </p>
              </Tooltip>
            ))
          )}
        </div>
      ),
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
      render: (_, record) => (
        <Avatar.Group maxCount={3}>
          {findAssigneesEmail(record).map((assignee) => {
            return (
              <Popover content={() => handleProfileContent(assignee)}>
                <Avatar
                  // size="large"
                  className="avatar-text-img"
                  src={
                    assignee?.isUserProfileUploaded
                      ? `${AppConstants.BUCKET_URL}` +
                        assignee?.email?.replace("@", "%40")
                      : ""
                  }
                >
                  {assignee?.name?.slice(0, 1)}
                </Avatar>
              </Popover>
            );
          })}
        </Avatar.Group>
      ),
    },
    // {
    //   title: "Accountable Assignee",
    //   dataIndex: "accountableAssignee",
    //   key: "accountableAssignee",
    //   render: (_, record) => (
    //     <Space size="start">
    //       {!record.children ? (
    //         <>
    //           <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
    //             {findAccountableAssigneeByEmail(record) !== "" ? (
    //               <Avatar
    //                 alt=""
    //                 // size="large"
    //                 className="avatar-text-img"
    //                 src={findAccountableAssigneeByEmail(record)}
    //               >
    //                 {record?.accountableAssignee?.slice(0, 1)}
    //               </Avatar>
    //             ) : null}
    //             {record?.accountableAssignee}
    //           </div>
    //         </>
    //       ) : null}
    //     </Space>
    //   ),
    // },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, record) => (
        <Space size="start">
          {!record.children ? (
            <>
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <Avatar
                  alt=""
                  // size="large"
                  className="avatar-text-img"
                  src={findCreatedBySrcByUserId(record)}
                >
                  {record?.createdBy.slice(0, 1)}
                </Avatar>
                {record?.createdBy}
              </div>
            </>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) =>
        text ? (
          <p
            className={
              text == AppConstants.TO_DO
                ? "todo-highlight"
                : text == AppConstants.IN_PROGRESS
                ? "progress-highlight"
                : text == AppConstants.DONE
                ? "done-highlight"
                : "hold-highlight"
            }
          >
            {text}
          </p>
        ) : null,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (text) =>
        text ? (
          <div style={{ textAlign: "center" }}>
            {text === "High" ? (
              <>
                <DoubleLeftOutlined className="priority-high" /> High
              </>
            ) : text === "Medium" ? (
              <>
                <PauseOutlined className="priority-medium" /> Medium
              </>
            ) : (
              <>
                <DoubleLeftOutlined className="priority-low" /> Low
              </>
            )}
          </div>
        ) : null,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space /* size="middle" */>
          {!record.children ? (
            <>
              {/* <p
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleOnEditTask(record);
                }}
              >
                {pencil}
              </p> */}
              {activeProject?.type === AppConstants.ADMIN &&
              handleAdminProject(record) ? null : (
                <p
                  onClick={() => {
                    setDelTask(record);
                    setIsDeleteOpen(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {deletebtn}
                </p>
              )}
            </>
          ) : (
            <p
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                showAddTaskModal(record.key);
              }}
            >
              <Tooltip title="Add Task" placement="right">
                {plusIcon}
              </Tooltip>
            </p>
          )}
        </Space>
      ),
    },
  ];

  const customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: "black" }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <CaretDownOutlined /> &nbsp;
        </a>
      );
    } else if (props.expandable || props.expandable?.length > 0) {
      return (
        <a
          style={{ color: "black" }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <CaretRightOutlined /> &nbsp;
        </a>
      );
    }
  };

  // Function to handle row click and expand/collapse the row
  const handleRowClick = (record) => {
    if (record.children) {
      const key = record.key;
      if (expandedRowKeys.includes(key)) {
        setExpandedRowKeys(expandedRowKeys.filter((k) => k !== key));
      } else {
        setExpandedRowKeys([...expandedRowKeys, key]);
      }
    }
  };

  const expandAll = (data) => {
    setExpandedRowKeys(data?.map((item) => item.key));
  };

  // Function to handle the "Expand All" button click
  const handleExpandAll = (data) => {
    if (expandedRowKeys.length === 0) {
      expandAll(data);
    } else {
      setExpandedRowKeys([]);
    }
  };

  // Custom row render to add click event
  const customRowRender = (record) => ({
    onClick: () => handleRowClick(record),
  });

  const removeEmptyFilters = () => {
    if (filters.selection?.length > 0) {
      let selections = filters.selection?.filter(
        (selection) => selection.filter !== ""
      );
      filters.selection = selections;
      setFilters(filters);
    }
  };

  const handleFilterChange = (newOpen) => {
    removeEmptyFilters();
    setToggleFilterDropdown(newOpen);
  };

  const handleSortChange = (newOpen) => {
    setToggleSortDropdown(newOpen);
  };

  const handleAddView = (viewName) => {
    if (filters.selection.length > 0 || sort.column !== "") {
      const config = {
        filters,
        sort,
      };
      const viewPayload = {
        name: viewName,
        projectId: activeProject._id,
        config: JSON.stringify(config),
      };
      dispatch(addView(viewPayload));
    }
  };

  const handleUpdateView = () => {
    const configuration = {
      filters,
      sort,
    };
    let updatedConfig = {
      config: JSON.stringify(configuration),
    };
    dispatch(updateView({ viewId: selectedView, updatedView: updatedConfig }));
  };

  const handleDeleteView = () => {
    if (views.length === 1) {
      resetState();
      setData([...listData]);
    }
    dispatch(deleteView(selectedView));
    setSelectedView(null);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {(isView && views.length > 0) || !isView ? (
        <>
          <ConfirmModal
            title="Are you sure you want to delete ?"
            isOpen={isDeleteOpen}
            handleClose={() => setIsDeleteOpen(false)}
            handleConfirm={handleDeleteTask}
          />
          <ConfirmModal
            title="Are you sure you want to delete ?"
            isOpen={isViewDeleteOpen}
            handleClose={() => setIsViewDeleteOpen(false)}
            handleConfirm={handleDeleteView}
          />
          <ViewNameModal
            title="Are you sure you want to delete ?"
            isOpen={isViewOpen}
            handleClose={() => setIsViewOpen(false)}
            handleConfirm={handleAddView}
          />
          {views?.length && isView ? (
            <div className="views-tab-container">
              <Menu
                mode="horizontal"
                style={{ marginBottom: "20px" }}
                selectedKeys={[selectedView]}
              >
                {views?.map((item) => {
                  return (
                    <Menu.Item
                      key={item._id}
                      // icon={item.icon}
                      onClick={(e) => handleOnViewSelect(item, e)}
                    >
                      {item.name}
                    </Menu.Item>
                  );
                })}
              </Menu>
            </div>
          ) : null}
          <div className="d-flex justify-space-between">
            <div>
              <Popover
                showArrow={false}
                content={
                  <div className="filter-dropdown">
                    {filters.selection?.map(function (select, index) {
                      return (
                        <div className="filter-field" key={index}>
                          <div>
                            <span
                              onClick={() => {
                                removeField(index);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              X &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <span className="">Filter by&nbsp;: </span>
                          </div>
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            options={filterList}
                            onChange={(e) => {
                              handleColumn(index, e);
                            }}
                            defaultValue={select.column ? select.column : null}
                            style={{
                              width: "150px",
                              marginLeft: "4px",
                            }}
                            placeholder={"Select..."}
                          />
                          <Input
                            key={index}
                            placeholder={`Filter...`}
                            value={select.filter}
                            onChange={(e) => {
                              handleFilter(index, e);
                            }}
                            style={{
                              width: "150px",
                              marginLeft: "4px",
                              height: "32px",
                            }}
                          />
                        </div>
                      );
                    })}
                    <Space
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          addField();
                        }}
                      >
                        Add Field
                      </Button>
                      <Button
                        onClick={() => {
                          handleReset();
                        }}
                        size="small"
                        style={{
                          width: 90,
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleSearchAndCondition(filters, sort);
                        }}
                        icon={<FilterOutlined />}
                        size="small"
                        style={{
                          width: 90,
                        }}
                      >
                        Apply
                      </Button>
                    </Space>
                  </div>
                }
                trigger="click"
                open={toggleFilterDropdown}
                onOpenChange={handleFilterChange}
              >
                <Button
                  style={{ margin: "0 10px" }}
                  type={
                    filters.selection?.length > 0 &&
                    filters.selection[0].filter !== ""
                      ? "primary"
                      : "default"
                  }
                >
                  {filters.selection?.length > 0 &&
                  filters.selection[0].filter !== ""
                    ? "FILTERED"
                    : "FILTER"}
                </Button>
              </Popover>

              <Popover
                showArrow={false}
                content={
                  <div className="filter-dropdown">
                    <div className="filter-field">
                      <div className="d-flex align-center">
                        <div className="d-flex align-center">
                          <div>
                            {/* <span
                      onClick={() => {
                        removeSort();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      X &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span> */}
                            <span className="">Sort by&nbsp;: </span>
                          </div>
                          <Switch
                            checkedChildren="Asc"
                            unCheckedChildren="Desc"
                            checked={sort.order}
                            style={{ margin: "5px" }}
                            onChange={(value) => {
                              setSort({ ...sort, order: value });
                            }}
                          />
                        </div>
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          options={filterList}
                          value={sort.column ? sort.column : null}
                          onChange={(value) => {
                            setSort({ ...sort, column: value });
                          }}
                          style={{
                            width: "150px",
                            marginLeft: "4px",
                          }}
                          placeholder={"Select..."}
                        />
                      </div>
                      <div className="d-flex justify-space-between">
                        <Button
                          onClick={() => {
                            removeSort();
                          }}
                          style={{ width: "70px" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => {
                            sortFields(sort, data);
                          }}
                          size="small"
                          className="sort-button"
                          style={{ width: "50px" }}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                }
                trigger="click"
                open={toggleSortDropdown}
                onOpenChange={handleSortChange}
              >
                <Button type={sort.column ? "primary" : "default"}>
                  {sort.column ? "SORTED" : "SORT"}
                </Button>
              </Popover>

              <Button
                style={{ margin: "0 10px" }}
                onClick={(e) => {
                  handleExpandAll(data);
                }}
              >
                {expandedRowKeys.length === 0 ? "Expand All" : "Collapse All"}
              </Button>
              {/* <Popover
            showArrow={false}
            content={
              <div className="views-container">
                {views?.map((view) => (
                  <div className="d-flex align-center">
                    <p
                      onClick={(e) => {
                        handleOnViewSelect(view);
                        setToggleViewsDropdown(false);
                      }}
                    >
                      {view.name}
                    </p>
                    <CloseCircleOutlined
                      onClick={(e) => handleDeleteView(view._id)}
                    />
                  </div>
                ))}
              </div>
            }
            trigger="click"
            open={toggleViewsDropdown}
            onOpenChange={handleViewsChange}
          >
            <Button>Views</Button>
          </Popover> */}
            </div>
            {!isView ? (
              <Button
                className="theme-bg-gradient"
                style={{ color: "#fff" }}
                onClick={() => setIsViewOpen(true)}
              >
                Save View
              </Button>
            ) : views.length > 0 ? (
              <div>
                <Button onClick={() => setIsViewDeleteOpen(true)}>
                  Delete View
                </Button>
                <Button
                  className="theme-bg-gradient"
                  style={{ color: "#fff", marginLeft: "10px" }}
                  onClick={() => handleUpdateView()}
                >
                  Update View
                </Button>
              </div>
            ) : null}
          </div>
          <div className="list-view-table" style={{ marginTop: "10px" }}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              bordered
              scroll={{
                x: 500,
              }}
              expandable={{
                expandedRowKeys,
              }}
              onRow={customRowRender}
              rowClassName={(record) => (!record.children ? "task-row" : "")}
              expandIcon={(props) => customExpandIcon(props)}
            />
          </div>
        </>
      ) : (
        <div
          className="d-flex align-center justify-center"
          style={{ padding: "50px" }}
        >
          <img src={noDataImg} alt="empty" style={{ width: "400px" }} />
        </div>
      )}
    </div>
  );
};
export default List;
