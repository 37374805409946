import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  addAsset,
  addAssetCategory,
  addCategoryToList,
  addMoreAssets,
  addNewAssetToList,
  assignAsset,
  deleteAsset,
  deleteCategory,
  getAssetCategories,
  getAssetHistory,
  getAssignedAssets,
  getCompanyAssets,
  getMyAssets,
  getRecoveredAssets,
  recoverAsset,
  setAssetCategories,
  setAssetHistory,
  setAssignedAssets,
  setCompanyAssets,
  setMyAssets,
  setRecoveredAssets,
  updateAsset,
  updateAssetCategory,
  updateAssetCategoryList,
  updateAssetInAssetList,
  updateAssetStatus,
  updateAssetsAfterAssign,
  updateAssetsAfterDelete,
  updateAssetsAfterRecover,
  updateCategoryListAfterDelete,
} from "../slices/AssetSlice";
import agent from "../../agent";
import showMessage from "../../utils/messageUtil";

function* getAllCategories() {
  const response = yield call(agent.Asset.getAssetCategories);
  if (response.statusCode === 200) yield put(setAssetCategories(response.body));
  else showMessage(response, "", "Failed to get all categories: ");
}

function* addCategory(data) {
  const response = yield call(agent.Asset.addAssetCategory, data.payload);
  if (response.statusCode === 201) yield put(addCategoryToList(response.body));
  showMessage(response, response.message, "Failed to add category: ");
}

function* updateCategory(data) {
  const response = yield call(
    agent.Asset.updateAssetCategory,
    data.payload.id,
    data.payload.updatePayload
  );
  if (response.statusCode === 200)
    yield put(updateAssetCategoryList(response.body));
  showMessage(response, response.message, "Failed to update category: ");
}

// function* getAllAssets(data) {
//   const response = yield call(agent.Asset.getAssets, data.payload);
//   if (response.statusCode === 200) yield put(setAssets(response.body));
//   else showMessage(response, "", "Failed to get all assets: ");
// }

function* getAllCompanyAssets() {
  const response = yield call(agent.Asset.getAssetsByCompany);
  if (response.statusCode === 200) yield put(setCompanyAssets(response.body));
  else showMessage(response, "", "Failed to get all assets: ");
}

function* getAllAssignedAssets(data) {
  const response = yield call(agent.Asset.getAssignedAssets);
  if (response.statusCode === 200) yield put(setAssignedAssets(response.body));
  else showMessage(response, "", "Failed to get assigned assets: ");
}

function* getAllRecoveredAssets(data) {
  const response = yield call(agent.Asset.getRecoveredAssets);
  if (response.statusCode === 200) yield put(setRecoveredAssets(response.body));
  else showMessage(response, "", "Failed to get recovered assets: ");
}

function* getCurrentUsersAssets(data) {
  const response = yield call(agent.Asset.getMyAssets);
  if (response.statusCode === 200) yield put(setMyAssets(response.body));
  else showMessage(response, "", "Failed to get my assets: ");
}

function* addNewAsset(data) {
  const response = yield call(agent.Asset.addAsset, data.payload);
  if (response.statusCode === 201) yield put(addNewAssetToList(response.body));
  showMessage(response, response.message, "Failed to add new asset: ");
}

function* updateAssetById(data) {
  const response = yield call(
    agent.Asset.updateAsset,
    data.payload.id,
    data.payload.updatePayload
  );
  if (response.statusCode === 200)
    yield put(updateAssetInAssetList(response.body));
  showMessage(response, response.message, "Failed to update asset: ");
}

function* addMoreRecoverableAssets(data) {
  const response = yield call(
    agent.Asset.addMoreRecoverableAssets,
    data.payload.groupId,
    data.payload.assetList
  );
  if (response.statusCode === 200)
    yield put(updateAssetsAfterAssign(response.body));
  showMessage(response, response.message, "Failed to add more assets: ");
}

function* assignAssetToUsers(data) {
  const response = yield call(
    agent.Asset.assignAsset,
    data.payload.id,
    data.payload.userList
  );
  if (response.statusCode === 200)
    yield put(updateAssetsAfterAssign(response.body));
  showMessage(response, response.message, "Failed to assign asset to users: ");
}

function* recoverAssetFromUser(data) {
  const response = yield call(agent.Asset.recoverAsset, data.payload);
  if (response.statusCode === 200)
    yield put(updateAssetsAfterRecover(response.body));
  showMessage(
    response,
    response.message,
    "Failed to recover asset from user: "
  );
}

function* deleteAssetById(data) {
  const response = yield call(agent.Asset.deleteAsset, data.payload);
  if (response.statusCode === 200) {
    if (response.body.recoverable)
      yield put(updateAssetInAssetList(response.body));
    else yield put(updateAssetsAfterDelete(response.body));
  }
  showMessage(response, response.message, "Failed to delete asset: ");
}

function* deleteCategoryById(data) {
  const response = yield call(agent.Asset.deleteCategory, data.payload);
  if (response.statusCode === 200) {
    yield put(updateCategoryListAfterDelete(data.payload));
  }
  showMessage(response, response.message, "Failed to delete category: ");
}

function* updateAssetStatusById(data) {
  const response = yield call(
    agent.Asset.updateAssetStatus,
    data.payload.id,
    data.payload.updatePayload
  );
  if (response.statusCode === 200) {
    yield put(updateAssetInAssetList(response.body));
  }
  showMessage(response, response.message, "Failed to update asset status: ");
}

function* getAssetHistoryById(data) {
  const response = yield call(agent.Asset.getAssetHistory, data.payload);
  if (response.statusCode === 200) yield put(setAssetHistory(response.body));
  else showMessage(response, "", "Failed to fetch asset history: ");
}

function* assets() {
  yield takeLatest(getAssetCategories.type, getAllCategories);
  yield takeLatest(addAssetCategory.type, addCategory);
  yield takeLatest(updateAssetCategory.type, updateCategory);
  // yield takeLatest(getAssets.type, getAllAssets);
  yield takeLatest(getCompanyAssets.type, getAllCompanyAssets);
  yield takeLatest(getAssignedAssets.type, getAllAssignedAssets);
  yield takeLatest(getRecoveredAssets.type, getAllRecoveredAssets);
  yield takeLatest(getMyAssets.type, getCurrentUsersAssets);
  yield takeLatest(addAsset.type, addNewAsset);
  yield takeLatest(updateAsset.type, updateAssetById);
  yield takeLatest(addMoreAssets.type, addMoreRecoverableAssets);
  yield takeLatest(assignAsset.type, assignAssetToUsers);
  yield takeLatest(recoverAsset.type, recoverAssetFromUser);
  yield takeLatest(deleteAsset.type, deleteAssetById);
  yield takeLatest(deleteCategory.type, deleteCategoryById);
  yield takeLatest(updateAssetStatus.type, updateAssetStatusById);
  yield takeLatest(getAssetHistory.type, getAssetHistoryById);
}
export const assetSaga = [fork(assets)];
