import React, { useState } from "react";
import { Button, Input, Modal, Select, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { addMoreAssets } from "../../redux/slices/AssetSlice";

const AddAssetItem = ({ asset, isOpen, close }) => {
  const dispatch = useDispatch();

  const [assetInfo, setAssetInfo] = useState([{ uniqueId: "", status: "New" }]);
  const statusOptions = [
    {
      label: (
        <div
          style={{ height: "25px", lineHeight: "20px" }}
          className="done-highlight"
        >
          New
        </div>
      ),
      value: "New",
    },
    {
      label: (
        <div
          style={{ height: "25px", lineHeight: "20px" }}
          className="hold-highlight"
        >
          Damaged
        </div>
      ),
      value: "Damaged",
    },
  ];

  const handleInfoChange = (e, i) => {
    let data = assetInfo;
    data[i].uniqueId = e.target.value;
    setAssetInfo([...data]);
  };

  const handleStatusChange = (e, i) => {
    let data = assetInfo;
    data[i].status = e;
    setAssetInfo([...data]);
  };

  const handleAddMoreAsset = () => {
    let data = assetInfo;
    data.push({ uniqueId: "", status: "New" });
    setAssetInfo([...data]);
  };

  const handleRemoveAsset = (index) => {
    let data = assetInfo;
    data.splice(index, 1);
    setAssetInfo([...data]);
  };

  const handleAddAssets = () => {
    let error = false;
    if (assetInfo?.length) {
      for (let asset of assetInfo) {
        asset.uniqueId = asset.uniqueId?.trim();
        asset.status = asset.status?.trim();
        if (
          !asset.uniqueId ||
          asset.uniqueId.trim().length <= 0 ||
          !asset.status ||
          asset.status.trim().length <= 0
        ) {
          error = true;
        }
      }
    }

    if (error) {
      message.error("Please enter valid details");
      return;
    }
    dispatch(
      addMoreAssets({
        groupId: asset.groupId,
        assetList: { assetItems: assetInfo },
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setAssetInfo([{ uniqueId: "", status: "New" }]);
    close();
  };

  return (
    <div>
      <Modal
        title="Add Asset"
        centered
        closable={true}
        open={isOpen}
        onCancel={handleClose}
        footer={null}
        className="add-asset-item-modal"
        width="430px"
      >
        <div>
          <div style={{ maxHeight: "380px" }}>
            <p>Please provide the details of each asset.</p>
            {assetInfo.length
              ? assetInfo.map((info, index) => (
                  <div
                    className="d-flex align-center justify-space-between"
                    style={{ margin: "10px 0" }}
                  >
                    <div style={{ width: "200px", marginRight: "20px" }}>
                      <label
                        style={{
                          fontSize: "12px",
                          color: "#6d6e6f",
                          fontWeight: 500,
                        }}
                      >
                        Serial Id
                      </label>
                      <Input
                        placeholder="Id"
                        name="uniqueId"
                        value={info.uniqueId || ""}
                        style={{ height: "32px" }}
                        onChange={(e) => {
                          handleInfoChange(e, index);
                        }}
                      />
                    </div>
                    <div className="d-flex flex-col" style={{ width: "150px" }}>
                      <label
                        style={{
                          fontSize: "12px",
                          color: "#6d6e6f",
                          fontWeight: 500,
                        }}
                      >
                        Status
                      </label>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        allowClear
                        style={{ width: "145px" }}
                        options={statusOptions}
                        value={info.status || ""}
                        placeholder="Select Status"
                        isValidNewOption={() => false}
                        onChange={(e) => handleStatusChange(e, index)}
                      />
                    </div>
                    {assetInfo?.length > 1 ? (
                      <CloseCircleOutlined
                        style={{ color: "red", marginTop: "18px" }}
                        onClick={() => handleRemoveAsset(index)}
                      />
                    ) : null}
                  </div>
                ))
              : null}
          </div>
          <p
            style={{
              color: "#062c8f",
              cursor: "pointer",
              fontSize: "12px",
              fontWeight: 600,
              width: "fit-content",
              margin: 0,
            }}
            onClick={handleAddMoreAsset}
          >
            Add more asset
          </p>
          <div
            className="d-flex align-center justify-space-between"
            style={{ paddingTop: "20px" }}
          >
            <Button htmlType="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="theme-bg-gradient"
              style={{ marginLeft: "10px" }}
              onClick={handleAddAssets}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddAssetItem;
