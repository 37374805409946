import { Avatar, Button, Col, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppConstants from "../../utils/AppConstants";
import { assignAsset } from "../../redux/slices/AssetSlice";

const AssignAssetModal = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();

  const { selectedAsset } = useSelector((state) => state.asset);
  const { activeCompany } = useSelector((state) => state.user);

  const [count, setCount] = useState(selectedAsset?.count);
  const [assignees, setAssignees] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [assetDropdown, setAssetDropdown] = useState([]);
  const [assetToAssign, setAssetToAssign] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledForAsset, setIsDisabledForAsset] = useState(true);
  const [errorStr, setError] = useState("");
  const [assetErrorStr, setAssetError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showassetError, setShowAssetError] = useState(false);

  useEffect(() => {
    setCount(selectedAsset?.count);
    let teamArray = [];
    if (selectedAsset) {
      if (Object.keys(activeCompany).length !== 0) {
        activeCompany.users?.forEach((user) => {
          let element = (
            <div>
              <Avatar
                alt=""
                size={25}
                className="avatar-text-img"
                style={{
                  marginRight: "10px",
                }}
                src={
                  user?.isUserProfileUploaded
                    ? `${AppConstants.BUCKET_URL}` +
                      user?.email.replace("@", "%40")
                    : ""
                }
              >
                {user.name?.slice(0, 1)}
              </Avatar>
              {user.name}
            </div>
          );
          let teamObj = { label: element, value: user?._id, name: user?.name };
          teamArray.push(teamObj);
        });
        setUserDropdown(teamArray);
      }
      if (selectedAsset?.recoverable) {
        createAssetDropdown();
        setIsDisabledForAsset(true);
      } else {
        setIsDisabledForAsset(false);
      }
    }
  }, [activeCompany, selectedAsset]);

  const createAssetDropdown = () => {
    let assets = selectedAsset?.assetItems?.map((asset) => {
      let obj = {
        label: (
          <div className="d-flex align-center">
            <span style={{ minWidth: "120px" }}>{asset.serialId}</span>
            <div
              style={{
                minWidth: "80px",
                marginLeft: "20px",
                height: "25px",
                lineHeight: "20px",
              }}
              className={
                asset?.status === "New"
                  ? "done-highlight"
                  : asset?.status === "Damaged"
                  ? "hold-highlight"
                  : "todo-highlight"
              }
            >
              {asset?.status}
            </div>
          </div>
        ),
        name: asset.serialId,
        value: asset._id,
      };
      return obj;
    });
    setAssetDropdown(assets);
  };

  const handleOnChangeAssetToAssign = (e) => {
    if (e) {
      setAssetToAssign(e);
      setIsDisabledForAsset(false);
      setAssetError("");
      setShowAssetError(false);
    } else {
      let err = "Asset must be selected";
      setAssetToAssign(null);
      setIsDisabledForAsset(true);
      setAssetError(err);
      setShowAssetError(true);
    }
  };
  const handleOnChangeAssignee = (e) => {
    if (selectedAsset?.recoverable) {
      handleOnChangeAssigneeForRecoverable(e);
    } else {
      handleOnChangeAssigneeForNonRecoverable(e);
    }
  };
  const handleOnChangeAssigneeForRecoverable = (e) => {
    if (e) {
      setAssignees(e);
      setShowError(false);
      setIsDisabled(false);
    } else {
      setError("No Users Selected");
      setAssignees([]);
      setIsDisabled(true);
      setShowError(true);
    }
  };
  const handleOnChangeAssigneeForNonRecoverable = (e) => {
    if (e.length > 0 && e.length <= selectedAsset?.count) {
      setAssignees(e);
      setCount(selectedAsset.count - e.length);
      setShowError(false);
      setIsDisabled(false);
    } else {
      let err = "Selected asset can not be assigned to more users";
      if (e.length === 0) {
        err = "No Users Selected";
        setAssignees([]);
        setCount(selectedAsset.count);
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      setError(err);
      setShowError(true);
    }
  };

  const handleAssignAsset = (e) => {
    let id = selectedAsset.recoverable ? assetToAssign : selectedAsset._id;
    let userIds = selectedAsset.recoverable ? [assignees] : [...assignees];
    dispatch(
      assignAsset({
        id: id,
        userList: { userIds: userIds },
      })
    );
    resetModalAndClose();
  };

  const resetModalAndClose = () => {
    setCount(selectedAsset?.count);
    setIsDisabled(true);
    setError("");
    setShowError(false);
    setIsDisabledForAsset(true);
    setAssetError("");
    setShowAssetError(false);
    setAssignees([]);
    setAssetToAssign(null);
    setAssetDropdown([]);
    handleClose();
  };

  return (
    <Modal
      className="asset-assign-modal"
      title="Assign Assets"
      centered
      open={isOpen}
      onCancel={resetModalAndClose}
      footer={null}
      maskClosable={false}
    >
      <div style={{ minWidth: "500px" }}>
        <div
          className="d-flex align-center justify-space-between"
          style={{ marginBottom: "20px" }}
        >
          <div className="d-flex align-center">
            <h4 style={{ margin: 0 }}>Asset</h4>{" "}
            <span style={{ margin: "0 10px" }}> :</span>
            <h5 style={{ fontSize: "14px", margin: 0 }}>
              {selectedAsset?.name}
            </h5>
          </div>
          {selectedAsset?.recoverable ? null : (
            <div className="d-flex align-center">
              <h4 style={{ margin: 0 }}>Count</h4>{" "}
              <span style={{ margin: "0 10px" }}> :</span>
              <h5 style={{ fontSize: "14px", margin: 0 }}>{count}</h5>
            </div>
          )}
        </div>
        <div style={{ marginBottom: "20px" }}>
          {selectedAsset?.properties &&
          JSON.parse(selectedAsset.properties).length ? (
            <Row className="asset-property-card">
              <Col span={23} offset={1}>
                <h4>Properties</h4>
              </Col>

              {selectedAsset?.properties &&
              JSON.parse(selectedAsset.properties).length
                ? JSON.parse(selectedAsset.properties).map((property) => (
                    <Col span={11} offset={1}>
                      <div className=" d-flex align-center ">
                        <p style={{ minWidth: "100px" }}> {property.key}</p>
                        <span style={{ margin: "0 10px" }}>:</span>
                        <p style={{ color: "#1079ff" }}>{property.value} </p>
                      </div>
                    </Col>
                  ))
                : null}
            </Row>
          ) : null}
        </div>
        {selectedAsset?.recoverable ? (
          <>
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ marginBottom: "10px" }}>Serial ID</h4>{" "}
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Select serial ID"
                style={{ width: "100%" }}
                options={assetDropdown}
                allowClear
                showSearch={true}
                onChange={(e) => handleOnChangeAssetToAssign(e)}
                value={assetToAssign}
                filterOption={(inputValue, option) => {
                  if (
                    option?.name
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase())
                  ) {
                    return true;
                  } else return false;
                }}
              ></Select>
            </div>
            <div
              style={{
                display: showassetError ? "block" : "none",
                color: "red",
              }}
            >
              {assetErrorStr}
            </div>
          </>
        ) : null}
        <div>
          <h4 style={{ margin: "10px 0" }}>Assign To</h4>{" "}
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Select users"
            style={{ width: "100%" }}
            options={userDropdown}
            allowClear
            showSearch={true}
            mode={selectedAsset?.recoverable ? "single" : "multiple"}
            onChange={(e) => handleOnChangeAssignee(e)}
            value={assignees}
            filterOption={(inputValue, option) => {
              if (
                option.name.toLowerCase().includes(inputValue.toLowerCase())
              ) {
                return true;
              } else return false;
            }}
          ></Select>
        </div>

        <div
          style={{
            display: showError ? "block" : "none",
            color: "red",
          }}
        >
          {errorStr}
        </div>
        <div
          className="d-flex align-center justify-space-between"
          style={{ marginTop: "20px" }}
        >
          <Button onClick={resetModalAndClose}>Cancel</Button>
          <Button
            type="primary "
            disabled={isDisabled || isDisabledForAsset}
            onClick={() => {
              handleAssignAsset();
            }}
            className="theme-bg-gradient"
            style={{ color: "#fff" }}
          >
            Assign
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignAssetModal;
