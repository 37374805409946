import React, { useEffect, useState } from "react";
import moment from "moment";
import { Avatar, Modal, Popconfirm, Select, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, HistoryOutlined } from "@ant-design/icons";
import {
  deleteAsset,
  getAssetHistory,
  updateAssetStatus,
} from "../../redux/slices/AssetSlice";
import AppConstants from "../../utils/AppConstants";
import AssetsHistoryModal from "./AssetsHistoryModal";

const AssetsInfoModal = ({ isOpen, close }) => {
  const { activeCompany } = useSelector((state) => state.user);
  const { selectedAsset } = useSelector((state) => state.asset);
  const [rowData, setRowData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const dispatch = useDispatch();

  const statusOptionNew = [
    {
      label: (
        <div
          style={{ height: "30px", lineHeight: "25px" }}
          className="done-highlight"
        >
          New
        </div>
      ),
      value: "New",
    },
    // {
    //   label: (
    //     <div
    //       style={{ height: "30px", lineHeight: "25px" }}
    //       className="hold-highlight"
    //     >
    //       Damaged
    //     </div>
    //   ),
    //   value: "Damaged",
    // },
  ];

  const statusOptionDamaged = [
    {
      label: (
        <div
          style={{ height: "30px", lineHeight: "25px" }}
          className="hold-highlight"
        >
          Damaged
        </div>
      ),
      value: "Damaged",
    },
  ];

  const getUser = (userId) => {
    let userDetails = {};
    if (activeCompany?.users?.length) {
      activeCompany?.users.forEach((user) => {
        if (user._id === userId) {
          userDetails = user;
        }
      });
    }
    if (userDetails._id) return userDetails;
    else return { name: "Removed User" };
  };

  const deleteSelectedAsset = (assetId) => {
    dispatch(deleteAsset(assetId));
  };

  const handleStatusChange = (e, record) => {
    dispatch(
      updateAssetStatus({
        id: record.id,
        updatePayload: { oldValue: record.status, status: e },
      })
    );
  };

  useEffect(() => {
    let data = [];
    if (
      selectedAsset &&
      selectedAsset?.assetItems &&
      selectedAsset?.assetItems?.length
    ) {
      selectedAsset?.assetItems?.forEach((asset) => {
        data.push({
          id: asset._id,
          serialId: asset.serialId,
          status: asset.status,
          addedBy: getUser(asset.addedBy),
          createdAt: asset.createdAt
            ? moment(asset.createdAt).format("DD MMM YYYY")
            : "-",
          updatedAt: asset.updatedAt
            ? moment(asset.updatedAt).format("DD MMM YYYY")
            : "-",
        });
      });
      setRowData([...data]);
    } else if (!selectedAsset) {
      close();
    }
  }, [selectedAsset]);

  const columns = [
    {
      title: "Serial ID",
      dataIndex: "serialId",
      key: "serialId",
      width: "150px",
      render: (text) => (
        <>
          {" "}
          {text?.length < 17 ? (
            <p style={{ margin: 0, color: "#000", fontWeight: 600 }}>{text}</p>
          ) : (
            <Tooltip title={text}>
              <p style={{ margin: 0, color: "#000", fontWeight: 600 }}>
                {`${text?.slice(0, 16)}...`}
              </p>
            </Tooltip>
          )}{" "}
        </>
      ),
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
      width: "180px",
      render: (info) => (
        <div>
          <Avatar
            size="small"
            className="avatar-text-img"
            src={
              info.isUserProfileUploaded
                ? `${AppConstants.BUCKET_URL}` +
                  info?.email?.replace("@", "%40")
                : ""
            }
          >
            {info?.name?.slice(0, 1)}
          </Avatar>
          &nbsp;{info?.name}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (text, record) => (
        <div className="asset-info-select">
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            style={{ width: "100%" }}
            options={
              text === AppConstants.DAMAGED
                ? statusOptionNew
                : statusOptionDamaged
            }
            value={text || ""}
            placeholder="Select Status"
            isValidNewOption={() => false}
            onChange={(e) => handleStatusChange(e, record)}
          />
        </div>
        // <p
        //   className={
        //     text == "New"
        //       ? "done-highlight"
        //       : text == "Recovered"
        //       ? "todo-highlight"
        //       : text == "Damaged"
        //       ? "hold-highlight"
        //       : "closed-highlight"
        //   }
        // >
        //   {text}
        // </p>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "110px",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "110px",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div>
          <Tooltip title="History">
            <HistoryOutlined
              onClick={() => {
                // close();
                dispatch(getAssetHistory(record.id));
                setShowHistory(true);
              }}
              style={{ color: "blue" }}
            />
          </Tooltip>
          <Popconfirm
            // placement="topRight"
            title={`Are you sure to delete this asset ?`}
            okText="Yes"
            cancelText="No"
            overlayClassName="delete-popover"
            onConfirm={() => deleteSelectedAsset(record.id)}
          >
            <DeleteOutlined style={{ color: "red", marginLeft: "20px" }} />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Modal
        title="Assets Information"
        centered
        closable={true}
        open={isOpen}
        onCancel={close}
        footer={null}
        width={900}
        className="assets-info-modal"
      >
        <AssetsHistoryModal
          isOpen={showHistory}
          close={() => {
            setShowHistory(false);
          }}
        />
        <Table
          scroll={{
            y: 300,
          }}
          dataSource={rowData}
          columns={columns}
          pagination={false}
        />
      </Modal>
    </div>
  );
};

export default AssetsInfoModal;
