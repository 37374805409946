import agent from "../../agent";
import { takeLatest, put, call, fork } from "redux-saga/effects";
import {
  addView,
  addViewToList,
  deleteView,
  getViews,
  removeViewFromList,
  setViews,
  updateExistingView,
  updateView,
} from "../slices/ViewSlice";
import showMessage from "../../utils/messageUtil";

function* getAllViews(data) {
  const response = yield call(agent.View.getViewsByProjectId, data.payload);
  yield put(setViews(response.body));
}

function* addViewByProjectId(data) {
  const response = yield call(agent.View.addView, data.payload);
  yield put(addViewToList(response.body));
  showMessage(response, "View added Successfully", "Add View Failed: ");
}

function* updateViewByProjectId(data) {
  const response = yield call(
    agent.View.updateView,
    data.payload.viewId,
    data.payload.updatedView
  );
  yield put(updateExistingView(response.body));
  showMessage(response, "View Updated Successfully", "Update View Failed: ");
}

function* deleteViewByProjectId(data) {
  const response = yield call(agent.View.deleteView, data.payload);
  yield put(removeViewFromList(response.body));
  showMessage(response, "View Deleted Successfully", "Delete View Failed: ");
}

function* views() {
  yield takeLatest(getViews.type, getAllViews);
  yield takeLatest(addView.type, addViewByProjectId);
  yield takeLatest(updateView.type, updateViewByProjectId);
  yield takeLatest(deleteView.type, deleteViewByProjectId);
}

export const viewSaga = [fork(views)];
