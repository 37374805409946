import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: [],
  activeUser: {},
  activeCompany: {},
  userInvite: {},
  userRole: "",
  companyNameList: [],
  uploaded: false,
  activeUserProfile: "",
  activeCompanyProfile: "",
  dashboardData: [],
  activeProjectsCount: 0,
  totalTasksCount: 0,
  notifications: [],
  departments: [],
  allNotifications: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getAllUsers: (state, action) => {},
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    takeUsers: (state, action) => {},
    getActiveCompany: () => {},
    setActiveCompany: (state, action) => {
      state.activeCompany = action.payload;
    },
    sendInvites: (state, action) => {},
    getUserInvite: (state, action) => {},
    setUserInvite: (state, action) => {
      state.userInvite = action.payload;
    },
    addUser: (state, action) => {},
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setNewPassword: (state, action) => {},
    forgotPassword: (state, action) => {},
    addUserToActiveCompany: (state, action) => {
      state.activeCompany.users.push(action.payload);
    },
    addUserDepartmentToActiveCompany: (state, action) => {
      state.activeCompany.userDepartments.push(action.payload);
    },
    updateUserRole: (state, action) => {},
    updateActiveCompanyUserRole: (state, action) => {
      let activeCompany = { ...state.activeCompany };
      let index = activeCompany.users.findIndex(
        (user) => user._id === action.payload._id
      );
      activeCompany.users[index].role = action.payload.role;
      state.activeCompany = activeCompany;
    },
    getCompanyNamesList: (state, action) => {},
    setCompanyNamesList: (state, action) => {
      state.companyNameList = action.payload;
    },
    removeUserFromActiveCompany: (state, action) => {
      let activeCompany = { ...state.activeCompany };
      let users = activeCompany.users.filter(
        (user) => user._id !== action.payload._id
      );
      activeCompany.users = users;
      state.activeCompany = activeCompany;
    },
    removeUser: () => {},
    setUploaded: (state, action) => {
      state.uploaded = true;
    },
    setActiveUserProfile: (state, action) => {
      state.activeUserProfile = action.payload;
    },
    setActiveCompanyProfile: (state, action) => {
      state.activeCompanyProfile = action.payload;
    },
    getDashboardDatasOfUser: (state, action) => {},
    setDashboardDatasOfUser: (state, action) => {
      state.dashboardData = action.payload;
    },
    setActiveProjectsCount: (state, action) => {
      state.activeProjectsCount = action.payload;
    },
    setTotalTasksCount: (state, action) => {
      state.totalTasksCount = action.payload;
    },
    uploadUserProfile: () => {},
    getNotifications: (state, action) => {},
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    getAllNotifications: (state, action) => {},
    setAllNotifications: (state, action) => {
      state.allNotifications = action.payload;
    },
    markSeen: (state, action) => {},
    getDepartments: () => {},
    setDepartments: (state, action) => {
      state.departments = action.payload.departments;
    },
    updateUserDepartment: () => {},
    updateUserDepartmentById: (state, action) => {
      let activeCompany = { ...state.activeCompany };
      let index = activeCompany.userDepartments.findIndex(
        (userDepartment) => userDepartment._id === action.payload._id
      );
      activeCompany.userDepartments[index] = action.payload;
      state.activeCompany = activeCompany;
    },
    deleteNotification: (state, action) => {},
    updateNotificationsAfterSeen: (state, action) => {
      let notis = [...state.notifications];
      let allNotis = [...state.allNotifications];
      if (action.payload === "all") {
        notis?.forEach((noti) => (noti.seen = true));
        allNotis?.forEach((noti) => (noti.seen = true));
      } else {
        notis?.forEach((noti) => {
          if (noti?._id?.toString() === action.payload?.toString()) {
            noti.seen = true;
            return;
          }
        });
        allNotis?.forEach((noti) => {
          if (noti?._id?.toString() === action.payload?.toString()) {
            noti.seen = true;
            return;
          }
        });
      }
      state.notifications = notis;
      state.allNotifications = allNotis;
    },
    updateNotificationsAfterDelete: (state, action) => {
      let notifications = [...state.notifications];
      let allNotifications = [...state.allNotifications];

      state.notifications = notifications?.filter(
        (noti) => noti?._id?.toString() !== action.payload?.toString()
      );

      state.allNotifications = allNotifications?.filter(
        (noti) => noti?._id?.toString() !== action.payload?.toString()
      );
    },
    addWebsocketNotifications: (state, action) => {
      if (
        action.payload?.company?._id?.toString() !==
        state.activeCompany?._id?.toString()
      ) {
        return;
      }
      let notis = [...state.notifications];
      if (notis?.length >= 30) {
        notis.pop();
      }
      notis.unshift(action.payload);
      state.notifications = notis;

      let allNotis = [...state.allNotifications];
      allNotis.unshift(action.payload);
      state.allNotifications = allNotis;
    },
  },
});

export const {
  getAllUsers,
  setAllUsers,
  setActiveUser,
  takeUsers,
  getActiveCompany,
  setActiveCompany,
  sendInvites,
  getUserInvite,
  setUserInvite,
  addUser,
  setUserRole,
  setNewPassword,
  forgotPassword,
  addUserToActiveCompany,
  updateUserRole,
  updateActiveCompanyUserRole,
  getCompanyNamesList,
  setCompanyNamesList,
  removeUser,
  removeUserFromActiveCompany,
  setUploaded,
  setActiveUserProfile,
  setActiveCompanyProfile,
  getDashboardDatasOfUser,
  setDashboardDatasOfUser,
  setActiveProjectsCount,
  setTotalTasksCount,
  uploadUserProfile,
  getNotifications,
  setNotifications,
  getAllNotifications,
  setAllNotifications,
  markSeen,
  getDepartments,
  setDepartments,
  updateUserDepartment,
  updateUserDepartmentById,
  addUserDepartmentToActiveCompany,
  deleteNotification,
  updateNotificationsAfterSeen,
  updateNotificationsAfterDelete,
  addWebsocketNotifications,
} = userSlice.actions;
export default userSlice.reducer;
