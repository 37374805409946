import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  projectTasks: [],
  activeProject: {},
  projectForInvite: [],
  toggle: false,
  view: "",
  projectNamesList: [],
  closedTasks: [],
  projectAudits: [],
  taskCount: {},
  activeProjectTaskCount: {},
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    getProjects: (state, action) => {},
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    getTasksByProject: () => {},
    setProjectTasks: (state, action) => {
      state.projectTasks = action.payload;
    },
    getProjectForInvite: () => {},
    setProjectForInvite: (state, action) => {
      state.projectForInvite = action.payload;
    },
    getActiveProject: () => {},
    setActiveProject: (state, action) => {
      state.activeProject = action.payload;
    },
    getTaskCount: () => {},
    setTaskCount: (state, action) => {
      state.taskCount = action.payload;
    },
    setActiveProjectTaskCount: (state, action) => {
      state.activeProjectTaskCount = action.payload;
    },
    updateProjectTasks: (state, action) => {
      state.projectTasks = action.payload;
    },
    setProjectToggle: (state, action) => {
      state.toggle = !state.toggle;
    },
    takeProject: (state, action) => {},
    addNewProjectToProjectList: (state, action) => {
      state.projects.push(action.payload);
      state.projectNamesList.push(action.payload?.name?.toLowerCase());
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    getProjectNamesList: (state, action) => {},
    setProjectNamesList: (state, action) => {
      state.projectNamesList = action.payload;
    },
    addTaskToProjectTasks: (state, action) => {
      switch (state.view) {
        case "kanban":
          state.projectTasks[action.payload.status].unshift(action.payload);
          break;
        case "list":
          let tasks = [...state.projectTasks];
          const index = tasks.findIndex(
            (task) => task.key === action.payload.status
          );
          tasks[index].children.unshift(action.payload);
          state.projectTasks = tasks;
          break;
        default:
          state.projectTasks.unshift(action.payload);
      }
    },
    updateProjectField: () => {},
    addTeamMembers: () => {},
    removeTeamMember: () => {},
    updateActiveProject: (state, action) => {
      let index = state.projects.findIndex(
        (project) => project._id === action.payload._id
      );
      state.projects[index] = action.payload;
      state.activeProject = action.payload;
    },
    deleteProject: () => {},
    updateProjectListAfterDelete: (state, action) => {
      state.projects = state.projects.filter(
        (project) => project._id !== action.payload
      );
    },
    getClosedTasksByProject: () => {},
    setClosedTasks: (state, action) => {
      state.closedTasks = action.payload;
    },
    getProjectAudits: () => {},
    setProjectAudits: (state, action) => {
      state.projectAudits = action.payload;
    },
    updateProjectAudits: (state, action) => {
      let audits = [...state.projectAudits];
      audits.push(action.payload);
      state.projectAudits = audits;
    },
    getActiveProjetAndTask: () => {},

    projectCleanup: (state, action) => {
      state.projectTasks = [];
      state.activeProject = {};
      state.closedTasks = [];
      state.projectAudits = [];
      state.taskCount = {};
      state.activeProjectTaskCount = {};
    },
  },
});

export const {
  setProjects,
  getProjects,
  getTasksByProject,
  setProjectTasks,
  getActiveProject,
  setActiveProject,
  updateProjectTasks,
  setProjectToggle,
  getProjectForInvite,
  setProjectForInvite,
  takeProject,
  addNewProjectToProjectList,
  setView,
  addTaskToProjectTasks,
  getProjectNamesList,
  setProjectNamesList,
  updateProjectField,
  updateActiveProject,
  addTeamMembers,
  removeTeamMember,
  deleteProject,
  updateProjectListAfterDelete,
  getClosedTasksByProject,
  setClosedTasks,
  getProjectAudits,
  setProjectAudits,
  updateProjectAudits,
  getActiveProjetAndTask,
  getTaskCount,
  setTaskCount,
  setActiveProjectTaskCount,
  projectCleanup,
} = projectSlice.actions;
export default projectSlice.reducer;
