import agent from "../../agent";
import { takeLatest, put, call, fork } from "redux-saga/effects";
import {
  getActiveCompany,
  getAllUsers,
  sendInvites,
  setActiveCompany,
  setAllUsers,
  takeUsers,
  getUserInvite,
  setUserInvite,
  addUser,
  setNewPassword,
  forgotPassword,
  addUserToActiveCompany,
  updateUserRole,
  updateActiveCompanyUserRole,
  getCompanyNamesList,
  setCompanyNamesList,
  removeUser,
  removeUserFromActiveCompany,
  getDashboardDatasOfUser,
  setDashboardDatasOfUser,
  setActiveProjectsCount,
  setTotalTasksCount,
  uploadUserProfile,
  getNotifications,
  setNotifications,
  markSeen,
  getDepartments,
  setDepartments,
  updateUserDepartment,
  updateUserDepartmentById,
  addUserDepartmentToActiveCompany,
  deleteNotification,
  updateNotificationsAfterSeen,
  updateNotificationsAfterDelete,
  setAllNotifications,
  getAllNotifications,
} from "../slices/UserSlice";
import { setSelectedCompanyToLoginUser } from "../slices/LoginSlice";
import showMessage from "../../utils/messageUtil";
import { resetAllAssets } from "../slices/AssetSlice";

function* getAllUser() {
  const response = yield call(agent.User.getUsers);
  yield put(setAllUsers(response.body));
}

function* getAllCompaniesName() {
  const response = yield call(agent.Company.getAllCompaniesName);
  if (response?.statusCode === 200 || response?.statusCode === 201) {
    yield put(setCompanyNamesList(response.body));
  }
}

function* createNewUser(data) {
  try {
    const response = yield call(agent.User.createUser, data.payload);
    showMessage(response, "Sign up Successful", "Sign up Failed : ");
  } catch (e) {
    console.log(e);
  }
}

function* getCompanyById(data) {
  try {
    const response = yield call(agent.Company.getSelectedCompany, data.payload);
    yield put(setActiveCompany(response.body));
    yield put(setSelectedCompanyToLoginUser(data.payload));
    yield put(resetAllAssets());
  } catch (e) {
    console.log(e);
  }
}

function* sendUserInvites(data) {
  const response = yield call(
    agent.Company.sendInvite,
    data.payload.companyId,
    data.payload.userInvites
  );
  showMessage(response, "Invite Sent to User", "Invite User failed: ");
  if (response?.statusCode === 200) {
    for (const userDepartment of response.body.userDepartments) {
      yield put(addUserDepartmentToActiveCompany(userDepartment));
    }
    for (const user of response.body.invitedUsers) {
      yield put(addUserToActiveCompany(user));
    }
  }
}

function* getUserInviteById(data) {
  const response = yield call(agent.Company.getUserInvite, data.payload.id);
  yield put(setUserInvite(response.body));
}

function* addUserFromAdmin(data) {
  const response = yield call(
    agent.User.addUser,
    data.payload.companyId,
    data.payload.user
  );
  showMessage(response, "Added User successfully", "Add User failed: ");
  if (response?.statusCode === 200) {
    yield put(addUserDepartmentToActiveCompany(response.body.userDepartment));
    yield put(addUserToActiveCompany(response.body.user));
  }
}

function* resetPassword(data) {
  try {
    const response = yield call(agent.User.resetPassword, data.payload);
    showMessage(
      response,
      "Password Reset Successfully!!",
      "Password Reset Failed: "
    );
  } catch (e) {
    console.log(e);
  }
}

function* sendForgotPasswordEmail(data) {
  try {
    const response = yield call(agent.User.forgotPassword, {
      email: data.payload,
    });
    showMessage(response, "Forget Password Link Sent to your email", "");
  } catch (e) {
    console.log(e);
  }
}

function* updateUserRoleById(data) {
  const response = yield call(
    agent.User.updateRole,
    data.payload.userId,
    data.payload.rolePayload
  );
  if (response?.statusCode === 200 || response?.statusCode === 201) {
    yield put(updateActiveCompanyUserRole(response.body));
  }
  showMessage(response, "Role Updated successfully", "Role Update failed: ");
}

function* removeUserFromCompany(data) {
  const response = yield call(agent.Company.removeUser, data.payload.userId);
  if (response?.statusCode === 200 || response?.statusCode === 201) {
    yield put(removeUserFromActiveCompany(response.body));
  }
  showMessage(response, "User Removed successfully", "Remove User failed: ");
}

function* getDashboardDataOfUser(data) {
  const response = yield call(agent.Company.getDashboardDatas, data.payload);
  if (response?.statusCode === 200 || response?.statusCode === 201) {
    yield put(setDashboardDatasOfUser(response.body.tasks));
    yield put(setActiveProjectsCount(response.body.projects));
    yield put(setTotalTasksCount(response.body.totalTasks));
  }
}

function* uploadUserImage(data) {
  try {
    const response = yield call(
      agent.User.userProfileUpload,
      data.payload.userId,
      data.payload.profile
    );
    // showMessage(response, "Image uploaded Successfully", "Image uploaded Failed: ");
  } catch (e) {
    console.log(e);
  }
}

function* getNotificationsOfCurrentCompany(data) {
  const response = yield call(
    agent.User.getNotificationsByCompanyId,
    data.payload
  );
  yield put(setNotifications(response.body));
}

function* getAllNotificationsOfCurrentCompany(data) {
  const response = yield call(
    agent.User.getAllNotificationsByCompanyId,
    data.payload
  );
  yield put(setAllNotifications(response.body));
}

function* markNotificationsSeen(data) {
  const response = yield call(
    agent.User.markNotificationsSeenByCompanyId,
    data.payload.companyId,
    data.payload.notificationId
  );
  if (response?.statusCode === 200 || response?.statusCode === 201) {
    yield put(
      updateNotificationsAfterSeen(data.payload.notificationId.notificationId)
    );
  }
  if (data.payload?.notificationId?.notificationId === "all")
    showMessage(response, "All Notifications Marked as Read", "");
}

function* deleteSingleNotification(data) {
  const response = yield call(
    agent.User.deleteNotificationById,
    data.payload.notificationId
  );
  if (response?.statusCode === 200 || response?.statusCode === 201) {
    yield put(updateNotificationsAfterDelete(data.payload.notificationId));
  }
  showMessage(response, "Notification Deleted", "");
}

function* getAllDepartment(data) {
  try {
    const response = yield call(agent.User.getAllDepartments, data.payload);
    yield put(setDepartments(response.body));
  } catch (err) {
    console.log(err);
  }
}

function* updateUserDepartments(data) {
  try {
    const response = yield call(
      agent.User.updateUserDepartment,
      data.payload.userPayload,
      data.payload.companyId
    );
    yield put(updateUserDepartmentById(response.body));
    showMessage(
      response,
      "User Department updated successfully",
      "Update User Department failed: "
    );
  } catch (err) {
    console.log(err);
  }
}

function* users() {
  yield takeLatest(getAllUsers.type, getAllUser);
  yield takeLatest(takeUsers.type, createNewUser);
  yield takeLatest(getActiveCompany.type, getCompanyById);
  yield takeLatest(sendInvites.type, sendUserInvites);
  yield takeLatest(getUserInvite.type, getUserInviteById);
  yield takeLatest(addUser.type, addUserFromAdmin);
  yield takeLatest(setNewPassword.type, resetPassword);
  yield takeLatest(forgotPassword.type, sendForgotPasswordEmail);
  yield takeLatest(updateUserRole.type, updateUserRoleById);
  yield takeLatest(getCompanyNamesList, getAllCompaniesName);
  yield takeLatest(removeUser.type, removeUserFromCompany);
  yield takeLatest(getDashboardDatasOfUser.type, getDashboardDataOfUser);
  yield takeLatest(uploadUserProfile.type, uploadUserImage);
  yield takeLatest(getNotifications.type, getNotificationsOfCurrentCompany);
  yield takeLatest(
    getAllNotifications.type,
    getAllNotificationsOfCurrentCompany
  );
  yield takeLatest(markSeen.type, markNotificationsSeen);
  yield takeLatest(getDepartments.type, getAllDepartment);
  yield takeLatest(updateUserDepartment.type, updateUserDepartments);
  yield takeLatest(deleteNotification.type, deleteSingleNotification);
}

export const userSaga = [fork(users)];
