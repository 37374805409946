import { takeLatest, put, call, fork, takeEvery } from "redux-saga/effects";
import agent from "../../agent";
import {
  takeLoginUser,
  setLoginUser,
  getCurrentUser,
  getMe,
  setNavigateFlag,
  logout,
} from "../slices/LoginSlice";
import showMessage from "../../utils/messageUtil";
import { message } from "antd";

function* onLoginAsync(data) {
  try {
    const response = yield call(agent.Auth.login, data.payload);
    showMessage(response, "Login Successfully", "Login Failed: ");
    if (response?.status === "success") {
      yield put(setLoginUser(response.data.user));
      localStorage.setItem("jwt", response.token);
    } else {
      yield put(logout());
    }
  } catch (e) {
    console.log(e);
  }
}

function* getLoggedInUser(token) {
  try {
    localStorage.setItem("jwt", token.payload);
    const response = yield call(agent.Auth.getCurrentUser);
    if (response?.statusCode === 200) {
      yield put(setLoginUser(response.body));
    } else {
      yield put(logout());
      showMessage(response, "", "");
    }
  } catch (e) {
    console.log(e);
  }
}

function* getCurrentLoggedInUser(data) {
  try {
    const response = yield call(agent.Auth.getCurrentUser);
    if (response?.statusCode === 200) {
      yield put(setLoginUser(response.body));
    } else {
      yield put(logout());
      message.error("Session Expired! Please Sign in again");
    }
  } catch (e) {
    console.log(e);
  }
}

function* onLogin() {
  yield takeLatest(takeLoginUser.type, onLoginAsync);
  yield takeLatest(getCurrentUser.type, getLoggedInUser);
  yield takeEvery(getMe.type, getCurrentLoggedInUser);
}

export const loginSaga = [fork(onLogin)];
