import { all } from "redux-saga/effects";
import { loginSaga } from "./sagas/LoginSaga";
import { projectSaga } from "./sagas/ProjectSaga";
import { taskSaga } from "./sagas/TaskSaga";
import { userSaga } from "./sagas/UserSaga";
import { viewSaga } from "./sagas/ViewSaga";
import { searchSaga } from "./sagas/SearchSaga";
import { assetSaga } from "./sagas/AssetSaga";
import { tagSaga } from "./sagas/TagSaga";

export default function* rootSaga() {
  yield all([
    ...loginSaga,
    ...projectSaga,
    ...taskSaga,
    ...userSaga,
    ...viewSaga,
    ...searchSaga,
    ...assetSaga,
    ...tagSaga,
  ]);
}
