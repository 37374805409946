import React from "react";
import { Layout, Menu, Button, Typography, Card, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../redux/slices/UserSlice";

const { Title } = Typography;
const { Footer, Content } = Layout;

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { isRegistered } = useSelector((state) => state.login);
  // const { registrationError } = useSelector((state) => state.login);

  const onFinish = (values) => {
    const email = values.email.trim();
    dispatch(forgotPassword(email));
    navigate("/sign-in");
  };

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content" style={{ paddingTop: "10px" }}>
              <Title>Grappler</Title>
              <p className="text-lg">
                Enter your email address and we’ll send you a link to reset your
                password.
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0 "
            title={<h5>Enter Email</h5>}
            bordered="false"
            style={{ marginTop: "-220px" }}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              className="row-col"
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input type="email" placeholder="Enter your email" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>

            <Link to="/sign-in" className="text-dark ">
              Back to Sign In
            </Link>
          </Card>
        </Content>
        <Footer>
          <Menu mode="horizontal">
            <Menu.Item>Company</Menu.Item>
            <Menu.Item>About Us</Menu.Item>
            <Menu.Item>Teams</Menu.Item>
            <Menu.Item>Products</Menu.Item>
            <Menu.Item>Blogs</Menu.Item>
            <Menu.Item>Pricing</Menu.Item>
          </Menu>
          <p className="copyright">
            Copyright © 2024 Grappler by Innogent Technologies
          </p>
        </Footer>
      </div>
    </>
  );
}

export default ForgotPassword;
